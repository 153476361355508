const state = {
  checkPopup: false,
  checkCalendarPopup: false,
  checkSearchPopup: false
};

const getters = {
  GET_POPUP: state => state.checkPopup,
  GET_CALENDAR_POPUP: state => state.checkCalendarPopup,
  GET_SERARCH_POPUP: state => state.checkSearchPopup
};

const mutations = {
  SET_POPUP(state, currentState) {
    state.checkPopup = currentState;
  },
  SET_CALENDAR_POPUP(state, currentState) {
    state.checkCalendarPopup = currentState;
  },
  SET_SEARCH_POPUP(state, currentState) {
    state.checkSearchPopup = currentState;
  }
};

const actions = {};

export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
