<template>
  <div class="filter-context">
    <div class="btn-wrap-absolute">
      <button type="button" @click="search(filterOption, 'search-button')">검색</button>
      <button type="button" @click="reset(); search(filterOption, 'search-button');">초기화</button>
    </div>
    <div class="filter">
      <div class="article">
        <h5 class="f-title">성별</h5>
        <div class="f-content">
          <div class="radio-input-wrap">
            <div class="item">
              <input type="radio" id="entire" v-model="filterOption.gender" value="전체" />
              <label for="entire">전체</label>
            </div>
            <div class="item">
              <input type="radio" id="male" v-model="filterOption.gender" value="남성" />
              <label for="male">남성</label>
            </div>
            <div class="item">
              <input type="radio" id="female" v-model="filterOption.gender" value="여성" />
              <label for="female">여성</label>
            </div>
          </div>
        </div>
      </div>
      <div class="article">
        <h5 class="f-title">나이</h5>
        <div class="f-content">
          <div class="input-wrap">
            <input type="number" class="center" v-model="filterOption.ageFirst" />
          </div>
          <span class="separate">~</span>
          <div class="input-wrap">
            <input class="center" type="number" v-model="filterOption.ageLast" />
          </div>
        </div>
      </div>
      <div class="article">
        <h5 class="f-title">
          <p v-text="dateLabel1" />
        </h5>
        <div class="f-content">
          <div class="calendar-wrap">
            <div class="item" @click="callCalendarPop(0)">
              <p v-text="filterOption.signUpFirst" />
            </div>
            <span class="separate">~</span>
            <div class="item" @click="callCalendarPop(1)">
              <p v-text="filterOption.signUpLast" />
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="today(0)">오늘</button>
            <button type="button" @click="month(0)">1개월</button>
            <button type="button" @click="halfYear(0)">6개월</button>
            <button type="button" @click="entire(0)">1년</button>
          </div>
        </div>
      </div>
      <div class="article" v-if="dateLabel2 !== null && dateLabel2 !== undefined" >
        <h5 class="f-title">
          <p v-text="dateLabel2" />
        </h5>
        <div class="f-content">
          <div class="calendar-wrap">
            <div class="item" @click="callCalendarPop(2)">
              <p v-text="filterOption.signUpFirst2" />
            </div>
            <span class="separate">~</span>
            <div class="item" @click="callCalendarPop(3)">
              <p v-text="filterOption.signUpLast2" />
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="today(1)">오늘</button>
            <button type="button" @click="month(1)">1개월</button>
            <button type="button" @click="halfYear(1)">6개월</button>
            <button type="button" @click="entire(1)">1년</button>
          </div>
        </div>
      </div>
      <div class="article">
        <h5 class="f-title">검색</h5>
        <div class="f-content">
          <div class="input-wrap wide">
            <input type="text" placeholder="이름 / 생년월일(8자리) / 연락처('-' 제외)" @keyup.enter="search(filterOption, 'search-button')" v-model="filterOption.search" />
          </div>
        </div>
      </div>
    </div>
    <calendar-popup
      @selected-date="selectedDate"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import CalendarPopup from "@/components/modal/CalendarPopup";
import { mapMutations, mapState } from "vuex";

export default {
  props: {
    dateLabel1: {
      type: String,
      default: '예약일'
    },
    dateLabel2: {
      type: String,
      default: null
    },
  },
  data() {
    return {
      filterOption: {
        gender: "전체",
        ageFirst: null,
        ageLast: null,
        search: "",
        signUpFirst: null,
        signUpLast: null,
        signUpFirst2: null,
        signUpLast2: null,
        listAmount: 10,
        clickedPage: 1
      },
      dateOpt: {
        today: null,
        month: null,
        halfYear: null,
        entire: null
      },
      popupSetCalendar: {},
    };
  },
  created() {
    this.initDateOpt();
  },
  methods: {
    ...mapMutations("basic", ["SET_CALENDAR_POPUP"]),
    selectedDate(date, order) {
      if (order === 0) {
        this.filterOption.signUpFirst = date;
      } else if (order === 1) {
        this.filterOption.signUpLast = date;
      } else if (order === 2) {
        this.filterOption.signUpFirst2 = date;
      } else if (order === 3) {
        this.filterOption.signUpLast2 = date;
      }
      console.log("가져온 date :", date);
      console.log("가져온 order :", order);
    },
    callCalendarPop(order) {
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.rangeOrder = order;
    },
    reset() {
      this.filterOption.gender = "전체";
      this.filterOption.ageFirst = null;
      this.filterOption.ageLast = null;
      this.filterOption.search = "";
      this.filterOption.signUpFirst = null;
      this.filterOption.signUpLast = null;
      this.filterOption.signUpFirst2 = null;
      this.filterOption.signUpLast2 = null;
    },
    initDateOpt() {
      this.dateOpt.today = this.$moment(new Date()).format("YYYY-MM-DD");
      this.dateOpt.month = this.$moment(
        new Date(new Date().setMonth(new Date().getMonth() - 1))
      ).format("YYYY-MM-DD");
      this.dateOpt.halfYear = this.$moment(
        new Date(new Date().setMonth(new Date().getMonth() - 6))
      ).format("YYYY-MM-DD");
      this.dateOpt.entire = this.$moment(
        new Date(new Date().setFullYear(new Date().getFullYear() - 1))
      ).format("YYYY-MM-DD");
    },
    changeClickedPage(value) {
      this.filterOption.clickedPage = value;
      this.search(this.filterOption);
    },
    changeListAmount(value) {
      this.filterOption.listAmount = value;
      this.search(this.filterOption, "changeAmount");
    },
    search(option, type) {
      console.log("전달받은 option", option);

      if (type != undefined)
        option.clickedPage = 1;

      let stringVal = "?page=" + option.clickedPage;
      stringVal += "&maxPage=" + option.listAmount;
      if (option.gender !== null && option.gender !== '0' && option.gender !== '전체'){
        stringVal += "&gender=" + option.gender;
      }
      if (option.ageFirst !== null && option.ageFirst != '') {
        stringVal += "&ageFirst=" + option.ageFirst;
      }
      if (option.ageLast !== null && option.ageLast != '') {
        stringVal += "&ageLast=" + option.ageLast;
      }
      if (option.signUpFirst !== null && option.signUpFirst != '') {
        stringVal += "&signUpFirst=" + option.signUpFirst;
      }
      if (option.signUpLast !== null && option.signUpLast != '') {
        stringVal += "&signUpLast=" + option.signUpLast;
      }
      if (option.signUpFirst2 !== null && option.signUpFirst2 != '') {
        stringVal += "&signUpFirst2=" + option.signUpFirst2;
      }
      if (option.signUpLast2 !== null && option.signUpLast2 != '') {
        stringVal += "&signUpLast2=" + option.signUpLast2;
      }
      if (option.search !== null && option.search !== "") {
        stringVal += "&search=" + encodeURI(encodeURIComponent(option.search));
      }
      this.$emit("request-list", stringVal, option, type);
    },
    today(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.today;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.today;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    },
    month(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.month;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.month;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    },
    halfYear(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.halfYear;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.halfYear;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    },
    entire(index) {
      if (index == 0) {
        this.filterOption.signUpFirst = this.dateOpt.entire;
        this.filterOption.signUpLast = this.dateOpt.today;
      } else if (index == 1) {
        this.filterOption.signUpFirst2 = this.dateOpt.entire;
        this.filterOption.signUpLast2 = this.dateOpt.today;
      }
    }
  },
  computed: {
    ...mapState("basic", ["checkCalendarPopup"])
  },
  components: {
    CalendarPopup
  }
};
</script>
<style lang="scss" scoped>
.filter-context {
  position: relative;
  padding: 40px;
  background: #f3f3f3;
  margin: 0 0 50px 0;
  .btn-wrap-absolute {
    position: absolute;
    bottom: 20px;
    right: 20px;
    button {
      border: 0;
      padding: 0;
      width: 105px;
      height: 40px;
      background: #f36900;
      margin: 0 0 0 10px;
      cursor: pointer;
      @include initfontKor(
        $font-size: 19px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
    }
  }
  .filter {
    .article {
      margin: 20px 0 0 0;
      display: flex;
      &:first-of-type {
        margin: 0;
      }
      h5.f-title {
        width: 140px;
        white-space: nowrap;
        @include initfontKor(
          $font-size: 22px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .f-content {
        width: calc(100% - 140px);
        display: flex;
        .radio-input-wrap {
          display: flex;
          .item {
            display: flex;
            align-items: center;
            margin: 0 0 0 20px;
            &:first-of-type {
              margin: 0;
            }
            input[type="radio"] {
              border-radius: 50%;

              &:after {
                width: 19px;
                height: 19px;
                border-radius: 50%;
                background: var(--active-inner);
                opacity: 0;
                transform: scale(var(--s, 0.7));
              }

              &:checked {
                --s: 0.5;
              }
              &:disabled {
                &:checked {
                  background: #146f83 !important;
                }
              }
            }
            input {
              --active: #146f83;
              --active-inner: #fff;
              --focus: 2px rgb(20 111 131 / 34%);
              --border: #bbc1e1;
              --border-hover: rgb(20 111 131 / 34%);
              --background: #fff;
              --disabled: #f6f8ff;
              --disabled-inner: #e1e6f9;
              -webkit-appearance: none;
              -moz-appearance: none;
              height: 21px;
              outline: none;
              display: inline-block;
              vertical-align: top;
              position: relative;
              margin: 0;
              cursor: pointer;
              border: 1px solid var(--bc, var(--border));
              background: var(--b, var(--background));
              transition: background 0.3s, border-color 0.3s, box-shadow 0.2s;
              &:after {
                content: "";
                display: block;
                left: 0;
                top: 0;
                position: absolute;
                transition: transform var(--d-t, 0.3s) var(--d-t-e, ease), opacity var(--d-o, 0.2s);
              }

              &:checked {
                --b: var(--active);
                --bc: var(--active);
                --d-o: 0.3s;
                --d-t: 0.6s;
                --d-t-e: cubic-bezier(0.2, 0.85, 0.32, 1.2);
              }

              &:disabled {
                --b: var(--disabled);
                cursor: not-allowed;
                opacity: 0.9;

                &:checked {
                  --b: var(--disabled-inner);
                  --bc: var(--border);
                }

                & + label {
                  cursor: not-allowed;
                }
              }

              &:hover {
                &:not(:checked) {
                  &:not(:disabled) {
                    --bc: var(--border-hover);
                  }
                }
              }

              &:focus {
                box-shadow: 0 0 0 var(--focus);
              }

              &:not(.switch) {
                width: 21px;

                &:after {
                  opacity: var(--o, 0);
                }

                &:checked {
                  --o: 1;
                }
              }

              & + label {
                font-family: "NotoSansCJKkr";
                font-size: 18px;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: 29px;
                letter-spacing: normal;
                text-align: left;
                color: #000000;
                cursor: pointer;
                margin: 0 0 0 8px;
              }
            }
          }
        }
        .input-wrap {
          width: 110px;
          height: 40px;
          &.wide {
            width: 452px;
            ::placeholder{
              @include initfontKor(
                $font-size: 20px,
                $line-height: 40px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #a9adb1,
                $letter-spacing: -0.6px,
                $text-align: left
              );
            }
          }
          &:first-of-type {
          }
          input {
            width: inherit;
            border: 0;
            padding: 0 20px;
            @include initfontKor(
              $font-size: 22px,
              $line-height: 40px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
            &.center {
              text-align: center;
            }
          }
        }
        .calendar-wrap {
          display: flex;
          margin: 0 30px 0 0;
          .item {
            min-width: 210px;
            position: relative;
            height: 40px;
            background: transparent;
            cursor: pointer;
            &:after {
              position: absolute;
              content: "";
              background-image: url("~@/assets/images/util/icon_calendar_color.svg");
              width: 30px;
              height: 30px;
              right: 10px;
              top: calc(50% - 1.5px);
              transform: translateY(-50%);
            }
            p {
              width: 100%;
              height: inherit;
              padding: 0 20px;
              background: #fff;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 40px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
          }
        }
        .btn-wrap {
          button {
            margin: 0 0 0 15px;
            min-width: 90px;
            padding: 0;
            background: #fd8b11;
            border: 0;
            cursor: pointer;
            @include initfontKor(
              $font-size: 19px,
              $line-height: 40px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              margin: 0;
            }
          }
        }
        span.separate {
          display: block;
          width: 12px;
          margin: 0 10px;
          @include initfontKor(
            $font-size: 22px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
    }
  }
}
</style>
