<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="excel-btn">
          <div class="btn-wrap" @click="download()">
            <p>Excel 다운로드</p>
          </div>
        </div>
        <h5 class="title">상담자 정보 관리</h5>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          @list-reversal="reversalResult"
          v-if="isListVisible"
          ref="list"
        ></list>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import axios from 'axios';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      tree: {
        title: '회원 관리',
        detail: '상담자 정보 관리',
      },
      columnInfo: [
        {
          word: '아이디',
          filter: 'id',
        },
        {
          word: '이름',
          filter: 'user_name',
        },
        {
          word: '서명등록',
          filter: 'signature',
        },
        {
          word: '작성자',
          filter: 'author',
        },
        {
          word: '작성일',
          filter: 'update_at',
        },
      ],
      listInfo: {
        type: 3,
        data: [],
        isHideAmount: true,
      },
      isListVisible: false,
      /** api Param */
      requestParam: '',
      requestParamOrder: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.requestParam = '?page=1';
    this.getResult();
  },
  methods: {
    ...mapActions('member', ['FETCH_CONSULT_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    reversalResult(info) {
      this.requestParamOrder = info;
      this.fetchData(false);
    },
    async fetchData(isInit) {
      try {
        await this.FETCH_CONSULT_LIST(this.requestParam + this.requestParamOrder).then((data) => {
          if (isInit) {
            this.listInfo.data = data.response.list;
            this.listInfo.totalDataCnt = data.response.list.length;
            this.isListVisible = true;
          } else {
            this.$refs.list.initList(data.response.list, data.response.list.length);
          }
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    getResult() {
      this.fetchData(true);
    },
    async download() {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/admin_account/consult_list/excel`,
        responseType: 'blob',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          console.log(response, ' : response');
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '상담자 리스트.xlsx');
          document.body.appendChild(link);
          link.click();
        });
    },
  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.container {
  .excel-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    .btn-wrap {
      cursor: pointer;
      background: #fd8b11;
      p {
        margin: 0 10px;
         @include initfontKor(
          $font-size: 15px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
</style>
