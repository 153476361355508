<template>
  <div class="notification-wrap">
    <div class="content-container">
      <h3 v-text="tree.title" />
      <div class="img-wrap" v-if="tree.detail !== null">
        <img src="@assets/images/util/back_trans.png" />
      </div>
      <h3 v-text="tree.detail" v-if="tree.detail !== null" />
      <div class="img-wrap" v-if="tree.detail2 !== null && tree.detail2 !== undefined">
        <img src="@assets/images/util/back_trans.png" />
      </div>
      <h3 v-text="tree.detail2" v-if="tree.detail2 !== null && tree.detail2 !== undefined" />
      <div class="img-wrap" v-if="tree.detail3 !== null && tree.detail3 !== undefined">
        <img src="@assets/images/util/back_trans.png" />
      </div>
      <h3 v-text="tree.detail3" v-if="tree.detail3 !== null && tree.detail3 !== undefined" />
      <button @click="logout()">
        로그아웃
      </button>
    </div>
  </div>
</template>

<script>
import { mapActions, } from "vuex";

export default {
  props: {
    tree: {
      type: Object
    }
  },
  data() {
    return {
      popupSet: {},
    };
  },
  created() {},
  methods: {
    ...mapActions('util', ["LOGOUT"]),
    async logout() {
      await this.LOGOUT().then(data => {
        console.log('logout data :', data)
        localStorage.removeItem("token")
        localStorage.removeItem("userId")
        localStorage.removeItem("userName")
        localStorage.removeItem("storeId")
        this.$emit('call-logout-popup')
      })
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.notification-wrap {
  background: #f3f3f3;
  padding: 0 60px;
  height: 80px;
  box-shadow: 0 3px 6px 0 rgba(54, 55, 57, 0.15);
  .content-container {
    display: flex;
    align-items: center;
    h3 {
      @include initfontKor(
        $font-size: 21px,
        $line-height: 80px,
        $font-family: "AppleSDGothicNeoR00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
    .img-wrap {
      width: 10px;
      margin: 0 12.5px;
      img {
        max-width: 100%;
      }
    }
    button {
      margin: 0 0 0 15px;
      min-width: 90px;
      padding: 0;
      background: #f36900;
      border: 0;
      cursor: pointer;
      position: absolute;
      right: 30px;
      @include initfontKor(
        $font-size: 19px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
    }
  }
}
</style>
