<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">회원 정보 관리</h5>
        <list-filter
          :dateLabel1="dateLabel1"
          :dateLabel2="dateLabel2"
          @request-list="injectionResult"
          ref="filter"
        ></list-filter>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          @list-reversal="reversalResult"
          @list-amount-change="changedListAmount"
          v-if="isListVisible"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          ref="pagination"
          v-if="isPaginationVisible"
        ></pagination>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import ListFilter from '@/components/ListFilter.vue';
import List from '@/components/List.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        startPage: null,
        lastPage: null,
        currentPage: null,
        length: null,
      },

      tree: {
        title: '회원 관리',
        detail: '회원 정보 관리',
      },
      listInfo: {
        type: 1,
        data: [],
        isHideAmount: false,
      },
      columnInfo: [
        {
          word: '가입일',
          filter: 'createAt',
        },
        {
          word: '이름',
          filter: 'userName',
        },
        {
          word: '성별',
          filter: 'gender',
        },
        {
          word: '생년월일',
          filter: 'birthDate',
        },
        {
          word: '연락처',
          filter: 'tel',
        },
        {
          word: '최근 상담일',
          filter: 'lastConsultantAt',
        },
        {
          word: '가입일',
          filter: 'authorAt',
        },
        {
          word: '정보보기',
          filter: null,
        },
      ],
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      requestParam: '',
      requestParamOrder: '',
      dateLabel1: '가입일',
      dateLabel2: '최근상담일',
    };
  },
  /** API 데이터 로딩주기 */
  created() {
    // localStorage.setItem(
    //   "token",
    //   "c778815b739866cc2c4d884f1f73e4639847babca9e928635e0f6a8a9d946e7b"
    // );
    // window.app.$children[0].$children[0].$children[0].callInUnAuth();
    /** 로그아웃처리 */
    // localStorage.removeItem("token");
    // localStorage.removeItem("userId");
    // localStorage.removeItem("userName");
    // localStorage.removeItem("storeId");
    // const url = "samkim://logout";
    // document.location.href = url;

    window.scrollTo(0, 0);
    this.getResult();
  },
  methods: {
    ...mapActions('member', ['FETCH_ACCOUNT_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    changedListAmount(listAmountVal) {
      this.$refs.filter.changeListAmount(listAmountVal);
    },
    reversalResult(info) {
      this.requestParamOrder = info;
      const curPage = this.$refs.pagination.paginationStatus.currentPage;
      this.fetchData(false, curPage, undefined);
    },
    async fetchData(isInit, page, type) {
      this.dateLabel2 = '최근상담일';
      try {
        await this.FETCH_ACCOUNT_LIST(this.requestParam + this.requestParamOrder).then((data) => {
          if (isInit) {
            this.listInfo.data = data.response.list;
            this.listInfo.totalDataCnt = data.response.totalCount;
            this.isListVisible = true;
            this.getPaginationInfo(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              1,
            );
          } else {
            this.$refs.list.initList(data.response.list, data.response.totalCount, ((type != undefined ? 1 : page) - 1) * this.$refs.filter.filterOption.listAmount);
            this.$refs.pagination.injectPagination(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              type !== undefined ? 1 : page,
            );
          }
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    getResult() {
      /**
       * API 실행하여 리스트값을 구함
       * ?list?page=1&maxPage=10&default=1

       *  */
      const defaultParam = '?page=1&maxPage=10&default=1';
      this.requestParam = defaultParam;
      this.fetchData(true, null, null);
    },
    getPaginationInfo(totalDataCnt, startPage, lastPage, currentPage) {
      /**
       * page, maxPage, default 인자로 페이지네이션 API 실행함
       * */
      this.paginationInfo.totalDataCnt = totalDataCnt;
      this.paginationInfo.startPage = startPage;
      this.paginationInfo.lastPage = lastPage;
      if (currentPage !== null) {
        this.paginationInfo.currentPage = currentPage;
      } else {
        this.paginationInfo.currentPage = 1;
      }
      this.isPaginationVisible = true;
    },
    paginationClick(pageOrder) {
      /** 페이지네이션 번호 클릭 */
      console.log('전달할 친구들', pageOrder);
      this.$refs.filter.changeClickedPage(pageOrder);
    },
    injectionResult(info, option, type) {
      /**
       * 리스트 다시 그리는 요청을 받았을 때 실행
       * (필터 설정 후 검색, 페이지네이션 클릭, 리스트 수 셀렉트박스 변동)
       * */
      console.log('filter 값 :', info);
      console.log('재료', option);
      console.log('리스트 수 셀렉트박스에서 변동되었는지 여부', type);
      console.log('클릭한 페이지', option.clickedPage);
      this.requestParam = info;
      this.fetchData(false, option.clickedPage, type);
    },
  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    ListFilter,
    List,
    Pagination,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
}
</style>
