<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">자세법과 식이법 리스트</h5>
        <list-filter @request-list="injectionResult" ref="filter"></list-filter>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          @list-reversal="reversalResult"
          @list-amount-change="changedListAmount"
          v-if="isListVisible"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          v-if="isPaginationVisible"
          ref="pagination"
        ></pagination>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import ListFilter from "@/components/ListFilter.vue";
import List from "@/components/List.vue";
import Pagination from "@/components/Pagination.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        startPage: null,
        lastPage: null,
        currentPage: null,
        length: null
      },
      listInfo: {
        type: 9,
        data: []
      },
      tree: {
        title: "자세법과 식이법",
        detail: "자세법과 식이법 리스트"
      },
      columnInfo: [
        // {
        //   word: "아이디",
        //   filter: "id"
        // },
        {
          word: "이름",
          filter: "name"
        },
        {
          word: "자세법과 식이법",
          filter: "posture&Diet"
        },
        {
          word: "활동일자",
          filter: "activityDate"
        }
      ],
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      requestParam: "",
      requestParamOrder: "",
    };
  },
  created() {
    // localStorage.setItem(
    //   "token",
    //   "c778815b739866cc2c4d884f1f73e4639847babca9e928635e0f6a8a9d946e7b"
    // );
    window.scrollTo(0, 0);
    this.getResult();
  },
  methods: {
    ...mapActions("activity", ["FETCH_PF_LIST"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    callLogoutPopup() {
      this.SET_POPUP(true)
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    changedListAmount(listAmountVal) {
      this.$refs.filter.changeListAmount(listAmountVal);
    },
    async fetchData(isInit, page, type) {
      try {
        await this.FETCH_PF_LIST(this.requestParam + this.requestParamOrder).then(data => {
          if (isInit) {
            this.listInfo.data = data.response.list;
            this.listInfo.totalDataCnt = data.response.totalCount;
            this.isListVisible = true;
            this.getPaginationInfo(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              1
            );
          }
          else {
            this.$refs.list.initList(data.response.list, data.response.totalCount, ((type != undefined ? 1 : page) - 1) * this.$refs.filter.filterOption.listAmount);
            this.$refs.pagination.injectPagination(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              type != undefined ? 1 : page
            );
          }
        });
      } catch (error) {
        console.log("error:", error);
      }
    },
    reversalResult(info) {
      this.requestParamOrder = info;
      this.fetchData(false, this.$refs.pagination.paginationStatus.currentPage, undefined);
    },
    getResult() {
      this.requestParam = "?page=1&maxPage=10&default=1";
      this.fetchData(true, null, undefined);
    },
    getPaginationInfo(totalDataCnt, startPage, lastPage, currentPage) {
      /**
       * page, maxPage, default 인자로 페이지네이션 API 실행함
       * */
      this.paginationInfo.totalDataCnt = totalDataCnt;
      this.paginationInfo.startPage = startPage;
      this.paginationInfo.lastPage = lastPage;
      if (currentPage !== null) {
        this.paginationInfo.currentPage = currentPage;
      } else {
        this.paginationInfo.currentPage = 1;
      }
      this.isPaginationVisible = true;
    },
    paginationClick(pageOrder) {
      /** 페이지네이션 번호 클릭 */
      console.log("전달할 친구들", pageOrder);
      this.$refs.filter.changeClickedPage(pageOrder);
    },
    injectionResult(info, option, type) {
      this.requestParam = info;
      this.fetchData(false, option.clickedPage, type);
    }
  },
  mounted() {},
  computed: {
    ...mapState("basic", ["checkPopup"])
  },
  components: {
    SideMenu,
    Notification,
    ListFilter,
    List,
    Pagination,
    DefaultPopup
  }
};
</script>
<style lang="scss" scoped>
.app-context {
}
</style>
