<template>
  <div class="posture-wrap in-tab">
    <div class="list-wrap" v-if="listType === 0">
      <div class="list" v-for="(listItem, index) in result" :key="index + 'A'">
        <h5 class="title" v-text="listItem.questionText" />
        <div class="content">
          <div
            class="item"
            v-for="(answerItem, index) in listItem.answerList"
            :key="index + 'B'"
            :class="{ active: answerItem.isSelected }"
          >
            <p v-html="computedLine(answerItem.question)" />
            <p v-html="computedLine(answerItem.answer)" />
            <div class="btn-wrap first" v-if="false">
              <button
                type="button"
                v-if="answerItem.isCompleted"
                @click="callResultPop(answerItem)"
              >
                결과보기
              </button>
            </div>
            <div class="btn-wrap second" v-if="false">
              <button type="button" @click="download(answerItem.question)">
                다운로드
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-wrap tripple" v-if="listType === 1">
      <div class="list" v-for="(listItem, index) in result" :key="index + 'A'">
        <h5 class="title" v-text="listItem.questionText" />
        <div class="content">
          <div
            class="item"
            v-for="(answerItem, index) in listItem.answerList"
            :key="index + 'B'"
            :class="{ active: answerItem.isSelected }"
          >
            <p v-text="answerItem.title" />
            <p v-html="computedLine(answerItem.question)" />
            <p v-html="computedLine(answerItem.answer)" class="first" />
            <div class="btn-wrap first">
              <button
                type="button"
                v-if="answerItem.isCompleted"
                @click="callResultPop(answerItem)"
              >
                결과보기
              </button>
            </div>
            <div class="btn-wrap second" v-if="false">
              <button type="button" @click="download(answerItem.question)">
                다운로드
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listType: {
      type: Number
    },
    result: {
      type: Array
    }
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    callResultPop(info) {
      this.$emit("call-result-pop", info);
    },
    download(info) {
      this.$emit("call-download", info);
    }
  },
  computed: {
    computedLine() {
      return text => text.split("\n").join("<br />");
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
@import "@assets/css/posture";
</style>
