<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="member-info-set">
          <h5 class="title">인적사항</h5>
          <div class="header">
            <div class="item" v-for="(header, index) in member" :key="index + 'A'">
              <p v-text="header.questionText" />
            </div>
          </div>
          <div class="content">
            <div class="item" v-for="(value, index) in member" :key="index + 'B'">
              <p v-text="value.answerResult" />
            </div>
          </div>
        </div>
        <div class="consultant-info">
          <h5 class="title">상담자 정보</h5>
          <div class="header">
            <div class="item">
              <p>이름</p>
            </div>
            <div class="item">
              <p>상태</p>
            </div>
          </div>
          <div class="content">
            <div class="item">
              <p v-text="consultant.choicedConsultant" />
            </div>
            <div class="item">
              <p
                class="status"
                v-text="consultant.choicedConsultant === '-' ? '등록' : '수정'"
                @click="callSetConsultantPop(consultant)"
              ></p>
            </div>
          </div>
        </div>
        <div class="reservation-info">
          <h5 class="title">예약정보</h5>
          <list
            :columnInfo="columnInfo"
            :listInfo="listInfo"
            @call-wish-pop="callWishPop"
            @call-calender-pop="callCalendarPop"
            @call-cancle-pop="callCanclePop"
            @call-type-pop="callTypePop"
            v-if="isListVisible"
            ref="list"
          ></list>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @choiced-consultant="consultantSetApi"
      @api-called="deleteResvApi"
    />
    <calendar-popup
      @selected-resv-data="selectedResv"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import CalendarPopup from '@/components/modal/CalendarPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      popupSetCalendar: {},
      tree: {
        title: '원격 상담',
        detail: '예약 관리 및 상담 내역',
        detail2: '예약 상세 내역',
      },
      member: [],
      consultant: {
        consultantList: [],
        choicedConsultant: '',
        list: [],
      },
      listInfo: {
        type: 11,
        data: [],
      },
      columnInfo: [
        {
          word: '상담유형',
          filter: 'consultationType',
        },
        {
          word: '예약일',
          filter: 'consultationDate',
        },
        {
          word: '예약시간',
          filter: 'consultationTime',
        },
        {
          word: '상담 희망 내용',
          filter: 'hopeConts',
        },
        {
          word: '상태',
          filter: 'state',
        },
        /**
         * 0: 예약완료
         * 1: 회원 취소
         * 2: 관리자 취소
         * 3: 관리자 예약 변경
         * 4: 상담완료
         */
        {
          word: '상태 변경',
          filter: 'statusChange',
        },
      ],
      /** api Param */
      requestParam: '',
      selectedConsultant: '',
      isListVisible: false,
    };
  },
  created() {
    console.log(`param code : ${this.$route.params.code}`);
    window.scrollTo(0, 0);
    this.fetchData();
  },
  methods: {
    ...mapActions('member', ['FETCH_CONSULT_LIST']),
    ...mapActions('reservation', ['FETCH_CONSULTATION', 'UPDATE_CONSULTATION']),
    ...mapMutations('basic', ['SET_POPUP', 'SET_CALENDAR_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async fetchData() {
      // 상담자 목록 설정
      await this.FETCH_CONSULT_LIST('?page=1').then((data) => {
        console.log(data.response.list);
        const list = [];

        for (let i = 0; i < data.response.list.length; i++) {
          data.response.list[
            i
          ].user_conm = `${data.response.list[i].user_name}(${data.response.list[i].id})`;
          list.push(data.response.list[i].user_conm);
        }

        this.consultant.consultantList = list;
        this.consultant.list = data.response.list;
      });

      // 인적사항, 상담자 정보, 예약정보 설정
      await this.FETCH_CONSULTATION(`?id=${this.$route.params.code}`).then((data) => {
        console.log(data.response, 'do');

        // 인적사항
        const getList = [
          {
            question: 1,
            questionText: '회원명',
            answerResult: data.response.userName,
          },
          {
            question: 2,
            questionText: '성별',
            answerResult: data.response.gender,
          },
          {
            question: 3,
            questionText: '생년월일',
            answerResult: data.response.birthDate,
          },
          {
            question: 4,
            questionText: '연락처',
            answerResult: data.response.tel,
          },
        ];
        this.member = getList;

        // 상담자 정보
        this.consultant.choicedConsultant = data.response.consultant
          + (data.response.consultantId === null || data.response.consultantId === undefined
            ? ''
            : `(${data.response.consultantId})`);

        // 예약정보 설정
        const list = {
          id: this.$route.params.code,
          consultationDate: data.response.consultationDate,
          consultationTime: data.response.consultationTime,
          state: data.response.state,
          requestContent: data.response.requestContent,
          type: data.response.type,
          // searchDate: "2020-02-01"
        };
        this.listInfo.data = list;
        this.isListVisible = true;
      });
    },
    deleteResvApi() {
      /** 삭제할 예약의 value 를 서버에 전송(아마도  ID값) */
      const data = {
        id: this.$route.params.code,
        state: 2,
      };
      this.UPDATE_CONSULTATION(data).then((data) => {
        if (data.result) {
          this.SET_POPUP(true);
          this.popupSet = {};
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '예약이 취소 되었습니다.';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
          this.popupSet.returnList = true;
          // this.popupSet.nextLink = '/ReservationList';
        }
      });
    },
    successPop() {
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '예약이 변경되었습니다.';
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.confirmBtnText = null;
    },
    selectedResv(dataSet) {
      console.log('데이터', dataSet);
      /** 저장한 데이터의 속성을 활용하여 예약변경 API 를 실행 */
      const data = {
        id: this.$route.params.code,
        state: 4,
        consultationDate: dataSet.date,
        consultationTime: dataSet.time,
      };
      this.UPDATE_CONSULTATION(data).then((data) => {
        if (data.result) {
          this.$refs.list.result.consultationDate = dataSet.date;
          this.$refs.list.result.consultationTime = dataSet.time;
          this.successPop();
        }
      });
    },
    callCalendarPop(info) {
      console.log('가져온 날짜정보', info);
      /** 가져온 날짜로 달력 API 조회 */
      this.SET_CALENDAR_POPUP(true);
      this.popupSetCalendar.notificationType = 1;
      this.popupSetCalendar.isTimeTable = true;
    },
    callCanclePop(info) {
      console.log('텍스트박스 정보 기입', info);
      /** 가저온 날짜로 해당 예약내역 취소 API 실행 */
      /** api 조회 */
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '예약을 취소하시겠습니까?';
      this.popupSet.cancelBtnText = '아니오';
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = '예';
    },
    callWishPop(info) {
      console.log('텍스트박스 정보 기입', info);
      /** 가저온 정보를 텍스트박스에 기입 */
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popType = 'wish';
      this.popupSet.wishFix = true;
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.wishContent = info.requestContent;
      this.popupSet.nextLink = null;
      this.popupSet.confirmBtnText = null;
    },
    callTypePop(info) {
      console.log('텍스트박스 정보 기입', info);

      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popType = 'popType';
    },
    consultantSetApi(info) {
      console.log('info :', info);
      /** 선택한 사람을 상담자로 지정하는 API 실행
       *  선택한 상담자로 view를 바꿔주기 위함
       */
      const consultantInfo = this.consultant.list.find((e) => {
        if (e.user_conm === info) {
          return true;
        }
      });

      if (consultantInfo !== null && consultantInfo !== undefined) {
        console.log('consultantInfo :', consultantInfo);
        const data = {
          id: this.$route.params.code,
          consultant: consultantInfo.user_name,
          consultantId: consultantInfo.id,
        };
        this.UPDATE_CONSULTATION(data).then((data) => {
          if (data.result) {
            this.SET_POPUP(true);
            this.popupSet = {};
            this.popupSet.overlayRestrict = true;
            this.popupSet.popImg = 'success.svg';
            this.popupSet.content = '상담자가 변경되었습니다.';
            this.popupSet.cancelBtnText = '확인';
            this.popupSet.confirmBtnText = null;

            this.consultant.choicedConsultant = info;
          }
        });
      } else {
        console.log('not found consultant');
        this.SET_POPUP(true);
        this.popupSet = {};
        this.popupSet.overlayRestrict = true;
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '선택하신 상담자를 찾을 수 없습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
      }
    },
    callSetConsultantPop(list) {
      /** 리스트 정리
       * '
       */
      this.SET_POPUP(true);
      this.popupSet.popType = 'setConsultant';
      this.popupSet.consultOpt = this.consultant;
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkCalendarPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    DefaultPopup,
    CalendarPopup,
  },
};
</script>
<style lang="scss" scoped>
.member-info-set {
  margin: 0 0 85px 0;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 90px 180px 180px;
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 125px 90px 180px 180px;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.consultant-info {
  margin: 0 0 85px 0;
  width: 310px;
  .header {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 140px 1fr;
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 140px 1fr;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      display: flex;
      align-items: center;
      justify-content: center;
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        &.status {
          width: 105px;
          line-height: 32px;
          padding: 0;
          background: #292a2b;
          color: #fff;
          margin: 8px auto;
          cursor: pointer;
        }
      }
    }
  }
}

.reservation-info {
  width: 1037px;
  margin: 0 auto 140px 0;
}
</style>
