<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">공지사항 상세</h5>
        <div class="notice">
          <div class="column-info">
            <div class="article">
              <h6 class="header">제목</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="form.title.value" />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">작성일</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="form.writedDate.value" />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">작성자</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="form.writer.value" />
                </div>
              </div>
            </div>
          </div>
          <div class="content-wrap">
            <!-- 에디터 자리 -->
            <editor :editorData="form.content.value" @sendEditContent="synchronization" :afterEditorData="form.content.value" ></editor>
          </div>
          <div class="attachment-wrap">
            <div class="attachment viewer">
              <div class="a-input-wrap">
                <span class="file-name" v-text="form.attachment.filename" @click="downloadFile()" />
                <input
                  class="hidden file-upload"
                  type="file"
                  @click.stop
                  @change="onFileChange($event)"
                />
              </div>
              <div class="a-btn-wrap">
                <button type="button" @click="attachmentFile()">
                  파일첨부
                </button>
              </div>
            </div>
            <!--
            <div class="preview-img" v-if="form.attachment.url !== ''">
              <img :src="form.attachment.url" />
            </div>
             -->
          </div>
          <div class="bottom-btn-wrap">
            <button type="button" @click="$router.go(-1)">이전으로</button>
            <button type="button" class="confirm" @click="submitNotice()" v-if="isModify">수정하기</button>
            <button type="button" class="confirm" @click="submitNotice()" v-else>등록하기</button>
            <button type="button" class="delete" @click="callDeletePop()" v-if="isModify">삭제하기</button>
          </div>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @api-called="deleteNotice" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import Editor from "@/components/Editor.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: "설정",
        detail: "공지사항 관리",
        detail2: "공지사항 상세"
      },
      form: {
        title: {
          value: "",
          flag: false
        },
        writedDate: {
          value: "",
          flag: false
        },
        writer: {
          value: "",
          flag: false
        },
        content: {
          value: "",
          flag: false
        },
        attachment: {
          title: "서명",
          flag: false,
          source: null,
          url: "",
          filename: ""
        }
      },
      isModify: false,
    };
  },
  created() {
    // code = undefined : 추가 / not null : 수정(공지사항 pk)
    console.log(`params code : ${this.$route.params.code}`);
    window.scrollTo(0, 0);
    this.fetchData();
  },
  mounted() {},
  methods: {
    ...mapActions("util", ["FETCH_NOTICE", "INSERT_NOTICE", "UPDATE_NOTICE", "DELETE_NOTICE"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    callLogoutPopup() {
      this.SET_POPUP(true)
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    async submitNotice() {
      console.log("form :", this.form);
      
      let formData = new FormData()
      formData.append('pk', this.$route.params.code)
      formData.append('title', this.form.title.value)
      formData.append('content', this.form.content.value)
      formData.append('author', this.form.writer.value)
      formData.append('createAt', this.form.writedDate.value)

      if (this.form.attachment.flag)
        formData.append('file', this.form.attachment.source)

      let result = true

      // 신규 작성
      if (this.$route.params.code == undefined) {
        await this.INSERT_NOTICE(formData).then(data => {
          result = data.result
        })
      }
      else {
        await this.UPDATE_NOTICE(formData).then(data => {
          result = data.result
        })
      }

      this.SET_POPUP(true);
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = result ? "공지사항이 등록 되었습니다." : "공지사항 등록에 실패했습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/NoticeList";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    deleteNotice() {
      /** 삭제할 예약의 value 를 서버에 전송(아마도  ID값) */
      setTimeout(() => {
        this.DELETE_NOTICE(`?id=${this.$route.params.code}`).then(data => {
          this.SET_POPUP(true);
          this.popupSet.popImg = "success.svg";
          this.popupSet.content = data.result ? "공지사항이 삭제 되었습니다." : "공지사항 삭제에 실패했습니다.";
          this.popupSet.cancelBtnText = null;
          this.popupSet.nextLink = "/NoticeList";
          this.popupSet.confirmBtnText = "확인";
          this.popupSet.destination = true;
        });
      }, 1);
    },
    callDeletePop() {
      // 신규 작성 삭제 제한
      if (this.$route.params.code == undefined) {
        return;
      }

      this.SET_POPUP(true);
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "공지사항을 삭제하시겠습니까?";
      this.popupSet.cancelBtnText = "아니오";
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = "예";
    },
    async fetchData(){
      if (this.$route.params.code === undefined) {
        let date = new Date();
        let str = date.getFullYear().toString().padStart(4, '0') + '-' +
                  (date.getMonth() + 1).toString().padStart(2, '0') + '-' +
                  date.getDate().toString().padStart(2, '0') + ' ' +
                  date.getHours().toString().padStart(2, '0') + ':' +
                  date.getMinutes().toString().padStart(2, '0') + ':' +
                  date.getSeconds().toString().padStart(2, '0');
        this.form.writedDate.value = this.transDateTime(`${str}`);
        this.form.writer.value = localStorage.getItem('userName');
        return;
      }

      await this.FETCH_NOTICE(`?pk=${this.$route.params.code}`).then(async data => {
          console.log('공지사항 상세 : ', data.response)

          this.isModify = true;

          this.form.title.value = data.response.title;
          this.form.writedDate.value = this.transDateTime(data.response.createAt);
          this.form.writer.value = data.response.author;
          this.synchronization(data.response.content);

          if (data.response.file !== undefined && data.response.file !== null && data.response.file != '') {
            this.form.attachment.filename = decodeURI(data.response.file.substring(data.response.file.lastIndexOf("/") + 1))
            this.form.attachment.url = `${process.env.VUE_APP_MAIN_URL}${data.response.file}`
          }
      });
    },
    leadingZeros(n, digits) {
      var zero = '';
      n = n.toString();

      if (n.length < digits) {
          for (i = 0; i < digits - n.length; i++)
              zero += '0';
      }
      return zero + n;
    },
    getList() {},
    synchronization(editorData) {
      this.form.content.value = editorData;
    },
    onFileChange(event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      this.form.attachment.flag = true;
      this.form.attachment.source = event.target.files[0];
      this.form.attachment.filename = getFile[0].name;
      this.form.attachment.url = getFileUrl;
    },
    attachmentFile() {
      document.querySelector("input.file-upload").click();
    },
    downloadFile() {
      if (this.form.attachment.url == undefined ||
          this.form.attachment.url === null ||
          this.form.attachment.url == '' ||
          this.form.attachment.filename == undefined ||
          this.form.attachment.filename === null ||
          this.form.attachment.filename == '')
        return

      let url = this.form.attachment.url
      let filename = this.form.attachment.filename
      
      fetch(url).then(async f => {
        let blob = await f.blob()

        let a = document.createElement('a')
        a.setAttribute('download', filename)
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    },
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    transDateTime() {
      return date => {
        console.log("date :", date);

        if (date === null)
          return "";

        return this.$moment(date).format("YYYY-MM-DD HH:mm:ss");
      };
    },
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    Editor
  }
};
</script>
<style lang="scss" scoped>
.notice {
  .column-info {
    width: 710px;
    margin: 0 0 20px 0;
    .article {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      grid-template-columns: 205px 1fr;
      margin: 1px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      h6.header {
        outline: 1px solid #acb3c9;
        background: #f3f3f3;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 32px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
      .content {
        outline: 1px solid #acb3c9;
        .input-wrap {
          width: 100%;
          margin: 0 10px 0 0;
          input {
            width: 100%;
            border: 0;
            padding: 0 12px;
            @include initfontKor(
              $font-size: 16px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
          }
        }
      }
    }
  }
  .content-wrap {
    width: 710px;
    margin: 0 0 20px 0;
  }
  .attachment-wrap {
    width: 100%;
    margin: 0 0 65px 0;
    .attachment {
      display: flex;
      flex-wrap: wrap;
      .a-input-wrap {
        border: 1px solid #acb3c9;
        height: 30px;
        width: 310px;
        margin: 0 15px 0 0;
        cursor: pointer;
        span.file-name {
          padding: 0 12px;
          display: block;
          width: inherit;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 30px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
        input.hidden {
          display: none;
        }
      }
      .a-btn-wrap {
        width: 105px;
        height: 32px;
        background: #fd8b11;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        border-radius: 6px;
        overflow: hidden;

        button {
          border-radius: 0;
          border: 0;
          background: transparent;
          height: 100%;
          width: 100%;
          @include initfontKor(
            $font-size: 19px,
            $line-height: 32px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          cursor: pointer;
        }
      }
    }
    .preview-img {
      margin: 25px 0 0 0;
      width: 460px;
      position: relative;
      img {
        max-width: 100%;
      }
    }
  }
  .bottom-btn-wrap {
    button {
      width: 140px !important;
      &.delete {
        background: #ff0000;
      }
      &.confirm {
        background: #fd8b11;
      }
    }
  }
}
/**


 */
</style>
