import { member } from '@/api';

const state = {
  util: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  },
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
};

const getters = {
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  DOWNLOAD_ACCOUNT_EXCEL({}, payload) {
    return member.downloadExcelAccount(payload);
  },
  FETCH_ACCOUNT_LIST({}, payload) {
    return member.fetchAccountList(payload);
  },
  FETCH_ACCOUNT_DETAIL({}, payload) {
    return member.fetchAccountDetail(payload);
  },
  POST_ACCOUNT_DETAIL({}, payload) {
    return member.postAccountDetail(payload);
  },
  FETCH_ACCOUNT_SET_LIST({ commit, getters }, payload) {
    return member.fetchAccountSetList(payload);
  },
  UPDATE_ACCOUNT_SET_MEMBER({ commit, getters }, payload) {
    return member.updateAccountSetMember(payload);
  },
  DELETE_ACCOUNT({ commit, getters }, payload) {
    return member.deleteAccount(payload);
  },
  FETCH_ACTIVITY_POSITION_LIST({ commit, getters }, payload) {
    return member.fetchActivityPositionList(payload);
  },
  UPDATE_ACTIVITY_POSITION({ commit, getters }, payload) {
    return member.updateActivityPosition(payload);
  },
  FETCH_ACTIVITY_FEEDING_LIST({ commit, getters }, payload) {
    return member.fetchActivityFeedingList(payload);
  },
  UPDATE_ACTIVITY_FEEDING({ commit, getters }, payload) {
    return member.updateActivityFeeding(payload);
  },
  FETCH_MANAGE_LIST({}, payload) {
    return member.fetchManageList(payload);
  },
  FETCH_MANAGE_DETAIL({}, payload) {
    return member.fetchManageDetail(payload);
  },
  POST_MANAGE_DETAIL({}, payload) {
    return member.postManageDetail(payload);
  },
  DELETE_MANAGE_DETAIL({}, payload) {
    return member.deleteManageDetail(payload);
  },
  FETCH_CONSULT_LIST({}, payload) {
    return member.fetchConsultList(payload);
  },
  FETCH_CONSULT_DETAIL({}, payload) {
    return member.fetchConsultDetail(payload);
  },
  FETCH_CONSULT_SIGN({}, payload) {
    return member.fetchConsultSign(payload);
  },
  POST_CONSULT_DETAIL({}, payload) {
    return member.postConsultDetail(payload);
  },
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
