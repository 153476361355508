<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title special">앱 관리</h5>
        <div class="version">
          <h5 class="title">버전관리</h5>
          <div class="article-wrap">
            <div class="article">
              <h6 class="header">버전</h6>
              <div class="content">
                <div class="input-wrap">
                  <input
                    type="text"
                    v-model="version.currentVersion.value"
                    @input="isWritedVersion()"
                  />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">옵션</h6>
              <div class="content">
                <div class="select-wrap">
                  <v-select
                    :searchable="false"
                    @input="choiceVersionOpt()"
                    :options="version.versionStatusList"
                    v-model="version.versionStatus"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="versionApi()">저장</button>
          </div>
        </div>
        <div class="push">
          <h5 class="title">알림 관리</h5>
          <div class="article-wrap">
            <div class="article">
              <h6 class="header">회원아이디</h6>
              <div class="content flex">
                <div class="input-wrap">
                  <input type="text" v-model="push.id.value" disabled />
                </div>
                <button type="button" @click="searchUserPop()">회원검색</button>
              </div>
            </div>
            <div class="article">
              <h6 class="header">URL</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="push.url.value" @input="isWritedUrl()" />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">제목</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="push.title.value" @input="isWritedTitle()" />
                </div>
              </div>
            </div>
            <div class="article">
              <h6 class="header">내용</h6>
              <div class="content">
                <div class="input-wrap">
                  <input type="text" v-model="push.content.value" @input="isWritedContent()" />
                </div>
              </div>
            </div>
          </div>
          <div class="btn-wrap">
            <button type="button" @click="sendPush()">발송</button>
          </div>
        </div>
        <!-- <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div> -->
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <search-popup v-if="checkSearchPopup" @get-member-id="applyMember" />
  </div>
</template>

<script>
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SearchPopup from "@/components/modal/SearchPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import vSelect from "vue-select";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: "설정",
        detail: "앱 관리"
      },
      version: {
        id: undefined,
        currentVersion: {
          flag: false,
          value: ""
        },
        versionStatus: "버전확인",
        versionStatusList: ["버전확인", "권고설치", "강제설치"]
      },
      push: {
        id: {
          flag: false,
          value: ""
        },
        url: {
          flag: false,
          value: ""
        },
        title: {
          flag: false,
          value: ""
        },
        content: {
          flag: false,
          value: ""
        }
      }
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getVersion();
  },
  methods: {
    ...mapActions("util", [
      "FETCH_APP_VERSION",
      "INSERT_APP_VERSION",
      "UPDATE_APP_VERSION",
      "SEND_PUSH"
    ]),
    ...mapMutations("basic", ["SET_POPUP", "SET_SEARCH_POPUP"]),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    isWritedVersion() {
      this.version.currentVersion.flag = false;
      const version = this.version.currentVersion.value.replace(/\s/gi, "");
      if (version === "") {
        this.version.currentVersion.flag = false;
        return false;
      }
      this.version.currentVersion.flag = true;
      return true;
    },
    isWritedUrl() {
      this.push.url.flag = false;
      const url = this.push.url.value.replace(/\s/gi, "");
      if (url === "") {
        this.push.url.flag = false;
        return false;
      }
      this.push.url.flag = true;
      return true;
    },
    isWritedTitle() {
      this.push.title.flag = false;
      const title = this.push.title.value.replace(/\s/gi, "");
      if (title === "") {
        this.push.title.flag = false;
        return false;
      }
      this.push.title.flag = true;
      return true;
    },
    isWritedContent() {
      this.push.content.flag = false;
      const content = this.push.content.value.replace(/\s/gi, "");
      if (content === "") {
        this.push.content.flag = false;
        return false;
      }
      this.push.content.flag = true;
      return true;
    },
    applyMember(index, item) {
      console.log("index :", index, "id :", item.id);
      this.push.id.value = item.id;
      this.push.id.flag = true;
    },
    checkFlag() {
      if (
        !this.push.id.flag ||
        !this.push.url.flag ||
        !this.push.title.flag ||
        !this.push.content.flag
      ) {
        if (!this.push.id.flag) {
          this.popupSet.content = "회원 아이디를 선택해주세요";
          this.push.id.flag = false;
        } else if (!this.push.url.flag) {
          this.popupSet.content = "URL을 입력해주세요";
          this.push.url.flag = false;
        } else if (!this.push.title.flag) {
          this.popupSet.content = "제목을 입력해주세요";
          this.push.title.flag = false;
        } else if (!this.push.content.flag) {
          this.popupSet.content = "내용을 입력해주세요";
          this.push.content.flag = false;
        }
        this.SET_POPUP(true);
        this.popupSet.popType = "warn";
        this.popupSet.popImg = "success.svg";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.confirmBtnText = null;
        return false;
      }
      return true;
    },
    sendPush() {
      console.log("push :", this.push);
      /** 푸시정보를 서버에 보냄 */
      if (!this.checkFlag()) return;

      let data = {
        id: this.push.id.value,
        url: this.push.url.value,
        title: this.push.title.value,
        body: this.push.content.value
      };

      this.SEND_PUSH(data).then(data => {
        let result = data.result;
        this.SET_POPUP(true);
        this.popupSet.popImg = "success.svg";
        this.popupSet.content = result ? "알림이 발송되었습니다." : "알림 발송에 실패하였습니다.";
        this.popupSet.cancelBtnText = "확인";
        this.popupSet.confirmBtnText = null;
      });
    },
    searchUserPop() {
      this.SET_SEARCH_POPUP(true);
    },
    async versionApi() {
      this.popupSet.cancelBtnText = "확인";
      this.popupSet.confirmBtnText = null;

      if (!this.version.currentVersion.flag) {
        this.SET_POPUP(true);
        this.popupSet.popImg = "success.svg";
        this.popupSet.content = "버전을 설정해주세요";
      } else if (
        !this.version.isSelected ||
        this.version.versionStatusList.findIndex(x => x === this.version.versionStatus) === -1
      ) {
        this.SET_POPUP(true);
        this.popupSet.popImg = "success.svg";
        this.popupSet.content = "옵션을 설정해주세요";
      } else {
        const appInfo = {
          id: this.version.id,
          version: this.version.currentVersion.value,
          option: this.version.versionStatusList.findIndex(x => x === this.version.versionStatus)
        };
        console.log("appinfo : ", appInfo);

        if (this.version.id === undefined) {
          await this.INSERT_APP_VERSION(appInfo).then(data => {
            console.log("data##### : ", data);
            if (data.result === true) {
              this.getVersion();

              this.SET_POPUP(true);
              this.popupSet.popImg = "success.svg";
              this.popupSet.content = "버전 상태가 변경되었습니다.";
            }
          });
        } else {
          await this.UPDATE_APP_VERSION(appInfo).then(data => {
            console.log("data##### : ", data);
            if (data.result === true) {
              this.getVersion();

              this.SET_POPUP(true);
              this.popupSet.popImg = "success.svg";
              this.popupSet.content = "버전 상태가 변경되었습니다.";
            }
          });
        }
      }
    },
    async getVersion() {
      await this.FETCH_APP_VERSION().then(data => {
        console.log(data);
        this.version.id =
          data.response.id === null || data.response.id === undefined
            ? undefined
            : data.response.id;
        this.version.currentVersion.value =
          data.response.version === null ? "1.0.0" : data.response.version;
        this.version.versionStatus =
          data.response.option === null
            ? this.version.versionStatusList[0]
            : this.version.versionStatusList[data.response.option];

        this.version.currentVersion.flag = true;
        this.version.isSelected = true;
      });
    },
    choiceVersionOpt() {
      this.version.isSelected = true;
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup", "checkSearchPopup"])
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    SearchPopup,
    vSelect
  }
};
</script>
<style lang="scss" scoped>
.wrap-context .container h5.special {
  margin: 0 0 45px 0;
}
.version {
  width: 730px;
  margin: 0 0 65px 0;
  h5.title {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 34px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #292a2b,
      $letter-spacing: -0.6px,
      $text-align: left
    );
  }
  .article-wrap {
    display: grid;
    grid-auto-flow: dense;
    grid-gap: 1px;
    grid-template-columns: 1fr 1fr;
    margin: 0 0 30px 0;
    .article {
      display: grid;
      grid-auto-flow: dense;
      outline: 1px solid #acb3c9;
      grid-gap: 1px;
      grid-template-columns: 170px 1fr;
      h6.header {
        outline: 1px solid #acb3c9;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        padding: 0 15px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .content {
        outline: 1px solid #acb3c9;
        display: flex;
        align-items: center;
        p {
          padding: 0 15px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    button {
      width: 105px;
      border-radius: 4px;
      border: 0;
      padding: 0;
      cursor: pointer;
      background: #292a2b;
      @include initfontKor(
        $font-size: 16px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }
}
.push {
  width: 730px;
  margin: 0 0 65px 0;
  h5.title {
    @include initfontKor(
      $font-size: 20px,
      $line-height: 34px,
      $font-family: "AppleSDGothicNeoB00",
      $color: #292a2b,
      $letter-spacing: -0.6px,
      $text-align: left
    );
  }
  .article-wrap {
    margin: 0 0 30px 0;
    .article {
      display: grid;
      grid-auto-flow: dense;
      outline: 1px solid #acb3c9;
      grid-gap: 1px;
      grid-template-columns: 170px 1fr;
      margin: 1px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      h6.header {
        outline: 1px solid #acb3c9;
        background: #f3f3f3;
        display: flex;
        align-items: center;
        padding: 0 15px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 60px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .content {
        outline: 1px solid #acb3c9;
        display: flex;
        align-items: center;
        &.flex {
          button {
            border: 0;
            padding: 0;
            background: #fd8b11;
            width: 105px;
            cursor: pointer;
            @include initfontKor(
              $font-size: 16px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #fff,
              $letter-spacing: -0.6px,
              $text-align: center
            );
          }
        }
        .input-wrap {
          margin: 0 15px;
          input {
            width: 190px;
            height: inherit;
            border: 1px solid #acb3c9;
            padding: 0;
            padding: 0 12px;
            @include initfontKor(
              $font-size: 16px,
              $line-height: 30px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
          }
        }
        p {
          padding: 0 15px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: left
          );
        }
      }
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    button {
      width: 105px;
      border-radius: 4px;
      border: 0;
      padding: 0;
      cursor: pointer;
      background: #292a2b;
      @include initfontKor(
        $font-size: 16px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }
}
</style>
<style lang="scss">
.version .select-wrap {
  margin: 10px 15px;
  .v-select {
    .vs__selected {
      line-height: 40px;
    }
  }
}
</style>
