<template>
  <div class="sidemenu-context">
    <div class="side-header">
      <div class="img-wrap">
        <img src="@assets/images/util/logo-samkim-side.png" />
      </div>
      <div class="side-title">
        <h3>삼킴-SMAP</h3>
        <p class="type">관리자</p>
      </div>
    </div>
    <div class="side-content">
      <div class="section">
        <h3 class="section-title" @click="test($event, 0)">회원 관리</h3>
        <router-link class="section-link" to="/" @click.native="gotoUrl($event)">회원 정보 관리</router-link>
        <router-link class="section-link" to="/patientSettingList" @click.native="gotoUrl($event)">회원별 설정</router-link>
        <router-link class="section-link" to="/consultantList" @click.native="gotoUrl($event)">상담자 정보 관리</router-link>
        <router-link class="section-link" to="/managementList" @click.native="gotoUrl($event)">관리자 관리</router-link>
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 1)">삼킴 평가</h3>
        <router-link class="section-link" to="/clinicList" @click.native="gotoUrl($event)">삼킴 임상 평가 리스트</router-link>
        <router-link class="section-link" to="/explainList" @click.native="gotoUrl($event)">삼킴 설문 리스트</router-link>
        <!-- <router-link to="">삼킴 기기 측정 리스트</router-link> -->
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 2)">삼킴 훈련</h3>
        <router-link class="section-link" to="/trainingList" @click.native="gotoUrl($event)">삼킴 훈련 리스트</router-link>
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 4)">자세법과 식이법</h3>
        <router-link class="section-link" to="/postureDietList" @click.native="gotoUrl($event)">자세법과 식이법 리스트</router-link>
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 5)">원격 상담</h3>
        <router-link class="section-link" to="/reservationList" @click.native="gotoUrl($event)">예약 관리 및 상담 내역</router-link>
        <router-link class="section-link" to="/reservationLimit" @click.native="gotoUrl($event)">예약 시간별 제한 설정</router-link>
        <router-link class="section-link" to="/scheduleList" @click.native="gotoUrl($event)">상담자 스케줄 조회</router-link>
      </div>
      <div class="section">
        <h3 class="section-title" @click="test($event, 6)">설정</h3>
        <router-link class="section-link" to="/setting" @click.native="gotoUrl($event)">앱 관리</router-link>
        <router-link class="section-link test" to="/noticeList" @click.native="gotoUrl($event)">공지사항 관리</router-link>
        <a href="https://www.bizmsg.kr/">알림톡 관리</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},
  data() {
    return {
      preUrl: '',
    };
  },
  created() {
    this.preUrl = this.$route.path;
  },
  methods: {
    callInUnAuth() {
      console.log('[callInUnAuth]');
      this.$emit('is-unauthorized');
    },
    gotoUrl(ev) {
      if (this.preUrl === this.$route.path) {
        this.$router.go();
        // window.location.reload()
      }

      this.preUrl = this.$route.path;
    },
    test(ev, index) {
      const section = document.querySelectorAll('.section')[index];

      if (section === undefined) { return; }

      const link = section.querySelectorAll('.section-link');

      if (link === undefined) { return; }

      for (let i = 0; i < link.length; i++) {
        if (link[i].classList.contains('hide')) {
          link[i].classList.remove('hide');
        } else {
          link[i].classList.add('hide');
        }
      }
    },
  },
  computed: {},
  components: {},
};
</script>
<style lang="scss" scoped>
.sidemenu-context {
  width: 260px;
  background: #fff9f3;
  box-shadow: 0 3px 6px 0 rgba(54, 55, 57, 0.15);
  .side-header {
    padding: 15px;
    background: #fd8b11;
    display: flex;
    align-items: center;
    height: 80px;
    .img-wrap {
      img {
        width: 44px;
        height: auto;
      }
    }
    .side-title {
      display: flex;
      h3 {
        @include initfontKor(
          $font-size: 22px,
          $line-height: 29px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        position: relative;
        padding: 0 10px;
        &:after {
          position: absolute;
          content: "";
          width: 1px;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          height: 20px;
          background: #fff;
        }
      }
      p.type {
        @include initfontKor(
          $font-size: 19px,
          $line-height: 29px,
          $font-family: "AppleSDGothicNeoR00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        padding: 0 10px;
      }
    }
  }
  .side-content {
    padding: 0 0 80px 0;
    .section {
      padding: 20px;
      position: relative;
      border-top: 1px solid #acb3c9;
      &.pressed {
        &:after {
          transform: rotate(180deg);
        }
      }
      &:after {
        position: absolute;
        width: 20px;
        height: 11px;
        content: "";
        top: 27px;
        right: 20px;
        background-image: url("~@/assets/images/util/dropdown.svg");
        cursor: pointer;
      }
      &:first-of-type {
        border-top: 0;
      }
      h3.section-title {
        @include initfontKor(
          $font-size: 19px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoB00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
        margin: 0 0 8px 0;
      }
      .router-link-exact-active {
        color: #fff;
        background: #fd8b11;
        border-radius: 12px;
      }
      .section-link {
        opacity: 1;
      }
      .section-link.hide {
        opacity: 0;
        height: 0;
        visibility: hidden;
      }
      a {
        width: 100%;
        display: block;
        &:hover {
          color: #fff;
          background: #f36900;
          border-radius: 12px;
        }
        &:first-of-type {
          margin: 0;
        }
        @include initfontKor(
          $font-size: 19px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
        position: relative;
        margin: 5px 0 0 0;
        padding: 0 0 0 25px;
        &:after {
          content: "-";
          position: absolute;
          left: 7.5px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
</style>
