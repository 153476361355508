<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="excel-btn">
          <div class="btn-wrap" @click="downloadExcel()">
            <p>Excel 다운로드</p>
          </div>
        </div>
        <div class="patient-info">
          <h5 class="title">회원 정보</h5>
          <list
            :columnInfo="patientColumnInfo"
            :listInfo="patientListInfo"
            v-if="isPatientListVisible"
            ref="patientList"
          ></list>
        </div>
        <div class="consult-complex">
          <div class="info">
            <h5 class="title">상담 내역</h5>
            <list
              :columnInfo="consultantColumnInfo"
              :listInfo="consultantListInfo"
              @load-list-detail="loadConsultantDetail"
              v-if="isConsultantListVisible"
              ref="consultantList"
            ></list>
          </div>
          <div class="detail">
            <h5 class="title">상세보기</h5>
            <div class="content-wrap">
              <div
                class="consultant-time"
                v-if="consultantDetail.consultationDatetime !== undefined"
              >
                <h5 class="title">상담시간</h5>
                <div
                  class="btn-wrap"
                  v-if="
                    consultantDetail.consultationEnd === undefined ||
                      consultantDetail.consultationEnd === null ||
                      consultantDetail.consultationEnd === ''
                  "
                >
                  <button type="button" @click="endConsultant()">종료</button>
                </div>
                <div class="article">
                  <h6 class="header">시작</h6>
                  <div class="content">
                    <p v-text="consultantDetail.consultationDatetime" />
                  </div>
                </div>
                <div class="article">
                  <h6 class="header">종료</h6>
                  <div class="content">
                    <p v-text="consultantDetail.consultationEnd" />
                  </div>
                </div>
              </div>
              <div
                class="consultant-info"
                v-if="consultantDetail.consultationDatetime !== undefined"
              >
                <div class="article">
                  <h6 class="header">제목</h6>
                  <div class="content">
                    <input type="text" v-model="consultantDetail.title" />
                  </div>
                </div>
                <div class="article">
                  <h6 class="header">내용</h6>
                  <div class="content">
                    <textarea v-model="consultantDetail.content" />
                  </div>
                </div>
                <div class="article">
                  <h6 class="header">서명</h6>
                  <div class="content">
                    <div class="signature">
                      <img
                        :src="consultantDetail.signature"
                        v-if="
                          consultantDetail.signature !== undefined &&
                            consultantDetail.signature !== null
                        "
                      />
                    </div>
                    <div class="btn-wrap">
                      <button type="button" @click="storeConsultant()">저장</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import axios from 'axios';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: '원격상담',
        detail: '상담 내용 기록',
      },
      patientColumnInfo: [
        {
          word: '회원명',
          filter: 'userName',
        },
        {
          word: '성별',
          filter: 'gender',
        },
        {
          word: '생년월일',
          filter: 'birthDate',
        },
        {
          word: '최초 상담일',
          filter: 'firstConsultantDate',
        },
        {
          word: '특이사항',
          filter: 'note',
        },
        {
          word: '상병명',
          filter: 'woundName',
        },
        {
          word: '질환명',
          filter: 'disorderName',
        },
      ],
      consultantColumnInfo: [
        {
          word: '시간',
          filter: 'consultationDatetime',
        },
        {
          word: '유형',
          filter: 'type',
        },
        {
          word: '제목',
          filter: 'title',
        },
        {
          word: '상담자',
          filter: 'consultant',
        },
      ],
      patientListInfo: {
        type: 13,
        data: {},
      },
      consultantListInfo: {
        type: 14,
        data: [],
      },
      consultantDetail: {},
      isPatientListVisible: false,
      isConsultantListVisible: false,
    };
  },
  created() {
    console.log('param query : ', this.$route.query);
    window.scrollTo(0, 0);
    this.fetchData();
    this.startVideoChat();
  },
  mounted() {},
  methods: {
    ...mapActions('reservation', ['UPDATE_CONSULTATION', 'START_CONSULTATION']),
    ...mapActions('consultant', [
      'FETCH_CONSULTATION_CONTENT',
      'UPDATE_CONSULTATION_CONTENT',
      'FETCH_CONSULTATION_CONTENT_LIST',
    ]),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async startVideoChat() {
      if (this.$route.query.ready) {
        const data = {
          id: this.$route.query.id,
        };
        await this.START_CONSULTATION(data).then((data) => {
          console.log('video data : ', data);
          const url = `https://remote.swallow.pro/?groupId=${data.response.groupId}&threadId=${data.response.threadId}`;
          window.open(url);
        });
      }
    },
    async fetchData() {
      await this.FETCH_CONSULTATION_CONTENT(`?id=${this.$route.query.id}`).then((data) => {
        // 회원 정보
        data.response.signature = `${process.env.VUE_APP_MAIN_URL}${data.response.signature}`;
        data.response.consultationDatetime = `${data.response.consultationDate} ${data.response.consultationTime}`;

        this.consultantDetail = data.response;
        if (this.isPatientListVisible) {
          this.$refs.patientList.initList(data.response);
        } else {
          this.patientListInfo.data = data.response;
          this.isPatientListVisible = true;
        }

        this.FETCH_CONSULTATION_CONTENT_LIST(`?userId=${data.response.userId}`).then((data) => {
          // 상담 내역
          for (let i = 0; i < data.response.list.length; i++) {
            const item = data.response.list[i];
            item.consultationDatetime = `${item.consultationDate
            }<br />${
              item.consultationTime.substring(0, 5)
            } ~ ${
              item.consultationEnd === null || item.consultationEnd === undefined
                ? ''
                : item.consultationEnd.substring(0, 5)}`;
          }
          console.log('consult list : ', data.response.list);

          if (this.isConsultantListVisible) {
            this.$refs.consultantList.initList(data.response.list);
          } else {
            this.consultantListInfo.data = data.response.list;
            this.isConsultantListVisible = true;
          }
        });
      });
    },
    storeConsultant() {
      /**
       * 해당 리스트의 내용을 저장함
       */
      const data = {
        id: this.consultantDetail.id,
        note: this.$refs.patientList.result.note,
        woundName: this.$refs.patientList.result.woundName,
        disorderName: this.$refs.patientList.result.disorderName,
        title: this.consultantDetail.title,
        content: this.consultantDetail.content,
      };
      console.log('저장 data :', data);
      this.UPDATE_CONSULTATION_CONTENT(data).then((data) => {
        console.log('response :', data);
        this.SET_POPUP(true);
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = data.result
          ? '상담내역이 저장되었습니다.'
          : '상담내역 저장에 실패하였습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
      });
    },
    endConsultant() {
      /**
       * (1) API 통신 후 상담을 종료시킴
       * (2) MS 팀즈를 종료시킴
       * (3) 서버의 해당 리스트 재조회
       * */
      const data = {
        id: this.consultantDetail.id,
        endFlag: true,
      };
      this.UPDATE_CONSULTATION_CONTENT(data).then((data) => {
        if (data.result) {
          this.SET_POPUP(true);
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = '상담이 종료되었습니다.';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;

          this.fetchData();
        }
      });
    },
    loadConsultantDetail(index, time) {
      console.log('index :', index);
      console.log('time :', time);
      /** 순번과 상담시간으로 상세정보 API 조회 */
      const pk = this.$refs.consultantList.result[index].id;

      this.FETCH_CONSULTATION_CONTENT(`?id=${pk}`).then((data) => {
        // 회원 정보
        data.response.signature = `${process.env.VUE_APP_MAIN_URL}${data.response.signature}`;
        data.response.consultationDatetime = `${data.response.consultationDate} ${data.response.consultationTime}`;
        console.log('response : ', data.response);
        this.consultantDetail = data.response;
      });
    },
    async downloadExcel() {
      const data = {
        id: this.patientListInfo.data.userId,
      };
      console.log(`download-data : ${data.id}`);
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/consultation/content/excel?userId=${data.id}`,
        responseType: 'blob',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          console.log(response, ' : response');
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.patientListInfo.data.userName}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    List,
  },
};
</script>
<style lang="scss" scoped>
.container {
  .excel-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    .btn-wrap {
      cursor: pointer;
      background: #fd8b11;
      p {
        margin: 0 10px;
         @include initfontKor(
          $font-size: 15px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.patient-info {
}
.consult-complex {
  display: flex;
  .info {
    width: 490px;
    margin: 0 40px 0 0;
  }
  .detail {
    width: calc(100% - 530px);
    h5.title {
    }
    .content-wrap {
      background: #fff9f3;
      border-radius: 12px;
      padding: 40px 105px;
      min-height: 580px;
    }
    .consultant-time {
      width: 340px;
      margin: 0 auto 65px auto;
      position: relative;
      h5.title {
        font-size: 16px;
        line-height: 40px;
        text-align: center;
        background: #fd8b11;
        color: #fff;
        outline: 1px solid #292a2b;
        margin: 0;
      }
      .btn-wrap {
        position: absolute;
        right: -65px;
        top: 0;
        button {
          border: 0;
          padding: 0;
          outline: 1px solid #292a2b;
          width: 65px;
          border-radius: 0px;
          background: #292a2b;
          cursor: pointer;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
      .article {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 105px 1fr;
        margin: 1px 0 0 0;
        &:first-of-type {
          margin: 0;
        }
        h6.header {
          background: #f3f3f3;
          width: 105px;
          padding: 0;
          outline: 1px solid #292a2b;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        .content {
          padding: 0;
          outline: 1px solid #292a2b;
          background: #fff;
          p {
            @include initfontKor(
              $font-size: 16px,
              $line-height: 40px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
          }
        }
      }
    }
    .consultant-info {
      width: 100%;
      .article {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 240px 1fr;
        margin: 1px 0 0 0;
        h6.header {
          outline: 1px solid #292a2b;
          background: #f3f3f3;
          display: flex;
          align-items: center;
          justify-content: center;
          @include initfontKor(
            $font-size: 18px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
        .content {
          outline: 1px solid #292a2b;
          background: #fff;
          display: flex;
          align-items: center;
          input,
          textarea {
            width: 100%;
            padding: 0 12px;
            @include initfontKor(
              $font-size: 16px,
              $line-height: 40px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: left
            );
          }
          textarea {
          }
          .signature {
            width: calc(100% - 105px);
            img {
              max-height: 50px;
            }
          }
          .btn-wrap {
            button {
              border: 0;
              padding: 0;
              width: 105px;
              background: #292a2b;
              cursor: pointer;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 48px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #fff,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
          }
        }
      }
    }
  }
}
</style>
