<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <list-detail-on-check
          :settingList="settingList"
          @return-checked-list="getCheckedList"
          @call-setting-pop="settingPop"
          v-if="isListVisible"
          ref="listDetail"
        ></list-detail-on-check>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @api-called="settingApi" />
  </div>
</template>

<script>
import ListDetailOnCheck from "@/components/ListDetailOnCheck.vue";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import { mapMutations, mapState, mapActions } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      settingList: {
        list: [],
        member: [],
        listType: 1
      },
      tree: {
        title: "회원 관리",
        detail: "회원별 설정",
        detail2: "자세법 설정"
      },
      isListVisible: false,
      apiParam: null
    };
  },
  created() {
    // localStorage.setItem(
    //   "token",
    //   "c778815b739866cc2c4d884f1f73e4639847babca9e928635e0f6a8a9d946e7b"
    // );
    window.scrollTo(0, 0);
    this.getData(this.$route.params.code);
  },
  methods: {
    ...mapActions("member", ["FETCH_ACTIVITY_POSITION_LIST", "UPDATE_ACTIVITY_POSITION"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    callLogoutPopup() {
      this.SET_POPUP(true)
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = "로그인 권한이 필요합니다.";
      this.popupSet.popImg = "warning.svg";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    getCheckedList(list) {
      this.apiParam = list;
    },
    async settingApi() {
      await this.$refs.listDetail.extract();
      console.log("API 연결시 사용할 재료 :", this.apiParam);
      let positionList = [];
      for (let i = 0; i < this.apiParam.length; i++)
      {
        for (let j = 0; j < this.apiParam[i].answerList.length; j++)
        {
          positionList.push({position_content_id: this.apiParam[i].answerList[j].id
                           , is_selected: this.apiParam[i].answerList[j].checkFlag});
        }
      }
      
      console.log(`id : ${this.$route.params.code}`)
      console.log(`position_list : ${positionList}`);
      let data = {
        id: this.$route.params.code,
        position_list : positionList,
      };

      await this.UPDATE_ACTIVITY_POSITION(data).then(result => {
        this.$refs.listDetail.$router.go(-1);
      });
    },
    settingPop() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "자세법 설정을 변경하시겠습니까?";
      this.popupSet.cancelBtnText = "취소";
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = "완료";
    },
    async getData(index) {
      /** API 조회하여 데이터를 info 에 주입 */
      try {
        await this.FETCH_ACTIVITY_POSITION_LIST(`?id=${index}`).then(data => {
          const getList = [
            {
              columnName: "회원명",
              value: data.response.name,
            }
          ];
          this.settingList.member = getList;
          this.settingList.list = data.response.result_list;
          this.isListVisible = true;
        });
      } catch (error) {
        console.log("error:", error);
      }
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"])
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    ListDetailOnCheck
  }
};
</script>
<style lang="scss" scoped></style>
