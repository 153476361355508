import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import basic from "./modules/basic";
import auth from "./modules/auth";
import activity from "./modules/activity";
import evaluate from "./modules/evaluate";
import util from "./modules/util";
import member from "./modules/member";
import training from "./modules/training";
import reservation from "./modules/reservation";
import consultant from "./modules/consultant";

const modules = {
  basic,
  auth,
  activity,
  evaluate,
  util,
  member,
  training,
  reservation,
  consultant,
};

const plugins = [
  createPersistedState({
    paths: ["auth"]
  })
];
Vue.use(Vuex);
export const store = new Vuex.Store({
  modules,
  plugins
});
