import { util } from "@/api";

const state = {
    util: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  }
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
};

const getters = {
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  // FETCH_LOGOUT({ commit,getters }, payload) {
  //   return util.fetchLogout(payload);
  // },
  FETCH_LOGIN({ commit,getters }, payload) {
    return util.fetchLogin(payload);
  },
  LOGOUT({}) {
    return util.Logout()
  },
  FETCH_NOTICE_LIST({commit,getters}, payload) {
    return util.fetchNoticeList(payload);
  },
  FETCH_NOTICE({commit,getters}, payload) {
    return util.fetchNotice(payload);
  },
  INSERT_NOTICE({commit,getters}, payload) {
    return util.insertNotice(payload);
  },
  UPDATE_NOTICE({commit,getters}, payload) {
    return util.updateNotice(payload);
  },
  DELETE_NOTICE({commit,getters}, payload) {
    return util.deleteNotice(payload);
  },
  FETCH_APP_VERSION({commit,getters}) {
    return util.fetchAppVersion();
  },
  INSERT_APP_VERSION({commit,getters}, payload) {
    return util.insertAppVersion(payload);
  },
  UPDATE_APP_VERSION({commit,getters}, payload) {
    return util.updateAppVersion(payload);
  },
  SEND_PUSH({}, payload) {
    return util.sendPush(payload);
  }
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
