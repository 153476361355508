<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="excel-btn">
          <div class="btn-wrap" @click="download()">
            <p>Excel 다운로드</p>
          </div>
        </div>
        <div class="info-detail">
          <section>
            <h5 class="title">인적사항</h5>
            <div class="context">
              <div class="item" v-for="(memberItem, index) in member" :key="index + 'AA'">
                <h6 class="header" v-text="memberItem.questionText" />
                <input
                  class="content"
                  v-if="memberItem.questionText == '연락처'"
                  v-model="form.tel.value"
                />
                <p class="content" v-else v-text="memberItem.answerResult" />
              </div>
              <div class="item" v-for="(infoItem, index) in info.slice(0, 6)" :key="index + 'AB'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'-'" />
              </div>
              <div class="item" v-for="(infoItem, index) in info.slice(29, 30)" :key="index + 'AC'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'-'" />
              </div>
              <div class="item" v-for="(infoItem, index) in info.slice(6, 8)" :key="index + 'AD'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'-'" />
              </div>
            </div>
          </section>
          <section>
            <h5 class="title">구강건강</h5>
            <div class="context">
              <div class="item" v-for="(infoItem, index) in info.slice(8, 12)" :key="index + 'BA'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'-'" />
              </div>
              <div class="item" v-for="(infoItem, index) in info.slice(26, 29)" :key="index + 'BB'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'-'" />
              </div>
              <div class="item" v-for="(infoItem, index) in info.slice(12, 14)" :key="index + 'BC'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'-'" />
              </div>
            </div>
          </section>
          <section>
            <h5 class="title">의사 진단 병력 정보(중복 선택 가능)</h5>
            <div class="context">
              <div class="item" v-for="(infoItem, index) in info.slice(14, 20)" :key="index + 'CA'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'해당사항 없음'" />
              </div>
              <div
                class="item"
                v-show="info[19].answerResult == '있음'"
                v-for="(infoItem, index) in info.slice(24, 26)"
                :key="index + 'CB'"
              >
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'해당사항 없음'" />
              </div>
            </div>
          </section>
          <section>
            <div class="context">
              <div class="item" v-for="(infoItem, index) in info.slice(20, 24)" :key="index + 'DA'">
                <h6 class="header" v-text="infoItem.questionText" />
                <p
                  class="content"
                  v-if="infoItem.answerResult != ''"
                  v-text="infoItem.answerResult"
                />
                <p class="content" v-else v-text="'해당사항 없음'" />
              </div>
            </div>
          </section>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
          <button type="button" @click="modify()" class="active">연락처 수정하기</button>
          <button type="button" @click="deleteInfo()" class="active">삭제하기</button>
        </div>
      </div>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @api-called="deleteResvApi"
    />
  </div>
</template>

<script>
import axios from 'axios';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: '회원 관리',
        detail: '회원 정보 관리',
        detail2: '회원 상세정보',
      },
      form: {
        tel: {
          title: '연락처',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '연락처 형식을 확인해주세요',
        },
      },
      info: [],
      member: [],
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getResult(this.$route.params.code);
  },
  methods: {
    ...mapActions('member', ['FETCH_ACCOUNT_DETAIL', 'POST_ACCOUNT_DETAIL', 'DELETE_ACCOUNT', 'DOWNLOAD_ACCOUNT_EXCEL']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async getResult(index) {
      /** index로 api 조회 */
      console.log('index: ', index);

      this.requestParam = `?id=${index}`;

      try {
        await this.FETCH_ACCOUNT_DETAIL(this.requestParam).then((data) => {
          this.member = data.response.list;

          console.log('data.response.list', data.response.list);
          this.form.tel.value = data.response.list[2].answerResult;

          this.info = data.response.answer_list;

          console.log('this.info', this.info);
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    checkPhone() {
      this.form.tel.flag = false;
      this.form.tel.hasError = true;
      const Phone = this.form.tel.value.replace(/\s/gi, '');
      if (Phone === '') {
        this.form.tel.flag = false;
        this.form.tel.hasError = true;
        this.form.tel.errorTxt = '연락처를 입력해주세요.';
        return false;
      }
      const isPhone = /^[0-9|-]{10,13}$/;
      if (!isPhone.test(Phone)) {
        this.form.tel.errorTxt = '연락처 형식이 맞지 않습니다.\r\n다시 입력해주세요.';
        this.form.tel.flag = false;
        this.form.tel.hasError = true;
        return false;
      }
      this.form.tel.flag = true;
      this.form.tel.hasError = false;
      this.form.tel.errorTxt = '';
      return true;
    },
    checkFlag() {
      if (!this.checkPhone()) {
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.content = this.form.tel.errorTxt;
        console.log('tel : ', this.form.tel);
        console.log('tel.errorTxt : ', this.form.tel.errorTxt);
        this.popupSet.popImg = 'success.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
        return false;
      }

      return true;
    },
    modify() {
      console.log('form: ', this.form);
      /** form의 value 를 서버에 전송 */
      if (this.checkFlag()) {
        this.settingApi();
      }
    },
    async settingApi() {
      console.log(`id : ${this.$route.params.code}`);
      console.log(`this.$route.params.code : ${this.$route.params.code}`);

      const data = {
        id: this.$route.params.code,
        tel: this.form.tel.value,
      };
      console.log(`data : ${data}`);

      await this.POST_ACCOUNT_DETAIL(data).then((result) => {
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '연락처가 변경되었습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
      });
    },
    async deleteInfo() {
      this.SET_POPUP(true);
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '회원을 삭제하시겠습니까?';
      this.popupSet.cancelBtnText = '아니오';
      this.popupSet.nextLink = null;
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = '예';
    },
    async deleteResvApi() {
      const data = {
        id: this.$route.params.code,
      };
      console.log(`data-delete : ${data.id}`);

      this.DELETE_ACCOUNT(data.id).then((result) => {
        console.log('result : ', result);
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '회원정보가 삭제되었습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.nextLink = '/';
        this.popupSet.confirmBtnText = null;
        this.popupSet.destination = true;
      });
    },
    async download() {
      const data = {
        id: this.$route.params.code,
      };
      console.log(`download-data : ${data.id}`);
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/account/detail/excel?userId=${data.id}`,
        responseType: 'blob',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          console.log(response, ' : response');
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `${this.member[0].answerResult}.xlsx`);
          document.body.appendChild(link);
          link.click();
        });
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.container {
  .excel-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    .btn-wrap {
      cursor: pointer;
      background: #fd8b11;
      p {
        margin: 0 10px;
         @include initfontKor(
          $font-size: 15px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.info-detail {
  margin: 0 0 105px 0;
  section {
    margin: 65px 0 0 0;
    &:first-of-type {
      margin: 0;
    }
    h5.title {
      @include initfontKor(
        $font-size: 22px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: left
      );
    }
    .context {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      .item {
        padding: 0;
        outline: 1px solid #acb3c9;
        h6.header {
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          background: #f3f3f3;
          border-bottom: 1px solid #acb3c9;
          padding: 8px 12px;
        }
        p.content {
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          padding: 8px 12px;
        }
        input.content {
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          padding: 8px 12px;
        }
      }
    }
  }
}

.info-detail section {
  /** section 1 */
  &:nth-of-type(1) {
    .context {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
      .item {
        input.content {
          width: 250px;
        }
      }
    }
  }

  /** section 2 */
  &:nth-of-type(2) {
    .context {
      grid-template-columns: repeat(6, calc(100% / 6));
      .item {
        .header {
          height: 70px;
        }
      }
    }
  }

  /** section 3 */
  &:nth-of-type(3) {
    .context {
      grid-template-columns: repeat(6, calc(100% / 6));
    }
  }

  /** section 4 */
  &:nth-of-type(4) {
    .context {
      grid-template-columns: 1fr;
      .item {
        display: flex;
        .header {
          padding: 15px 25px;
          width: 600px;
          border-bottom: 0;
        }
        .content {
          padding: 15px 25px;
          width: calc(100% - 500px);
          text-align: left;
        }
      }
    }
  }
}
</style>
