import { reservation } from '@/api';

const state = {
  reservation: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  },
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
};

const getters = {
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  FETCH_CONSULTATION({}, payload) {
    return reservation.fetchConsultation(payload);
  },
  START_CONSULTATION({}, payload) {
    return reservation.startConsultation(payload);
  },
  STORE_CONSULTATION({}, payload) {
    console.log(payload, ' : reservation.js');
    return reservation.storeConsultation(payload);
  },
  STORE_CONSULTATION_REPEAT({}, payload) {
    console.log(payload, ' : reservation.js');
    return reservation.storeConsultationRepeat(payload);
  },
  FETCH_CONSULTATION_LIST({}, payload) {
    return reservation.fetchConsultationList(payload);
  },
  UPDATE_CONSULTATION({}, payload) {
    return reservation.updateConsultation(payload);
  },
  FETCH_CONSULTATION_AVAILABLE_LIST({}, payload) {
    return reservation.fetchConsultationAvailableList(payload);
  },
  FETCH_CONSULTATION_LIMIT({}) {
    return reservation.fetchConsultationLimit();
  },
  UPDATE_CONSULTATION_LIMIT({}, payload) {
    return reservation.updateConsultationLimit(payload);
  },
};
export default {
  strict: process.env.NODE_ENV !== 'production',
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
