import { evaluate } from "@/api";

const state = {
  day: "",
  checkPopup: false,
  checkCalendarPopup: false
};

const getters = {
  GET_DAY: state => state.day
};

const mutations = {
  SET_DAY(state, day) {
    state.day = day;
  }
};

const actions = {
  FETCH_EVALUATION_LIST({}, payload) {
    return evaluate.fetchEvaluationList(payload);
  },
  FETCH_EVALUATION_DETAIL({}, payload) {
    return evaluate.fetchEvaluationDetail(payload);
  },
  FETCH_SURVEY_LIST({}, payload) {
    return evaluate.fetchSurveyList(payload);
  },
  FETCH_SURVEY_DETAIL({}, payload) {
    return evaluate.fetchSurveyDetail(payload);
  },
};

export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
