<template>
  <div class="list-context">
    <div
      class="l-notification"
      :class="{ rightMgr: listInfo.type === 10 }"
      v-if="
        listInfo.type !== 11 &&
          listInfo.type !== 12 &&
          listInfo.type !== 13 &&
          listInfo.type !== 14 &&
          listInfo.type !== 15 &&
          listInfo.type !== 18
      "
    >
      <h3 class="case-num">
        <input type="text" readonly v-model="this.totalDataCnt" />
      </h3>
      <div class="select-complex" v-if="listInfo.type === 10">
        <div class="select-wrap" v-if="!listInfo.isHideAmount">
          <v-select
            :options="countOpt.option"
            :searchable="false"
            @input="listAmount()"
            v-model="countOpt.value"
          ></v-select>
        </div>
        <div class="neo-btn">
          <button type="button" @click="addResv()">원격상담추가</button>
        </div>
      </div>
      <div class="select-complex" v-else>
        <div class="select-wrap" v-if="!listInfo.isHideAmount">
          <v-select
            :options="countOpt.option"
            :searchable="false"
            @input="listAmount()"
            v-model="countOpt.value"
          ></v-select>
        </div>
      </div>
    </div>
    <div
      class="list-wrap"
      :class="{
        patientInfo: listType === 1,
        patientSetting: listType === 2,
        consultInfo: listType === 3,
        managementInfo: listType === 4,
        clinicInfo: listType === 5,
        explainInfo: listType === 6,
        trainingInfo: listType === 7,
        meaningInfo: listType === 8,
        postureDietInfo: listType === 9,
        reservationInfo: listType === 10,
        reservationDetailInfo: listType === 11,
        scheduleDetail: listType === 12,
        consultantRecordPatientInfo: listType === 13,
        consultantRecordConsultantInfo: listType === 14,
        searhInfo: listType === 15,
        noticeInfo: listType === 16,
        scheduleList: listType === 17
      }"
    >
      <div class="consultant-plus" v-if="listInfo.type === 4">
        <router-link to="/managementAdd">관리자 추가</router-link>
      </div>
      <div class="consultant-plus" v-if="listInfo.type === 3">
        <router-link to="/consultantAdd">상담자 추가</router-link>
      </div>
      <div class="header inCheckOpt">
        <div class="item" v-for="(filter, index) in filterOpt" :key="index" :class="{resv : filter.word === '상담 유형',remove_arrow:listInfo.type===2}">
          <p
            v-text="filter.word"
            @click="filterReversal(index)"
            :class="{ isReversal: filter.flag }"
          ></p>
        </div>
      </div>
      <div
        class="content noResult" v-if="!totalCnt && (listType === 1 ||listType === 2|| listType === 3|| listType === 5||listType === 6||listType === 7 ||listType === 9||listType === 10)">
        조회 결과가 없습니다.
      </div>
      <div class="content"  v-else>
        <div class="article-wrap" v-if="listInfo.type === 1">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'A'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <div class="item">
              <p v-text="transDate(listItem.createAt)"></p>
            </div>
            <!-- <div class="item">
              <p v-text="listItem.id"></p>
            </div> -->
            <div class="item">
              <p v-text="listItem.userName"></p>
            </div>
            <div class="item">
              <p v-text="listItem.gender"></p>
            </div>
            <div class="item">
              <p v-text="transDate(listItem.birthDate)"></p>
            </div>
            <div class="item">
              <p v-text="transTel(listItem.tel)"></p>
            </div>
            <div class="item">
              <p v-text="transDateTime(listItem.lastConsultantAt)"></p>
            </div>
            <div class="item">
              <p v-text="transDateTime(listItem.authorAt)"></p>
            </div>
            <div class="item button-parent">
              <button type="button" @click="goDetail(listItem.id, 'patient')">상세보기</button>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 2">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'B'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <!-- <div class="item">
              <p v-text="transDate(listItem.createAt)"></p>
            </div> -->
            <!-- <div class="item">
              <p v-text="listItem.id"></p>
            </div> -->
            <div class="item">
              <p v-text="listItem.userName"></p>
            </div>
            <div class="item">
              <p v-text="listItem.gender"></p>
            </div>
            <div class="item">
              <p v-text="transDate(listItem.birthDate)"></p>
            </div>
            <!-- <div class="item">
              <p v-text="transTel(listItem.tel)"></p>
            </div> -->
            <!-- 훈련시간 설정팝업 -->
            <div class="item time-setting" @click.stop>
              <div class="exercise">
              <p class="exercise_cate"><b>힘껏 오래 삼키기</b></p>
              <div class="setter" @click="setTrainingTime(index,'swallow')">
                <p class="title">아침</p>
                <p class="link" v-text="listItem.training_swallow[0].morning"></p>
              </div>
              <div class="setter" @click="setTrainingTime(index,'swallow')">
                <p class="title">점심</p>
                <p class="link" v-text="listItem.training_swallow[0].afternoon"></p>
              </div>
              <div class="setter" @click="setTrainingTime(index,'swallow')">
                <p class="title">저녁</p>
                <p class="link" v-text="listItem.training_swallow[0].night"></p>
              </div>
              </div>
              <div class="exercise">
              <p class="exercise_cate"><b>힘껏 음도 높이기</b></p>
              <div class="setter" @click="setTrainingTime(index,'pitch')">
                <p class="title">아침</p>
                <p class="link"  v-text="listItem.training_pitch[0].morning"></p>
              </div>
              <div class="setter" @click="setTrainingTime(index,'pitch')">
                <p class="title">점심</p>
                <p class="link" v-text="listItem.training_pitch[0].afternoon"></p>
              </div>
              <div class="setter" @click="setTrainingTime(index,'pitch')">
                <p class="title">저녁</p>
                <p class="link" v-text="listItem.training_pitch[0].night"></p>
              </div>
              </div>
              <div class="exercise">
              <p class="exercise_cate"><b>힘껏 혀 돌리기</b></p>
              <div class="setter" @click="setTrainingTime(index,'tongue')">
                <p class="title">아침</p>
                <p class="link" v-text="listItem.training_tongue[0].morning"></p>
              </div>
              <div class="setter" @click="setTrainingTime(index,'tongue')">
                <p class="title">점심</p>
                <p class="link" v-text="listItem.training_tongue[0].afternoon"></p>
              </div>
              <div class="setter" @click="setTrainingTime(index,'tongue')">
                <p class="title">저녁</p>
                <p class="link" v-text="listItem.training_tongue[0].night"></p>
              </div>
              </div>
            </div>
            <!-- 훈련횟수 설정팝업 -->
            <div class="item count-setting" @click.stop>
              <p class="link" @click="setTrainingAmount(index,'swallow')">
                {{ listItem.training_swallow[1].amount }}번씩 {{ listItem.training_swallow[1].set }}세트
              </p>
              <p class="link" @click="setTrainingAmount(index,'pitch')">
                {{ listItem.training_pitch[1].amount }}번씩 {{ listItem.training_pitch[1].set }}세트
              </p>
              <p class="link" @click="setTrainingAmount(index,'tongue')">
                {{ listItem.training_tongue[1].amount }}번씩 {{ listItem.training_tongue[1].set }}세트
              </p>
            </div>
            <div class="item count-setting" @click="setHertz(index,'pitch')" @click.stop>
              <p class="link" v-text="listItem.hertzTime + '초'"></p>
            </div>
            <div class="item" @click="goDetail(index, 'posture')">
              <p class="link" v-text="listItem.positionCount"></p>
            </div>
            <div class="item" @click="goDetail(index, 'diet')">
              <p class="link" v-text="listItem.feedCount"></p>
            </div>
            <!-- <div class="item">
              <p v-text="listItem.author"></p>
            </div> -->
            <div class="item">
              <p v-text="transDate(listItem.authorAt)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 3">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'C'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <div class="item">
              <p v-text="listItem.id"></p>
            </div>
            <div class="item" @click="goDetail(listItem.id, 'consultant')">
              <p class="link" v-text="listItem.user_name"></p>
            </div>
            <div class="item" @click="goDetail(listItem.id, 'consultant')">
              <p class="link" v-text="listItem.signature == '' ? '미등록' : '등록'"></p>
            </div>
            <div class="item">
              <p v-text="listItem.author"></p>
            </div>
            <div class="item">
              <p v-text="transDateTime(listItem.update_at)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 4">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'D'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <div class="item" @click="goDetail(listItem.id, 'management')">
              <p class="link" v-text="listItem.id"></p>
            </div>
            <div class="item">
              <p v-text="listItem.name"></p>
            </div>
            <div class="item">
              <p v-text="listItem.mail"></p>
            </div>
            <div class="item">
              <p v-text="transTel(listItem.tel)"></p>
            </div>
            <div class="item">
              <p v-text="listItem.author"></p>
            </div>
            <div class="item">
              <p v-text="transDateTime(listItem.update_at)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 5">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'D'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <!-- <div class="item">
              <p v-text="listItem.userId"></p>
            </div> -->
            <div class="item" @click="goDetail(listItem.userId, 'clinic', listItem.createAt)">
              <p class="link" v-text="listItem.userName"></p>
            </div>
            <div class="item">
              <p
                class="status"
                :class="{ inActive: !listItem.structure }"
                v-text="listItem.structure ? '실행완료' : '미실행'"
              ></p>
            </div>
            <div class="item">
              <p
                class="status"
                :class="{ inActive: !listItem.water }"
                v-text="listItem.water ? '실행완료' : '미실행'"
              ></p>
            </div>
            <div class="item">
              <p
                class="status"
                :class="{ inActive: !listItem.solid }"
                v-text="listItem.solid ? '실행완료' : '미실행'"
              ></p>
            </div>
            <div class="item">
              <p
                class="status"
                :class="{ inActive: !listItem.vocalization }"
                v-text="listItem.vocalization ? '실행완료' : '미실행'"
              ></p>
            </div>
            <div class="item">
              <p v-text="transDate(listItem.createAt)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 6">
          <div class="sub-wrap" v-if="result.length > 0">
            <div class="article" v-for="(listItem, index) in result" :key="index + 'E'">
              <div class="item">
                <p v-text="startindex + index + 1"></p>
              </div>
              <!-- <div class="item">
              <p v-text="listItem.userId"></p>
            </div> -->
              <div class="item">
                <p v-text="listItem.userName"></p>
              </div>
              <div class="item" @click="goDetail(index, 'explain', listItem.createAt)">
                <p
                  class="status"
                  :class="{ inActive: !listItem.isCompleted }"
                  v-text="listItem.isCompleted ? '실행완료' : '미실행'"
                ></p>
              </div>
              <div class="item">
                <p v-text="transDate(listItem.createAt)"></p>
              </div>
            </div>
          </div>
          <div class="empty-zone" v-else>
            <p>조회결과가 없습니다.</p>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 7">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'E'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <!-- <div class="item">
              <p v-text="listItem.userId"></p>
            </div> -->
            <div class="item">
              <p class="link" v-text="listItem.userName" @click="goDetail(index, 'training')"></p>
            </div>
            <div class="item time">
              <p>아침</p>
              <p>점심</p>
              <p>저녁</p>
            </div>
            <div class="item cluster">
              <div
                class="p-wrap"
                v-for="(trainingItem, childIndex) in Object.entries(listItem.longSwallow)"
                :key="childIndex + 'F'"
                @click="goDetail(index, 'training')"
              >
                <p
                  class="status"
                  :class="{ inActive: trainingItem[1] === 0 }"
                  v-text="transStatus(trainingItem[1])"
                />
              </div>
            </div>
            <div class="item cluster">
              <div
                class="p-wrap"
                v-for="(trainingItem, childIndex) in Object.entries(listItem.increasePitch)"
                :key="childIndex + 'G'"
                @click="goDetail(index, 'training')"
              >
                <p
                  class="status"
                  :class="{ inActive: trainingItem[1] === 0 }"
                  v-text="transStatus(trainingItem[1])"
                />
              </div>
            </div>
            <div class="item cluster">
              <div
                class="p-wrap"
                v-for="(trainingItem, childIndex) in Object.entries(listItem.tongueTurn)"
                :key="childIndex + 'H'"
                @click="goDetail(index, 'training')"
              >
                <p
                  class="status"
                  :class="{ inActive: trainingItem[1] === 0 }"
                  v-text="transStatus(trainingItem[1])"
                />
              </div>
            </div>
            <div class="item">
              <p v-text="listItem.updateAt"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 8">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'F'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <!-- <div class="item">
              <p v-text="listItem.memberId"></p>
            </div> -->
            <div class="item">
              <p v-text="listItem.userName"></p>
            </div>
            <div class="item" @click="goDetail(index, 'meaning', listItem)">
              <p class="link" v-text="listItem.day + '일차'"></p>
            </div>
            <div class="item">
              <p v-text="transDate(listItem.updateAt)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 9">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'G'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <!-- <div class="item">
              <p v-text="listItem.id"></p>
            </div> -->
            <div class="item">
              <p v-text="listItem.name"></p>
            </div>
            <div class="item" @click="goDetail(index, 'postureDiet', listItem.activityDate)">
              <p class="status">결과보기</p>
            </div>

            <div class="item">
              <p v-text="transDate(listItem.activityDate)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 10">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'H'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <div class="item">
              <p v-text="listItem.consultationDate"></p>
            </div>
            <div class="item">
              <p
                :class="{ link: listItem.ready }"
                v-text="listItem.consultationTime"
                @click="msTeams(index)"
              ></p>
            </div>
            <div class="item" @click="goDetail(index, 'reservation', listItem.consultationDate)">
              <p class="link" v-text="listItem.userName"></p>
            </div>
            <div class="item">
              <p v-text="transDate(listItem.birthDate)"></p>
            </div>
            <div class="item" v-if="listItem.state !== 5">
              <p
                class="status"
                :class="{
                  inActive: listItem.state === 1 || listItem.state === 2
                }"
                v-text="resvStatus(listItem.state)"
              ></p>
            </div>
            <div class="item" v-else>
              <p
                class="status"
                :class="{
                  counseling: listItem.state === 5
                }"
                v-text="resvStatus(listItem.state)"
                @click="goDetail(index, 'consultantRecord')"
              ></p>
            </div>
            <div class="item" @click="goDetail(index, 'reservation', listItem.consultationDate)">
              <p
                class="link"
                v-text="setWorkder(listItem.consultantStatus, listItem.consultant)"
              ></p>
            </div>
            <div class="item">
              <p v-text="setWorkder(listItem.authorStatus, listItem.author)"></p>
            </div>
            <div class="item">
              <p v-text="listItem.authorAt"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 11">
          <div class="article">
            <div class="item">
              <p v-if="result.type === 0">음성</p>
              <p v-else>영상</p>
            </div>
            <div class="item">
              <p v-text="result.consultationDate"></p>
            </div>
            <div class="item">
              <p v-text="result.consultationTime.substring(0, 5)"></p>
            </div>
            <div class="item">
              <p class="status" @click="wishPop(result)">자세히보기</p>
            </div>
            <div class="item">
              <p v-text="resvStatus(result.state)"></p>
            </div>
            <div class="item flex-btn">
              <!-- 상담완료 상태가 아니면 변경버튼이 나타남 -->
              <p
                class="status"
                v-if="result.state !== 1 && result.state !== 2 && result.state !== 5"
                @click="calendarPop(result.consultationDate)"
              >
                변경
              </p>
              <!-- 취소 또는 완료상태가 아니면 취소버튼이 나타남 -->
              <p
                class="status"
                v-if="result.state !== 1 && result.state !== 2 && result.state !== 5"
                @click="cancelPop(result.consultationDate)"
              >
                취소
              </p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 12">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'I'">
            <div class="item">
              <p v-text="listItem.consultationDate"></p>
            </div>
            <div class="item">
              <p v-text="listItem.consultationTime"></p>
            </div>
            <div class="item">
              <p v-text="listItem.userName"></p>
            </div>
            <div class="item">
              <p v-text="resvStatus(listItem.state)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 13">
          <div class="article">
            <div class="item">
              <p v-text="result.userName"></p>
            </div>
            <div class="item">
              <p v-text="result.gender === '남성' ? '남' : '여'"></p>
            </div>
            <div class="item">
              <p v-text="result.birthDate"></p>
            </div>
            <div class="item">
              <p v-text="result.firstConsultantDate"></p>
            </div>
            <div class="item">
              <div class="input-wrap">
                <input type="text" v-model="result.note" />
              </div>
            </div>
            <div class="item">
              <div class="input-wrap">
                <input type="text" v-model="result.woundName" />
              </div>
            </div>
            <div class="item">
              <div class="input-wrap">
                <input type="text" v-model="result.disorderName" />
              </div>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 14">
          <div
            class="article"
            v-for="(listItem, index) in result"
            :key="index + 'J'"
            @click="loadDetailInfo(index, listItem.consultationDatetime)"
          >
            <div class="item">
              <p v-html="listItem.consultationDatetime"></p>
            </div>
            <div class="item">
              <p v-text="setConsultantTime(listItem.type)"></p>
            </div>
            <div class="item">
              <p v-text="listItem.title"></p>
            </div>
            <div class="item">
              <p v-text="listItem.consultant"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 15">
          <div
            class="article"
            v-for="(listItem, index) in result"
            :key="index + 'K'"
            @click="selectMember(index, listItem)"
          >
            <div class="item">
              <p v-text="listItem.id"></p>
            </div>
            <div class="item">
              <p v-text="listItem.userName"></p>
            </div>
            <div class="item">
              <p v-text="listItem.gender"></p>
            </div>
            <div class="item">
              <p v-text="transTel(listItem.tel)"></p>
            </div>
            <div class="item">
              <p v-text="transDate(listItem.birthDate)"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 16">
          <div class="sub-wrap" v-if="result.length > 0">
            <div
              class="article"
              v-for="(listItem, index) in result"
              :key="index + 'L'"
              @click="goDetail(index, 'notice')"
            >
              <div class="item">
                <p v-text="startindex + index + 1"></p>
              </div>
              <div class="item">
                <p v-text="listItem.top"></p>
              </div>
              <div class="item">
                <p v-text="listItem.title"></p>
              </div>
              <div class="item">
                <p v-text="listItem.attachment"></p>
              </div>
              <div class="item">
                <p v-text="listItem.writer"></p>
              </div>
              <div class="item">
                <p v-text="transDateTime(listItem.writedDate)"></p>
              </div>
            </div>
          </div>
          <div class="empty-zone" v-else>
            <p>조회결과가 없습니다.</p>
          </div>
        </div>
        <div class="article-wrap" v-if="listInfo.type === 17">
          <div class="article" v-for="(listItem, index) in result" :key="index + 'C'">
            <div class="item">
              <p v-text="startindex + index + 1"></p>
            </div>
            <div class="item" @click="goDetail(index, 'schedule')">
              <p class="link" v-text="listItem.consultant"></p>
            </div>
            <div class="item">
              <p v-text="listItem.consultationDate"></p>
            </div>
            <div class="item">
              <p v-text="listItem.consultationTime"></p>
            </div>
            <div class="item">
              <p v-text="listItem.userName"></p>
            </div>
          </div>
        </div>
        <div class="article-wrap resv" v-if="listInfo.type === 18">
          <div class="article" v-for="(item, index) in listArray" :key="index">
            <div class="item">
              <p v-if="item.data.type === 0 ">음성</p>
              <p v-else-if="item.data.type === 1">영상</p>
            </div>
            <div class="item">
              <p class="status" @click="typecall(index)">등록</p>
            </div>
            <div class="item">
              <p v-text="item.data.consultationDate"></p>
            </div>
            <div class="item">
              <p v-text="item.data.consultationTime.substring(0, 5)"></p>
            </div>
            <div class="item">
              <p
                class="status"
                @click="writePop(item.data,index)"
                v-text="item.data.state !== null ? '작성하기' : ''"
              >
                작성하기
              </p>
            </div>
            <div class="item flex-btn">
              <!-- 상담완료 상태가 아니면 변경버튼이 나타남 -->
              <button
                type="button"
                class="request-resv"
                @click="calendarPop(item.data.consultationDate,index)"
                v-if="item.data.consultationDate === ''"
              >
                등록
              </button>
              <button
                type="button"
                class="request-resv"
                @click="calendarPop(item.data.consultationDate, index)"
                v-else
              >
                변경
              </button>
              <!-- 취소 또는 완료상태가 아니면 취소버튼이 나타남 -->
              <!--
              <p
                class="status"
                v-if="result.state !== 1 && result.state !== 2 && result.state !== 5"
                @click="cancelPop(result.consultationDate)"
              >
                취소
              </p>
              -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { mapMutations } from 'vuex';

export default {
  props: {
    columnInfo: {
      type: Array,
    },
    listInfo: {
      type: Object,
    },
    listArray: {
      type: Array,
    },
  },
  data() {
    return {
      countOpt: {
        option: [10, 30, 50, 100],
        value: '' || 10,
      },
      filterOpt: [],
      result: [],
      allChecked: false,
      listType: 1,
      totalDataCnt: '',
      totalCnt: 0,
      startindex: 0,
      view: {},
      order: 'desc',
      typeData: null,
    };
  },
  created() {
    this.makeFilter();
    this.initList(this.listInfo.data, this.listInfo.totalDataCnt);
    this.typeData = this.listInfo.data.type;
  },
  methods: {
    ...mapMutations('evaluate', ['SET_DAY']),
    addResv() {
      this.$router.push('/reservationAdd');
    },
    selectMember(index, item) {
      this.$emit('member-selected', index, item);
    },
    loadDetailInfo(index, time) {
      this.$emit('load-list-detail', index, time);
    },
    msTeams(index) {
      this.$emit('call-consultant-record', this.result[index]);
    },
    calendarPop(info, index) {
      console.log(info, ': info / ', index, ': index');
      this.$emit('call-calender-pop', info, index);
    },
    cancelPop(info) {
      this.$emit('call-cancle-pop', info);
    },
    typecall(index) {
      this.$emit('typecall', index);
    },
    wishPop(info) {
      this.$emit('call-wish-pop', info);
    },
    writePop(info, index) {
      this.$emit('call-write-pop', info, index);
    },
    goDetail(index, location, day) {
      if (location === 'management') {
        this.$router.push(`/managementDetail/${index}`);
      } else if (location === 'consultant') {
        this.$router.push(`/consultantDetail/${index}`);
      } else if (location === 'posture') {
        this.$router.push(`/settingPosture/${this.result[index].id}`);
      } else if (location === 'diet') {
        this.$router.push(`/settingDiet/${this.result[index].id}`);
      } else if (location === 'patient') {
        this.$router.push(`/patientInfoDetail/${index}`);
      } else if (location === 'clinic') {
        this.$router.push(`/clinicDetail/${String(index)}`);
        this.SET_DAY(day);
      } else if (location === 'explain') {
        console.log('체크여부', this.result[index].isCompleted);
        this.$router.push(`/explainDetail/${this.result[index].userId}`);
      } else if (location === 'training') {
        if (
          this.result[index].updateAt !== undefined
          && this.result[index].updateAt !== null
          && this.result[index].updateAt != ''
        ) {
          console.log(
            `userId : ${this.result[index].userId} | date : ${this.transDate(
              this.result[index].updateAt,
            )}`,
          );
          this.$router.push({
            name: 'TrainingDetail',
            query: {
              userId: this.result[index].userId,
              date: this.transDate(this.result[index].updateAt),
            },
          });
        }
      } else if (location === 'meaning') {
        this.$router.push({
          name: 'MeaningDetail',
          query: { MeaningDetail: JSON.stringify(day) },
        });
      } else if (location === 'postureDiet') {
        this.$router.push(`/postureDietDetail/${this.result[index].id}`);
      } else if (location === 'reservation') {
        this.$router.push(`/reservationDetail/${this.result[index].id}`);
      } else if (location === 'schedule') {
        this.$router.push(`/scheduleDetail/${this.result[index].id}`);
      } else if (location === 'notice') {
        this.$router.push(`/noticeDetail/${this.result[index].id}`);
      } else if (location === 'consultantRecord') {
        this.$router.push(`/ConsultantRecord?id=${this.result[index].id}`);
      }
    },
    extract(type) {
      this.$emit('list-find-to-checked', this.result, type);
    },
    allCheck() {
      const flag = this.allChecked;
      const list = this.result;
      for (let i = 0; i < list.length; i++) {
        list[i].checkFlag = !flag;
      }
      this.result = list;
    },
    /** listInfo Type 1의 함수 시작 */
    setTrainingTime(order, cate) {
      this.$emit('set-training-time', order, cate);
    },
    setTrainingAmount(order, cate) {
      this.$emit('set-training-amount', order, cate);
    },
    setHertz(order, cate) {
      this.$emit('set-training-hertz', order, cate);
    },
    /** listInfo Type 2의 함수 끝 */

    listAmount() {
      this.$emit('list-amount-change', this.countOpt.value);
    },
    initList(list, totalDataCnt, startIndex) {
      console.log('리스트 테스트', list);
      console.log('startIndex :', startIndex);
      this.startindex = startIndex === undefined ? 0 : startIndex;
      /** 더미데이터 리스트 */
      if (this.listInfo.type === 2) {
        const sampleArray = list;
        for (let i = 0; i < sampleArray.length; i++) {
          /** sampleArray[i].flag = false; */
          sampleArray[i].checkFlag = false;
          sampleArray[i].keyName = `keyName${i}`;
        }
        this.result = sampleArray;
      } else {
        this.result = list;
      }
      this.listType = this.listInfo.type;
      this.totalCnt = totalDataCnt;
      this.totalDataCnt = totalDataCnt === undefined ? '총 0명' : `총 ${totalDataCnt}명`;
    },
    makeFilter() {
      /** 필터옵션 생성해주기 */
      const arraySet = this.columnInfo;
      for (let i = 0; i < arraySet.length; i++) {
        arraySet[i].flag = false;
      }
      if (
        this.listInfo.type !== 11
        && this.listInfo.type !== 12
        && this.listInfo.type !== 13
        && this.listInfo.type !== 14
        && this.listInfo.type !== 15
        && this.listInfo.type !== 18
      ) {
        const numArea = {
          word: '번호',
        };
        arraySet.unshift(numArea);
      }
      this.filterOpt = arraySet;
    },
    filterReversal(index) {
      /**
      if (
        document
          .querySelector(".header")
          .childNodes[index + 1].childNodes[0].classList.contains("isReversal")
      ) {
        document
          .querySelector(".header")
          .childNodes[index + 1].childNodes[0].classList.remove("isReversal");
      } else {
        document
          .querySelector(".header")
          .childNodes[index + 1].childNodes[0].classList.add("isReversal");
      }
       */
      /** 선택된 필터항목과 순서(order) 넘기기 */
      if (index !== 0) {
        this.filterOpt[index].flag = !this.filterOpt[index].flag;
        const option = this.filterOpt[index];
        let stringVal = '';
        stringVal += `&orderField=${option.filter}`;
        stringVal += `&order=${option.flag === true ? 'asc' : 'desc'}`;
        this.$emit('list-reversal', stringVal);
      }
    },
    /** 공통함수  끝 */
  },
  mounted() {},
  computed: {
    transTel() {
      return (tel) => {
        let format = '';
        if (tel.length == 11) {
          format = tel.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
        } else if (tel.length == 8) {
          format = tel.replace(/(\d{4})(\d{4})/, '$1-$2');
        } else if (tel.indexOf('02') == 0) {
          format = tel.replace(/(\d{2})(\d{4})(\d{4})/, '$1-$2-$3');
        } else {
          format = tel.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
        }
        return format;
      };
    },
    transDate() {
      return (date) => this.$moment(date).format('YYYY-MM-DD');
    },
    transDateTime() {
      return (date) => {
        if (date === null) return '';

        return this.$moment(date).format('YYYY-MM-DD HH:mm:ss');
      };
    },
    transStatus() {
      return (status) => {
        console.log('status :', status);
        let mutatedValue;
        if (status === 0) {
          mutatedValue = '-';
        } else if (status === 1) {
          mutatedValue = '진행중';
        } else if (status === 2) {
          mutatedValue = '훈련완료';
        }
        return mutatedValue;
      };
    },
    resvStatus() {
      return (status) => {
        console.log('status :', status);
        let mutatedValue;
        if (status === 0) {
          mutatedValue = '예약완료';
        } else if (status === 1) {
          mutatedValue = '회원 취소';
        } else if (status === 2) {
          mutatedValue = '관리자 취소';
        } else if (status === 3) {
          mutatedValue = '미진행';
        } else if (status === 4) {
          mutatedValue = '관리자 예약 변경';
        } else if (status === 5) {
          mutatedValue = '상담완료';
        }
        return mutatedValue;
      };
    },
    setWorkder() {
      return (status, worker) => {
        console.log('status :', status);
        let mutatedValue;
        if (status === 0) {
          mutatedValue = '배정 전';
        } else if (status === 1) {
          mutatedValue = worker;
        } else if (status === 2) {
          mutatedValue = '-';
        } else if (status === 3) {
          mutatedValue = '-';
        }
        return mutatedValue;
      };
    },
    setConsultantTime() {
      return (status) => {
        console.log('status :', status);
        let mutatedValue;
        if (status === 0) {
          mutatedValue = '음성';
        } else if (status === 1) {
          mutatedValue = '영상';
        }
        return mutatedValue;
      };
    },
    setTransType() {
      return (type) => {
        console.log('type2 : ', type);
        let mutatedValue;
        if (type === 0) {
          mutatedValue = '음성';
        } else if (type === 1) {
          mutatedValue = '영상';
        }
        return mutatedValue;
      };
    },
  },
  components: {
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
.list-context {
  margin: 0 0 60px 0;
  .l-notification {
    display: flex;
    justify-content: space-between;
    margin: 0 0 30px 0;
    &.rightMgr {
      .select-wrap {
        margin: 0 150px 0 0;
      }
    }
    h3.case-num {
      @include initfontKor(
        $font-size: 22px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
    .select-complex {
      position: relative;
      .select-wrap {
        width: 140px;
      }
      .neo-btn {
        position: absolute;
        top: 0;
        right: 0;
        width: 125px;
        height: 40px;
        padding: 0;
        background: #292a2b;
        cursor: pointer;
        button {
          width: 100%;
          border: 0;
          padding: 0;
          background: transparent;
          cursor: pointer;
          @include initfontKor(
            $font-size: 19px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #fff,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
    }
  }

  .list-wrap {
    border: 1px solid #acb3c9;
    position: relative;
    .consultant-plus {
      position: absolute;
      top: -78px;
      right: 0;
      a {
        display: block;
        border: 0;
        padding: 0;
        width: 105px;
        background: #292a2b;
        cursor: pointer;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 48px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
    .header {
      display: flex;
      background: #f3f3f3;
      margin: 0 0 1px 0;
      &.inCheckOpt {
        .item {
          &.resv {
            min-width:332.5px;
          }
          &:nth-of-type(1) p:after {
            display: none;
          }
        }
      }
      .item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0;
        outline: 1px solid #acb3c9;
        width: 100%;
        height: 100%;
        &:first-of-type {
          border-left: 0;
        }
        &:first-of-type p:after {
          display: none;
        }
        p {
          width: fit-content;
          margin: 0 auto;
          position: relative;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 48px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          &.isReversal {
            &:after {
              transform: rotate(180deg);
              top: calc(50% - 7.5px);
            }
          }
          &:after {
            position: absolute;
            content: "";
            background-image: url("~@/assets/images/util/dropdown.svg");
            width: 20px;
            height: 11px;
            right: -27.5px;
            top: 50%;
            transform: translateY(-50%);
            cursor: pointer;
          }

          }
            &.remove_arrow p{
              &:after{
              right: -20.5px;
            }
        }
      }
    }
    .content {
      .article-wrap {
        &.resv {
          .article {
            .item {
              min-height: 48px;
              &.flex-btn {
                button.request-resv {
                  width: 105px;
                  padding: 0;
                  background: #292a2b;
                  margin: 0px auto;
                  cursor: pointer;
                  border: 0;
                  @include initfontKor(
                    $font-size: 16px,
                    $line-height: 32px,
                    $font-family: "AppleSDGothicNeoM00",
                    $color: #fff,
                    $letter-spacing: -0.6px,
                    $text-align: center
                  );
                }
              }
              p.status {
                background: #292a2b;
                color: #fff;
              }
            }
          }
        }
        .empty-zone {
          min-height: 130px;
          padding: 25px;
          display: flex;
          align-items: center;
          justify-content: center;
          p {
            @include initfontKor(
              $font-size: 21px,
              $line-height: 27px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.48px,
              $text-align: center
            );
          }
        }
        .empty-list {
          p {
          }
        }
        .article {
          display: flex;
          align-items: center;
          margin: 1px 0 0 0;
          &:first-of-type {
            margin: 0;
          }
          .item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 8px 0;
            outline: 1px solid #acb3c9;
            width: 100%;
            height: 100%;
            &.button-parent {
              display: flex;
              justify-content: center;
              button {
                border: 0;
                padding: 0;
                width: 120px;
                background: rgba(41, 42, 43, 0.8);
                cursor: pointer;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #fff,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            &.time-setting {
              display: flex;
              flex-direction: row;
              flex-wrap: wrap;
              .setter {
                width: 100%;
                display: flex;
                justify-content: center;
                p {
                  &.title {
                    width: 40px;
                    position: relative;
                    color: blue;
                    text-decoration: underline;
                    padding: 0 10px 0 0;
                    &:after {
                      content: "";
                      position: absolute;
                      right: 4.5px;
                      top: 50%;
                      width: 1px;
                      height: 16px;
                      transform: translateY(-50%);
                      background: #292a2b;
                    }
                  }
                }
              }
              .exercise{
                width:100%;
                display:flex;
                flex-direction: row;
                justify-content: space-evenly;
                .exercise_cate{
                  flex-basis: 30%;
                }
                .setter{flex-basis: 25%;}
              }
            }
            &.count-setting{flex-direction: column;}
            .input-wrap {
              width: inherit;
              display: flex;
              justify-content: center;
              input {
                width: calc(100% - 20px);
                padding: 0 12px;
                border: 1px solid #acb3c9;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 26px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: #292a2b,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
            p {
              @include initfontKor(
                $font-size: 16px,
                $line-height: 32px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              &.status {
                width: 105px;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
                &.inActive {
                  background: transparent;
                  color: #292a2b;
                }
                &.counseling {
                  background: #292a2b;
                  color: #fff;
                  cursor: pointer;
                }
              }
              &.link {
                text-decoration: underline;
                cursor: pointer;
                @include initfontKor(
                  $font-size: 16px,
                  $line-height: 32px,
                  $font-family: "AppleSDGothicNeoM00",
                  $color: blue,
                  $letter-spacing: -0.6px,
                  $text-align: center
                );
              }
            }
          }
        }
      }
      &.noResult {
        width: 100%;
        height: 200px;
        line-height: 200px;
        text-align: center;
        border-top: none;
        font-size: 22px;
      }
    }

    /** lisyType === 1 */
    &.patientInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 40px 1fr 130px 130px 1fr 1fr 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(1),
            &:nth-of-type(9) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 40px 1fr 130px 130px 1fr 1fr 1fr 1fr 1fr;
          place-items: center;
        }
      }
    }
    /** lisyType === 2 */
    &.patientSetting {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns:0.5fr 0.7fr 0.5fr 0.7fr 3fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            // &:nth-of-type(2),
            &:nth-of-type(6),
            &:nth-of-type(7)
            {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
        grid-template-columns:0.5fr 0.7fr 0.5fr 0.7fr 3fr 1fr 0.7fr 0.5fr 0.5fr 1fr;
            // grid-template-columns: 40px 90px 100px 100px 350px 1fr 1fr 90px 100px 100px;
          place-items: center;
          .item:nth-of-type(7),
          .item:nth-of-type(8),
          .item:nth-of-type(9),
          .item:nth-of-type(10) {
            cursor: pointer;
          }
        }
      }
    }
    /** lisyType === 3 */
    &.consultInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr;
        place-items: center;
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr;
          place-items: center;
          .item:nth-of-type(3),
          .item:nth-of-type(4) {
            cursor: pointer;
          }
        }
      }
    }
    /** lisyType === 4 */
    &.managementInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr;
        place-items: center;
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 40px 1fr 1fr 1fr 1fr 1fr 1fr;
          place-items: center;
          .item:nth-of-type(2) {
            cursor: pointer;
          }
        }
      }
    }
    /** lisyType === 5 */
    &.clinicInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 85px 1fr 1fr 1fr 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 85px 1fr 1fr 1fr 1fr 1fr 1fr;
          place-items: center;
          .item:nth-of-type(2) {
            cursor: pointer;
          }
        }
      }
    }
    /** lisyType === 6 */
    &.explainInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 85px 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(3) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 85px 1fr 1fr 1fr;
          place-items: center;
          .item:nth-of-type(3) {
            cursor: pointer;
          }
        }
      }
    }
    /** lisyType === 7 */
    &.trainingInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 85px 160px 1fr 1fr 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 85px 160px 1fr 1fr 1fr 1fr 1fr;
          place-items: center;
          .item {
            &.time {
              flex-wrap: wrap;
              padding: 0;
              align-items: flex-start;
              p {
                width: 100%;
                border-top: 1px solid #acb3c9;
                line-height: 36px;
                &:first-of-type {
                  border-top: 0;
                }
              }
            }
            &.cluster {
              flex-wrap: wrap;
              padding: 0;
              align-items: flex-start;
              .p-wrap {
                width: 100%;
                border-top: 1px solid #acb3c9;
                &:first-of-type {
                  border-top: 0;
                }
                p {
                  line-height: 26px;
                  width: 120px;
                  margin: 5px auto;
                }
              }
            }
            &:nth-of-type(2),
            &:nth-of-type(4),
            &:nth-of-type(5),
            &:nth-of-type(6) {
              cursor: pointer;
            }
          }
        }
      }
    }
    /** lisyType === 8 */
    &.meaningInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 85px 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(3) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 85px 1fr 1fr 1fr;
          place-items: center;
          .item:nth-of-type(3) {
            cursor: pointer;
          }
        }
      }
    }
    /** lisyType === 9 */
    &.postureDietInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 85px 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(3) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 85px 1fr 1fr 1fr;
          place-items: center;
          .item:nth-of-type(3) {
            cursor: pointer;
          }
        }
      }
    }
    /** lisyType === 10 */
    &.reservationInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 60px 1fr 1fr 105px 1fr 1fr 105px 105px 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(3),
            &:nth-of-type(6) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 60px 1fr 1fr 105px 1fr 1fr 105px 105px 1fr;
          place-items: center;
          .item {
            &:nth-of-type(4),
            &:nth-of-type(7) {
              cursor: pointer;
            }
          }
        }
      }
    }
    /** lisyType === 11 */
    &.reservationDetailInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 160px 160px 160px 160px 160px 230px 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(1),
            &:nth-of-type(2),
            &:nth-of-type(3),
            &:nth-of-type(4),
            &:nth-of-type(5) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 160px 160px 160px 160px 160px 230px 1fr;
          place-items: center;
          .item {
            &.flex-btn {
              p.status {
                margin: 0 0 0 10px;
                &:first-of-type {
                  margin: 0;
                }
                &.init {
                  background: transparent;
                  color: #292a2b;
                }
              }
            }
            &:nth-of-type(3),
            &:nth-of-type(5) {
              cursor: pointer;
            }
            p.status {
              background: #292a2b;
                color: #fff;
            }
          }
        }
      }
    }
    /** lisyType === 12 */
    &.scheduleDetail {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            &:nth-of-type(4),
            &:nth-of-type(5) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          place-items: center;
        }
      }
    }
    /** lisyType === 13 */
    &.consultantRecordPatientInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 120px 90px 145px 145px 1fr 1fr 1fr;
        place-items: center;
        &.inCheckOpt {
          .item {
            p:after {
              display: none;
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 120px 90px 145px 145px 1fr 1fr 1fr;
          place-items: center;
        }
      }
    }
    /** lisyType === 14 */
    &.consultantRecordConsultantInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 1fr 120px 1fr 120px;
        place-items: center;
        &.inCheckOpt {
          .item {
            p:after {
              display: none;
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 1fr 120px 1fr 120px;
          place-items: center;
          cursor: pointer;
        }
      }
    }
    /** lisyType === 15 */
    &.searhInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 120px 120px 120px 1fr 1fr;
        &.inCheckOpt {
          .item {
            p:after {
              display: none;
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 120px 120px 120px 1fr 1fr;
          cursor: pointer;
        }
      }
    }
    /** lisyType === 16 */
    &.noticeInfo {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 60px 120px 1fr 1fr 120px 1fr;
        &.inCheckOpt {
          .item {
            &:nth-of-type(4) {
              p:after {
                display: none;
              }
            }
          }
        }
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 60px 120px 1fr 1fr 120px 1fr;
          cursor: pointer;
        }
      }
    }
    /** lisyType === 17 */
    &.scheduleList {
      .header {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: 40px 1fr 1fr 1fr 1fr;
        place-items: center;
      }
      .content .article-wrap {
        .article {
          display: grid;
          grid-auto-flow: dense;
          grid-gap: 1px;
          grid-template-columns: 40px 1fr 1fr 1fr 1fr;
          place-items: center;
          .item:nth-of-type(2) {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
