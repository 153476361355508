<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="noti-zone">
          <h5 class="title">공지사항 관리</h5>
          <div class="add-notice">
            <button type="button" @click="add()">추가</button>
          </div>
        </div>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          @list-reversal="reversalResult"
          ref="list"
          v-if="isListVisible"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          ref="pagination"
          v-if="isPaginationVisible"
        ></pagination>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        startPage: null,
        lastPage: null,
        currentPage: 1,
        length: null,
      },
      tree: {
        title: '설정',
        detail: '공지사항 관리',
      },
      columnInfo: [
        {
          word: 'TOP',
          filter: 'top',
        },
        {
          word: '제목',
          filter: 'title',
        },
        {
          word: '첨부파일',
          filter: 'attachment',
        },
        {
          word: '작성자',
          filter: 'writer',
        },
        {
          word: '작성일',
          filter: 'writedDate',
        },
      ],
      listInfo: {
        type: 16,
        data: [],
        isHideAmount: true,
      },
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      requestParamOrder: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getResult();
  },
  methods: {
    ...mapActions('util', ['FETCH_NOTICE_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    add() {
      this.$router.push('/noticeDetail');
    },
    async fetchData(isInit, page, type) {
      try {
        const param = `?page=${page === null ? 1 : page}&maxPage=10&default=1`;

        await this.FETCH_NOTICE_LIST(param + this.requestParamOrder).then((data) => {
          if (isInit) {
            this.listInfo.data = data.response.list;
            this.listInfo.totalDataCnt = data.response.totalCount;
            this.isListVisible = true;
            this.getPaginationInfo(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              1,
            );
          } else {
            this.$refs.list.initList(data.response.list, data.response.totalCount);
            this.$refs.pagination.injectPagination(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              type != undefined ? 1 : page,
            );
          }
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    reversalResult(info) {
      this.requestParamOrder = info;
      this.fetchData(false, this.$refs.pagination.paginationStatus.currentPage, undefined);
    },
    getResult() {
      this.fetchData(true, null, undefined);
    },
    getPaginationInfo(totalDataCnt, startPage, lastPage, currentPage) {
      /**
       * page, maxPage, default 인자로 페이지네이션 API 실행함
       * */
      this.paginationInfo.totalDataCnt = totalDataCnt;
      this.paginationInfo.startPage = startPage;
      this.paginationInfo.lastPage = lastPage;
      if (currentPage !== null) {
        this.paginationInfo.currentPage = currentPage;
      } else {
        this.paginationInfo.currentPage = 1;
      }
      this.isPaginationVisible = true;
    },
    paginationClick(pageOrder) {
      this.fetchData(false, pageOrder, undefined);
    },
  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    Pagination,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.container {
  .noti-zone {
    position: relative;
  }
  .add-notice {
    position: absolute;
    top: 55px;
    right: 0;
    button {
      border: 0;
      padding: 0;
      width: 105px;
      background: #292a2b;
      cursor: pointer;
      @include initfontKor(
        $font-size: 18px,
        $line-height: 48px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #fff,
        $letter-spacing: -0.6px,
        $text-align: center
      );
    }
  }
}
</style>
