<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="info-detail">
          <div class="article">
            <h5 class="title" v-text="form.id.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="text" v-model="form.id.value" @input="checkId()" :disabled="view.isModify" />
                 <p class="warn-message" v-if="form.id.hasError" v-text="form.id.errorTxt"/>
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.name.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="text" v-model="form.name.value" @input="checkName()" :disabled="view.isModify"/>
                <p class="warn-message" v-if="form.name.hasError" v-text="form.name.errorTxt"/>
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.password.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="password" v-model="form.password.value" @input="checkPw()" />
                <p class="warn-message" v-if="form.password.hasError" v-text="form.password.errorTxt"/>
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.passwordConfirm.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="password" v-model="form.passwordConfirm.value" @input="checkPwConfirm()" />
                <p class="warn-message" v-if="form.passwordConfirm.hasError" v-text="form.passwordConfirm.errorTxt"/>
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.mail.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="text" v-model="form.mail.value"  @input="checkEmail()"/>
                <p class="warn-message" v-if="form.mail.hasError" v-text="form.mail.errorTxt"/>
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.tel.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="text" v-model="form.tel.value" @input="checkPhone()"/>
                <p class="warn-message" v-if="form.tel.hasError" v-text="form.tel.errorTxt"/>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
          <button type="button" @click="modify()" class="active" v-if="view.isModify">수정하기</button>
          <button type="button" @click="register()" class="active" v-else>등록하기</button>
          <button type="button" @click="withdraw()" class="draw" v-if="view.isModify">삭제하기</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" @api-called="withDrawApi"/>
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: '회원 관리',
        detail: '관리자 관리',
        detail2: '관리자 상세정보',
      },
      view: {
        isModify: false,
      },
      form: {
        id: {
          title: '아이디',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '아이디를 점검해주세요',
        },
        name: {
          title: '이름',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '이름을 점검해주세요',
        },
        password: {
          title: '비밀번호',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '비밀번호를 점검해주세요',
        },
        passwordConfirm: {
          title: '비밀번호 확인',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '비밀번호 확인을 점검해주세요',
        },
        mail: {
          title: '이메일',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '이메일 형식을 확인해주세요',
        },
        tel: {
          title: '연락처',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '연락처 형식을 확인해주세요',
        },
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.typeCheck();
  },
  methods: {
    ...mapActions('member', ['FETCH_MANAGE_DETAIL', 'POST_MANAGE_DETAIL', 'DELETE_MANAGE_DETAIL']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    checkFlag(save_type) {
      if (save_type == 'new' || this.form.password.value != '' || this.form.passwordConfirm.value != '') {
        if (!this.checkPw() || !this.checkPwConfirm()) {
          this.popupSet.content = '비밀번호 확인을 입력해주세요';
          this.form.passwordConfirm.flag = false;
          this.form.password.hasError = true;

          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
          return false;
        }
      }

      if (
        !this.form.id.flag
        || !this.form.name.flag
        || !this.form.mail.flag
        || !this.form.tel.flag
      ) {
        if (!this.form.id.flag) {
          this.popupSet.content = '아이디를 입력해주세요';
          this.form.id.flag = false;
          this.form.id.hasError = true;
        } else if (!this.form.name.flag) {
          this.popupSet.content = '이름을 입력해주세요';
          this.form.name.flag = false;
          this.form.name.hasError = true;
        } else if (!this.form.password.flag) {
          this.popupSet.content = '비밀번호를 입력해주세요';
          this.form.password.flag = false;
          this.form.password.hasError = true;
        } else if (!this.form.passwordConfirm.flag) {
          this.popupSet.content = '비밀번호 확인을 입력해주세요';
          this.form.passwordConfirm.flag = false;
          this.form.password.hasError = true;
        } else if (!this.form.mail.flag) {
          this.popupSet.content = '이메일을 입력해주세요';
          this.form.mail.flag = false;
          this.form.mail.hasError = true;
        } else if (!this.form.tel.flag) {
          this.popupSet.content = '연락처를 입력해주세요';
          this.form.tel.flag = false;
          this.form.tel.hasError = true;
        }
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
        return false;
      }
      return true;
    },
    register() {
      console.log('form: ', this.form);
      /** form의 value 를 서버에 전송 */
      if (this.checkFlag('new')) {
        this.settingApi('new');
      }
    },
    modify() {
      console.log('form: ', this.form);
      /** form의 value 를 서버에 전송 */
      if (this.checkFlag('mod')) {
        this.settingApi('mod');
      }
    },
    async settingApi(save_type) {
      console.log(`id : ${this.$route.params.code}`);
      console.log(`this.$route.params.code : ${this.$route.params.code}`);

      const data = {
        save_type,
        id: save_type == 'new' ? this.form.id.value : this.$route.params.code,
        password: this.form.password.value,
        name: this.form.name.value,
        mail: this.form.mail.value,
        tel: this.form.tel.value,
      };
      console.log(`data : ${data}`);

      await this.POST_MANAGE_DETAIL(data).then((result) => {
        console.log('result', result);
        if (result.result) {
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = save_type == 'new' ? '관리자 정보가 등록되었습니다.' : '관리자 정보가 변경되었습니다.';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
          this.popupSet.returnList = true;
        } else {
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'warning.svg';
          this.popupSet.content = result.response.message;
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
        }
      });
    },
    withdraw() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '관리자 계정을 삭제하시겠습니까?';
      this.popupSet.cancelBtnText = '취소';
      this.popupSet.isApiCall = true;
      this.popupSet.confirmBtnText = '삭제';
    },
    withDrawApi() {
      /** form의 value 를 서버에 전송(아마도  ID값) */
      console.log('a');
      setTimeout(() => {
        this.settingDeleteApi();
      }, 1);
    },
    async settingDeleteApi() {
      console.log(`data : ${this.$route.params.code}`);

      const data = `?id=${this.$route.params.code}`;

      await this.DELETE_MANAGE_DETAIL(data).then((result) => {
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.content = '관리자 계정이 삭제되었습니다.';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
        this.popupSet.returnList = true;
      });
    },
    checkPhone() {
      this.form.tel.flag = false;
      this.form.tel.hasError = true;
      const Phone = this.form.tel.value.replace(/\s/gi, '');
      if (Phone === '') {
        this.form.tel.flag = false;
        this.form.tel.hasError = true;
        this.form.tel.errorTxt = '핸드폰번호를 입력해주세요.';
        return false;
      }
      const isPhone = /^[0-9|-]{10,13}$/;
      if (!isPhone.test(Phone)) {
        this.form.tel.errorTxt = '숫자만을 입력해주세요.';
        this.form.tel.flag = false;
        this.form.tel.hasError = true;
        return false;
      }
      this.form.tel.flag = true;
      this.form.tel.hasError = false;
      this.form.tel.errorTxt = '';
      return true;
    },
    checkEmail() {
      this.form.mail.flag = false;
      this.form.mail.hasError = true;
      const mail = this.form.mail.value.replace(/\s/gi, '');
      if (mail === '') {
        this.form.mail.flag = false;
        this.form.mail.hasError = true;
        this.form.mail.errorTxt = '이메일을 입력해주세요.';
        return false;
      }
      const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!isEmail.test(mail)) {
        this.form.mail.flag = false;
        this.form.mail.hasError = true;
        this.form.mail.errorTxt = '이메일 형식에 맞지 않습니다.';
        return false;
      }
      this.form.mail.flag = true;
      this.form.mail.hasError = false;
      this.form.mail.errorTxt = '';
      return true;
    },
    checkPw() {
      this.form.password.flag = false;
      this.form.password.hasError = true;
      const pw = this.form.password.value.replace(/\s/gi, '');
      if (pw === '') {
        this.form.password.flag = false;
        this.form.password.hasError = true;
        this.form.password.errorTxt = '비밀번호를 입력해주세요.';
        return false;
      }
      const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,20}$/;
      if (!isPw.test(pw)) {
        this.form.password.errorTxt = '영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.';
        this.form.password.flag = false;
        this.form.password.hasError = true;
        return false;
      }
      this.form.password.flag = true;
      this.form.password.hasError = false;
      this.form.password.errorTxt = '';
      return true;
    },
    checkPwConfirm() {
      this.form.passwordConfirm.flag = false;
      this.form.passwordConfirm.hasError = true;
      const pwConfirm = this.form.passwordConfirm.value.replace(/\s/gi, '');
      if (pwConfirm === '') {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        this.form.passwordConfirm.errorTxt = '비밀번호 확인을 입력해주세요.';
        return false;
      }
      if (pwConfirm !== this.form.password.value) {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        this.form.passwordConfirm.errorTxt = '비밀번호가 일치하지 않습니다.';
        return false;
      }
      this.form.passwordConfirm.flag = true;
      this.form.passwordConfirm.hasError = false;
      this.form.passwordConfirm.errorTxt = '';
      return true;
    },
    checkName() {
      this.form.name.flag = false;
      this.form.name.hasError = true;
      const name = this.form.name.value.replace(/\s/gi, '');
      if (name === '') {
        this.form.name.flag = false;
        this.form.name.errorTxt = '이름을 입력해주세요.';
        return false;
      }
      const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z]{2,6}$/;
      if (!isHan.test(name)) {
        this.form.name.errorTxt = '한글 및 영문만 입력할 수 있습니다.';
        this.form.name.flag = false;
        return false;
      }
      this.form.name.flag = true;
      this.form.name.hasError = false;
      this.form.name.errorTxt = '';
      return true;
    },
    checkId() {
      this.form.id.flag = false;
      this.form.id.hasError = true;
      const id = this.form.id.value.replace(/\s/gi, '');
      if (id === '') {
        this.form.id.flag = false;
        this.form.id.errorTxt = '아이디를 입력해주세요.';
        return false;
      }
      const isId = /^[a-zA-Z][a-zA-Z0-9]{4,13}$/;
      if (!isId.test(id)) {
        this.form.id.errorTxt = '영문 및 숫자만 입력할 수 있습니다.';
        this.form.id.flag = false;
        return false;
      }
      this.form.id.flag = true;
      this.form.id.hasError = false;
      this.form.id.errorTxt = '';
      return true;
    },
    typeCheck() {
      if (this.$route.params.code !== undefined) {
        this.view.isModify = true;
        console.log(this.$route.params.code);
        this.getResult(this.$route.params.code);
      } else {
        this.tree.detail2 = '관리자 추가';
      }
    },
    async getResult(id) {
      /** id로 api 조회 */
      console.log('id: ', id);

      this.requestParam = `?id=${id}`;

      try {
        await this.FETCH_MANAGE_DETAIL(this.requestParam).then((data) => {
          /** id */
          console.log('id :', data.response.list[0].tel);
          this.form.id.value = data.response.list[0].id;
          this.form.id.flag = true;
          /** name */
          this.form.name.value = data.response.list[0].name;
          this.form.name.flag = true;
          /** mail */
          this.form.mail.value = data.response.list[0].mail;
          this.form.mail.flag = true;
          /** 비고 */
          this.form.tel.value = data.response.list[0].tel;
          this.form.tel.flag = true;
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/infoDetail";
</style>
