<template>
  <div class="tab-box" :class="{ clinic: tabType === 0 || tabType === 1 }">
    <ul class="tabs__header">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        @click="selectTab(index)"
        :class="{ tab__selected: index == selectedIndex }"
      >
        <p class="title">{{ tab.tabData.title }}</p>
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    tabType: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      selectedIndex: 0,
      tabs: []
    };
  },
  created() {
    this.tabs = this.$children;
    console.log("정보", this.tabs);
    console.log("들어온 탭타입", this.tabType);
  },
  mounted() {
    this.selectTab(this.tabType);
  },
  methods: {
    selectTab(i) {
      this.selectedIndex = i;
      // loop over all the tabs
      this.tabs.forEach((tab, index) => {
        tab.isActive = index === i;
      });
      /** 선택사항 이벤트 수신시 리로드 */
      this.$emit("tab-selected", i);
      console.log("보낸아이 :", i);
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.mini-tab {
  .tab-box {
    &.clinic {
      ul.tabs__header {
        li {
          width: 205px;
          margin: 0;
        }
      }
    }
  }
}
.tab-box {
  margin: 0 0 60px 0;
  &.clinic {
    ul.tabs__header {
      li {
        width: 260px;
        height: 46px;
        background: #f3f3f3;
        &.tab__selected {
          background: #f36900;
          p {
            color: #fff;
          }
        }
        p {
        }
      }
    }
  }
}
ul.tabs__header {
  padding: 0;
  li {
    width: 160px;
    height: 46px;
    background: rgba(49, 87, 84, 0.1);
    margin: 0 0 0 10px;
    border-top-right-radius: 4.5px;
    border-top-left-radius: 4.5px;
    position: relative;
    cursor: pointer;
    &.tab__selected {
      background: #fff;
      p {
        color: #315754;
      }
      &:after {
        content: "";
        position: absolute;
        background: #fff;
        width: 100%;
        height: 1px;
        bottom: -1px;
        left: 0;
      }
    }
    &:first-of-type {
      margin: 0;
    }
    p {
      @include initfontKor(
        $font-size: 21px,
        $line-height: 40px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.92px,
        $text-align: center
      );
    }
  }
}
.tab-wrapper {
  background: #fff;
  box-shadow: 0 3px 20px 0 rgba(54, 55, 57, 0.15);
  border: solid 1px rgb(234, 235, 241);
}
</style>
