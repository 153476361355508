import { training } from "@/api";

const state = {
  training: {
    /**
    drId: localStorage.getItem('drId') || '',
     */
  }
};

const mutations = {
  /**
  HACK_TOKEN(state, token) {
    state.auth.token = token;
  }
  */
};

const getters = {
  /**
  GET_CHECK_RESULT: (state) => state.auth.isCheck
   */
};

const actions = {
  FETCH_TRAINING_LIST({}, payload) {
    return training.fetchTrainingList(payload);
  },
  FETCH_TRAINING({}, payload) {
    return training.fetchTraining(payload);
  },
  FETCH_TRAINING_VIDEO({}, payload) {
    return training.fetchTrainingVideo(payload);
  },
  FETCH_TRAINING_DATE({}, payload) {
    return training.fetchTrainingDate(payload);
  },
  FETCH_TRAINING_HERTZ({}, payload) {
    return training.fetchTrainingHertz(payload);
  },
  CHECK_TRAINING({}, payload) {
    return training.checkTraining(payload);
  }
};
export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
