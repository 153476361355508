import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@page/member/PatientInfoList.vue';
import PatientSettingList from '@page/member/PatientSettingList.vue';
import PatientInfoDetail from '@page/member/PatientInfoDetail.vue';
import SettingPosture from '@page/member/SettingPosture.vue';
import SettingDiet from '@page/member/SettingDiet.vue';
import ConsultantList from '@page/member/ConsultantList.vue';
import ConsultantDetail from '@page/member/ConsultantDetail.vue';
import ManagementList from '@page/member/ManagementList.vue';
import ManagementDetail from '@page/member/ManagementDetail.vue';
import ClinicList from '@page/evaluate/ClinicList.vue';
import ClinicDetail from '@page/evaluate/ClinicDetail.vue';
import ExplainList from '@page/evaluate/ExplainList.vue';
import TrainingList from '@page/training/TrainingList.vue';
import TrainingDetail from '@page/training/TrainingDetail.vue';
import VoiceRecord from '@page/training/VoiceRecord.vue';
import WaveForm from '@page/training/WaveForm.vue';
import Video from '@page/training/VideoRecord.vue';
import PostureDietList from '@page/postureDiet/PostureDietList.vue';
import PostureDietDetail from '@page/postureDiet/PostureDietDetail.vue';
import ReservationList from '@page/reservation/ReservationList.vue';
import ReservationDetail from '@page/reservation/ReservationDetail.vue';
import ReservationLimit from '@page/reservation/ReservationLimit.vue';
import ReservationAdd from '@page/reservation/ReservationAdd.vue';
import ScheduleList from '@page/consultant/ScheduleList.vue';
import ScheduleDetail from '@page/consultant/ScheduleDetail.vue';
import ConsultantRecord from '@page/consultant/ConsultantRecord.vue';
import Setting from '@page/util/Setting.vue';
import NoticeList from '@page/util/NoticeList.vue';
import NoticeDetail from '@page/util/NoticeDetail.vue';
import Login from '@page/util/Login.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  // {
  //   path: "/logout",
  //   name: "Logout",
  //   component: Login
  // },
  {
    path: '/patientSettingList',
    name: 'PatientSettingList',
    component: PatientSettingList,
  },
  {
    path: '/patientInfoDetail/:code',
    name: 'PatientInfoDetail',
    component: PatientInfoDetail,
  },
  {
    path: '/settingPosture/:code',
    name: 'SettingPosture',
    component: SettingPosture,
  },
  {
    path: '/settingDiet/:code',
    name: 'SettingDiet',
    component: SettingDiet,
  },
  {
    path: '/consultantList',
    name: 'ConsultantList',
    component: ConsultantList,
  },
  {
    path: '/consultantDetail/:code',
    name: 'ConsultantDetail',
    component: ConsultantDetail,
  },
  {
    path: '/consultantAdd',
    name: 'ConsultantDetail',
    component: ConsultantDetail,
  },
  {
    path: '/managementList',
    name: 'ManagementList',
    component: ManagementList,
  },
  {
    path: '/managementDetail/:code',
    name: 'ManagementDetail',
    component: ManagementDetail,
  },
  {
    path: '/managementAdd',
    name: 'ManagementDetail',
    component: ManagementDetail,
  },
  {
    path: '/clinicList',
    name: 'ClinicList',
    component: ClinicList,
  },
  {
    path: '/clinicDetail/:code',
    name: 'ClinicDetail',
    component: ClinicDetail,
  },
  {
    path: '/explainList',
    name: 'ExplainList',
    component: ExplainList,
  },
  {
    path: '/explainDetail/:code',
    name: 'ClinicDetail',
    component: ClinicDetail,
  },
  {
    path: '/trainingList',
    name: 'TrainingList',
    component: TrainingList,
  },
  {
    path: '/trainingDetail',
    name: 'TrainingDetail',
    component: TrainingDetail,
  },
  {
    path: '/voiceRecord',
    name: 'VoiceRecord',
    component: VoiceRecord,
  },
  {
    path: '/waveForm',
    name: 'WaveForm',
    component: WaveForm,
  },
  {
    path: '/video',
    name: 'Video',
    component: Video,
  },
  {
    path: '/postureDietList',
    name: 'PostureDietList',
    component: PostureDietList,
  },
  {
    path: '/postureDietDetail/:code',
    name: 'PostureDietDetail',
    component: PostureDietDetail,
  },
  {
    path: '/reservationList',
    name: 'ReservationList',
    component: ReservationList,
  },
  {
    path: '/reservationDetail/:code',
    name: 'ReservationDetail',
    component: ReservationDetail,
  },
  {
    path: '/reservationAdd',
    name: 'ReservationAdd',
    component: ReservationAdd,
  },
  {
    path: '/reservationLimit',
    name: 'ReservationLimit',
    component: ReservationLimit,
  },
  {
    path: '/scheduleList',
    name: 'ScheduleList',
    component: ScheduleList,
  },
  {
    path: '/scheduleDetail/:code',
    name: 'ScheduleDetail',
    component: ScheduleDetail,
  },
  {
    path: '/consultantRecord',
    name: 'ConsultantRecord',
    component: ConsultantRecord,
  },
  {
    path: '/setting',
    name: 'Setting',
    component: Setting,
  },
  {
    path: '/noticeList',
    name: 'NoticeList',
    component: NoticeList,
  },
  {
    path: '/noticeDetail/',
    name: 'NoticeDetail',
    component: NoticeDetail,
  },
  {
    path: '/noticeDetail/:code',
    name: 'NoticeDetail',
    component: NoticeDetail,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
