<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="excel-btn">
          <div class="btn-wrap" @click="download()">
            <p>Excel 다운로드</p>
          </div>
        </div>
        <div class="training-result" v-if="view.isPrepared">
          <div class="member-info-set">
            <div class="header">
              <div class="item" v-for="(header, index) in member" :key="index + 'A'">
                <p v-text="header.questionText" />
              </div>
            </div>
            <div class="content">
              <div class="item" v-for="(value, index) in member" :key="index + 'B'">
                <p v-text="value.answerResult" v-if="index === 0" />
                <div class="select-wrap" v-else>
                  <v-select
                    :searchable="false"
                    :options="value.answerResultOpt"
                    @input="dayPick(value.answerResult)"
                    v-model="value.answerResult"
                  ></v-select>
                </div>
              </div>
            </div>
          </div>
          <div class="training-list" v-if="view.swallowPrepared">
            <h5 class="title">오래삼키기</h5>
            <div class="time-wrap">
              <div
                class="time-status"
                v-for="(timeItem, timeIndex) in Object.keys(result.longSwallow).length"
                :key="timeIndex"
              >
                <div class="start">
                  <h3
                    class="title"
                    v-if="
                      Object.entries(
                        Object.entries(
                          result.longSwallow[Object.keys(result.longSwallow)[timeIndex]]
                        )[0]
                      )[1][1].length > 0
                    "
                  >
                    시작시간
                  </h3>
                  <div class="time" v-if="result.longSwallow.morning.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.longSwallow))[timeIndex][1]
                        ).indexOf('morning') !== -1
                      "
                    >
                      {{ timeFormat(result.longSwallow.morning.training[0].enteredTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.longSwallow.afternoon.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.longSwallow))[timeIndex][1]
                        ).indexOf('afternoon') !== -1
                      "
                    >
                      {{ timeFormat(result.longSwallow.afternoon.training[0].enteredTime) }}
                    </p>
                  </div>
                  <div class="time 232" v-if="result.longSwallow.night.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.longSwallow))[timeIndex][1]
                        ).indexOf('night') !== -1
                      "
                    >
                      {{ timeFormat(result.longSwallow.night.training[0].enteredTime) }}
                    </p>
                  </div>
                </div>
                <div class="end">
                  <h3
                    class="title"
                    v-if="
                      Object.entries(
                        Object.entries(
                          result.longSwallow[Object.keys(result.longSwallow)[timeIndex]]
                        )[0]
                      )[1][1].length > 0
                    "
                  >
                    종료시간
                  </h3>
                  <div class="time" v-if="result.longSwallow.morning.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.longSwallow))[timeIndex][1]
                        ).indexOf('morning') !== -1
                      "
                    >
                      {{ timeFormat(result.longSwallow.morning.training[0].exitTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.longSwallow.afternoon.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.longSwallow))[timeIndex][1]
                        ).indexOf('afternoon') !== -1
                      "
                    >
                      {{ timeFormat(result.longSwallow.afternoon.training[0].exitTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.longSwallow.night.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.longSwallow))[timeIndex][1]
                        ).indexOf('night') !== -1
                      "
                    >
                      {{ timeFormat(result.longSwallow.night.training[0].exitTime) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-wrap">
              <div
                class="list"
                v-for="(trainingType, index) in Object.entries(result.longSwallow)"
                :key="index + 'B'"
                :style="[trainingType[1].training.length <= 0 ? { width: 0 } : null]"
              >
                <div
                  class="time-notification"
                  :class="{ actived: trainingType[1].training.length !== 1 }"
                  v-if="trainingType[1].training.length > 0"
                >
                  <p v-text="transTimeName(trainingType[0])" />
                  <p v-text="trainingType[1].time" v-if="trainingType[1].time" />
                  <p v-else>미설정</p>
                </div>
                <div
                  class="set"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <p
                    v-for="(set, index) in trainingType[1].training.length"
                    :key="index + 'C'"
                    v-text="index + 1 + '세트'"
                  />
                </div>

                <div
                  class="count"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <!-- 정보확인용 텍스트
                    <p v-text="Object.entries(trainingType[1].training)" style="color:red;" /> -->
                  <p
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'D'"
                    v-text="
                      count[1].proceeding === count[1].total
                        ? count[1].proceeding + '회 (완료)'
                        : count[1].proceeding + '회'
                    "
                  />
                </div>

                <!--
                <div
                  class="noti-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(miniItem, miniIndex) in Object.entries(trainingType[1].training)"
                    :key="miniIndex + 'A'"
                  >
                    <p>시작</p>
                  </div>
                </div>
                <div class="noti-time alone" v-else>
                  <div class="item">
                    <p>시작</p>
                  </div>
                </div>
x                <div
                  class="on-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'E'"
                  >
                    <p v-text="timeFormat(count[1].enteredTime)" />
                  </div>
                </div>
                <div class="on-time alone" v-else>
                  <div class="item">
                    <p>기록없음</p>
                  </div>
                </div>
                <div
                  class="noti-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(miniItem, miniIndex) in Object.entries(trainingType[1].training)"
                    :key="miniIndex + 'B'"
                  >
                    <p>종료</p>
                  </div>
                </div>
                <div class="noti-time alone" v-else>
                  <div class="item">
                    <p>종료</p>
                  </div>
                </div>
                <div
                  class="on-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'F1'"
                  >
                    <p v-text="timeFormat(count[1].exitTime)" />
                  </div>
                </div>
                <div class="on-time alone" v-else>
                  <div class="item">
                    <p>기록없음</p>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
          <div class="training-list" v-if="view.raisingPrepared">
            <div class="plus-btn">
              <button type="button" @click="goDetail('voice')">상세보기</button>
            </div>
            <h5 class="title">음도 높이기</h5>
            <div class="time-wrap">
              <div
                class="time-status"
                v-for="(timeItem, timeIndex) in Object.keys(result.increasePitch).length"
                :key="timeIndex"
              >
                <div class="start">
                  <h3
                    class="title"
                    v-if="
                      Object.entries(
                        Object.entries(
                          result.increasePitch[Object.keys(result.increasePitch)[timeIndex]]
                        )[0]
                      )[1][1].length > 0
                    "
                  >
                    시작시간
                  </h3>
                  <div class="time" v-if="result.increasePitch.morning.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.increasePitch))[timeIndex][1]
                        ).indexOf('morning') !== -1
                      "
                    >
                      {{ timeFormat(result.increasePitch.morning.training[0].enteredTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.increasePitch.afternoon.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.increasePitch))[timeIndex][1]
                        ).indexOf('afternoon') !== -1
                      "
                    >
                      {{ timeFormat(result.increasePitch.afternoon.training[0].enteredTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.increasePitch.night.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.increasePitch))[timeIndex][1]
                        ).indexOf('night') !== -1
                      "
                    >
                      {{ timeFormat(result.increasePitch.night.training[0].enteredTime) }}
                    </p>
                  </div>
                </div>
                <div class="end">
                  <h3
                    class="title"
                    v-if="
                      Object.entries(
                        Object.entries(
                          result.increasePitch[Object.keys(result.increasePitch)[timeIndex]]
                        )[0]
                      )[1][1].length > 0
                    "
                  >
                    종료시간
                  </h3>
                  <div class="time" v-if="result.increasePitch.morning.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.increasePitch))[timeIndex][1]
                        ).indexOf('morning') !== -1
                      "
                    >
                      {{ timeFormat(result.increasePitch.morning.training[0].exitTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.increasePitch.afternoon.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.increasePitch))[timeIndex][1]
                        ).indexOf('afternoon') !== -1
                      "
                    >
                      {{ timeFormat(result.increasePitch.afternoon.training[0].exitTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.increasePitch.night.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.increasePitch))[timeIndex][1]
                        ).indexOf('night') !== -1
                      "
                    >
                      {{ timeFormat(result.increasePitch.night.training[0].exitTime) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-wrap">
              <div
                class="list"
                v-for="(trainingType, index) in Object.entries(result.increasePitch)"
                :key="index + 'B'"
                :style="[trainingType[1].training.length <= 0 ? { width: 0 } : null]"
              >
                <div
                  class="time-notification"
                  :class="{ actived: trainingType[1].training.length !== 1 }"
                  v-if="trainingType[1].training.length > 0"
                >
                  <p v-text="transTimeName(trainingType[0])" />
                  <p v-text="trainingType[1].time" v-if="trainingType[1].time !== null" />
                  <p v-else>미설정</p>
                </div>
                <div
                  class="set"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <p
                    v-for="(set, index) in trainingType[1].training.length"
                    :key="index + 'C'"
                    v-text="index + 1 + '세트'"
                  />
                </div>
                <div
                  class="count"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <p
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'D'"
                    v-text="
                      count[1].proceeding === count[1].total
                        ? count[1].proceeding + '회 (완료)'
                        : count[1].proceeding + '회'
                    "
                  />
                </div>
                <!--
                <div
                  class="noti-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(miniItem, miniIndex) in Object.entries(trainingType[1].training)"
                    :key="miniIndex + 'D'"
                  >
                    <p>시작</p>
                  </div>
                </div>
                <div class="noti-time alone" v-else>
                  <div class="item">
                    <p>시작</p>
                  </div>
                </div>
                <div
                  class="on-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'E'"
                  >
                    <p v-text="timeFormat(count[1].enteredTime)" />
                  </div>
                </div>
                <div class="on-time alone" v-else>
                  <div class="item">
                    <p>기록없음</p>
                  </div>
                </div>
                <div
                  class="noti-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(miniItem, miniIndex) in Object.entries(trainingType[1].training)"
                    :key="miniIndex + 'F2'"
                  >
                    <p>종료</p>
                  </div>
                </div>
                <div class="noti-time alone" v-else>
                  <div class="item">
                    <p>종료</p>
                  </div>
                </div>
                <div
                  class="on-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'F3'"
                  >
                    <p v-text="timeFormat(count[1].exitTime)" />
                  </div>
                </div>
                <div class="on-time alone" v-else>
                  <div class="item">
                    <p>기록없음</p>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
          <div class="training-list" v-if="view.turnPrepared">
            <div class="plus-btn">
              <button type="button" @click="goDetail('tongue')">상세보기</button>
            </div>
            <h5 class="title">혀 돌리기</h5>
            <div class="time-wrap">
              <div
                class="time-status"
                v-for="(timeItem, timeIndex) in Object.keys(result.tongueTurn).length"
                :key="timeIndex"
              >
                <div class="start">
                  <h3
                    class="title"
                    v-if="
                      Object.entries(
                        Object.entries(
                          result.tongueTurn[Object.keys(result.tongueTurn)[timeIndex]]
                        )[0]
                      )[1][1].length > 0
                    "
                  >
                    시작시간
                  </h3>
                  <div class="time" v-if="result.tongueTurn.morning.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.tongueTurn))[timeIndex][1]
                        ).indexOf('morning') !== -1
                      "
                    >
                      {{ timeFormat(result.tongueTurn.morning.training[0].enteredTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.tongueTurn.afternoon.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.tongueTurn))[timeIndex][1]
                        ).indexOf('afternoon') !== -1
                      "
                    >
                      {{ timeFormat(result.tongueTurn.afternoon.training[0].enteredTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.tongueTurn.night.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.tongueTurn))[timeIndex][1]
                        ).indexOf('night') !== -1
                      "
                    >
                      {{ timeFormat(result.tongueTurn.night.training[0].enteredTime) }}
                    </p>
                  </div>
                </div>
                <div class="end">
                  <h3
                    class="title"
                    v-if="
                      Object.entries(
                        Object.entries(
                          result.tongueTurn[Object.keys(result.tongueTurn)[timeIndex]]
                        )[0]
                      )[1][1].length > 0
                    "
                  >
                    종료시간
                  </h3>
                  <div class="time" v-if="result.tongueTurn.morning.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.tongueTurn))[timeIndex][1]
                        ).indexOf('morning') !== -1
                      "
                    >
                      {{ timeFormat(result.tongueTurn.morning.training[0].exitTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.tongueTurn.afternoon.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.tongueTurn))[timeIndex][1]
                        ).indexOf('afternoon') !== -1
                      "
                    >
                      {{ timeFormat(result.tongueTurn.afternoon.training[0].exitTime) }}
                    </p>
                  </div>
                  <div class="time" v-if="result.tongueTurn.night.training.length > 0">
                    <p
                      v-if="
                        JSON.stringify(
                          Object.entries(Object.keys(result.tongueTurn))[timeIndex][1]
                        ).indexOf('night') !== -1
                      "
                    >
                      {{ timeFormat(result.tongueTurn.night.training[0].exitTime) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="list-wrap">
              <div
                class="list"
                v-for="(trainingType, index) in Object.entries(result.tongueTurn)"
                :key="index + 'B'"
                :style="[trainingType[1].training.length <= 0 ? { width: 0 } : null]"
              >
                <div
                  class="time-notification"
                  :class="{ actived: trainingType[1].training.length !== 1 }"
                  v-if="trainingType[1].training.length > 0"
                >
                  <p v-text="transTimeName(trainingType[0])" />
                  <p v-text="trainingType[1].time" v-if="trainingType[1].time !== null" />
                  <p v-else>미설정</p>
                </div>
                <div
                  class="set"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <p
                    v-for="(set, index) in trainingType[1].training.length"
                    :key="index + 'C'"
                    v-text="index + 1 + '세트'"
                  />
                </div>

                <div
                  class="count"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <p
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'D'"
                    v-text="
                      count[1].proceeding === count[1].total
                        ? count[1].proceeding + '회 (완료)'
                        : count[1].proceeding + '회'
                    "
                  />
                </div>

                <!--
                <div
                  class="noti-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(miniItem, miniIndex) in Object.entries(trainingType[1].training)"
                    :key="miniIndex + 'F4'"
                  >
                    <p>시작</p>
                  </div>
                </div>
                <div class="noti-time alone" v-else>
                  <div class="item">
                    <p>시작</p>
                  </div>
                </div>
                <div
                  class="on-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'E'"
                  >
                    <p v-text="timeFormat(count[1].enteredTime)" />
                  </div>
                </div>
                <div class="on-time alone" v-else>
                  <div class="item">
                    <p>기록없음</p>
                  </div>
                </div>
                <div
                  class="noti-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(miniItem, miniIndex) in Object.entries(trainingType[1].training)"
                    :key="miniIndex + 'F5'"
                  >
                    <p>종료</p>
                  </div>
                </div>
                <div class="noti-time alone" v-else>
                  <div class="item">
                    <p>종료</p>
                  </div>
                </div>
                <div
                  class="on-time"
                  v-if="trainingType[1].training.length > 0"
                  :class="{ alone: trainingType[1].training.length === 1 }"
                >
                  <div
                    class="item"
                    v-for="(count, index) in Object.entries(trainingType[1].training)"
                    :key="index + 'F6'"
                  >
                    <p v-text="timeFormat(count[1].exitTime)" />
                  </div>
                </div>
                <div class="on-time alone" v-else>
                  <div class="item">
                    <p>기록없음</p>
                  </div>
                </div>
                -->
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import DefaultPopup from "@/components/modal/DefaultPopup.vue";
import SideMenu from "@/components/SideMenu.vue";
import Notification from "@/components/Notification.vue";
import { mapActions, mapMutations, mapState } from "vuex";

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: "삼킴 훈련",
        detail: "삼킴 훈련 리스트",
        detail2: "훈련별 상세정보"
      },
      member: [],
      result: {},
      view: {
        isPrepared: false,
        swallowPrepared: false,
        raisingPrepared: false,
        turnPrepared: false
      }
    };
  },
  created() {
    console.log(`get params | ${this.$route.query.date}`);
    console.log(
      `get params | date : ${this.$route.query.date} | userId : ${this.$route.query.userId}`
    );

    window.scrollTo(0, 0);
    this.getMember();
    this.dayPick(this.$route.query.date);
  },
  mounted() {},
  methods: {
    ...mapActions("training", ["FETCH_TRAINING", "FETCH_TRAINING_DATE"]),
    ...mapMutations("basic", ["SET_POPUP"]),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    dayPick(date) {
      this.view.isPrepared = false;
      console.log("date :", date);
      this.FETCH_TRAINING(`?userId=${this.$route.query.userId}&date=${date}`).then(data => {
        console.log(data);

        const number = data.response.settingNumber;
        // let set = data.response.settingSet;
        const { pitch } = data.response;
        const { tongue } = data.response;
        const { swallow } = data.response;
        console.log(pitch, tongue, swallow, number, "?");
        const list = {};

        list.longSwallow = {};
        list.longSwallow.morning = {};
        list.longSwallow.morning.training = [];
        list.longSwallow.afternoon = {};
        list.longSwallow.afternoon.training = [];
        list.longSwallow.night = {};
        list.longSwallow.night.training = [];

        list.increasePitch = {};
        list.increasePitch.morning = {};
        list.increasePitch.morning.training = [];
        list.increasePitch.afternoon = {};
        list.increasePitch.afternoon.training = [];
        list.increasePitch.night = {};
        list.increasePitch.night.training = [];

        list.tongueTurn = {};
        list.tongueTurn.morning = {};
        list.tongueTurn.morning.training = [];
        list.tongueTurn.afternoon = {};
        list.tongueTurn.afternoon.training = [];
        list.tongueTurn.night = {};
        list.tongueTurn.night.training = [];

        list.longSwallow.morning.time = swallow[0].morning_time;
        list.increasePitch.morning.time = pitch[0].morning_time;
        list.tongueTurn.morning.time = tongue[0].morning_time;

        list.longSwallow.afternoon.time = swallow[0].lunch_time;
        list.increasePitch.afternoon.time = pitch[0].lunch_time;
        list.tongueTurn.afternoon.time = tongue[0].lunch_time;

        list.longSwallow.night.time = swallow[0].evening_time;
        list.increasePitch.night.time = pitch[0].evening_time;
        list.tongueTurn.night.time = tongue[0].evening_time;

        for (let i = 0; i < data.response.morning.length; i++) {
          const item = data.response.morning[i];
          const { type } = item;
          if (data.response.morning[i].type === "swallow") {
            this.view.swallowPrepared = true;
          }
          if (data.response.morning[i].type === "raising") {
            this.view.raisingPrepared = true;
          }
          if (data.response.morning[i].type === "turn") {
            this.view.turnPrepared = true;
          }
          for (let j = 0; j < item.list.length; j++) {
            const subitem = item.list[j];

            switch (type) {
              case "swallow":
                list.longSwallow.morning.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
              case "raising":
                list.increasePitch.morning.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
              case "turn":
                list.tongueTurn.morning.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
            }
          }
        }

        for (let i = 0; i < data.response.lunch.length; i++) {
          const item = data.response.lunch[i];
          const { type } = item;
          if (data.response.lunch[i].type === "swallow") {
            this.view.swallowPrepared = true;
          }
          if (data.response.lunch[i].type === "raising") {
            this.view.raisingPrepared = true;
          }
          if (data.response.lunch[i].type === "turn") {
            this.view.turnPrepared = true;
          }
          for (let j = 0; j < item.list.length; j++) {
            const subitem = item.list[j];

            switch (type) {
              case "swallow":
                list.longSwallow.afternoon.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
              case "raising":
                list.increasePitch.afternoon.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
              case "turn":
                list.tongueTurn.afternoon.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
            }
          }
        }

        for (let i = 0; i < data.response.evening.length; i++) {
          const item = data.response.evening[i];
          const { type } = item;
          if (data.response.evening[i].type === "swallow") {
            this.view.swallowPrepared = true;
          }
          if (data.response.evening[i].type === "raising") {
            this.view.raisingPrepared = true;
          }
          if (data.response.evening[i].type === "turn") {
            this.view.turnPrepared = true;
          }

          for (let j = 0; j < item.list.length; j++) {
            const subitem = item.list[j];

            switch (type) {
              case "swallow":
                list.longSwallow.night.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
              case "raising":
                list.increasePitch.night.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
              case "turn":
                list.tongueTurn.night.training.push({
                  total: number,
                  proceeding: subitem.number,
                  enteredTime: item.startAt,
                  exitTime: item.endAt
                });
                break;
            }
          }
        }
        this.result = list;
        this.view.isPrepared = true;
      });
    },
    getMember() {
      this.FETCH_TRAINING_DATE(`?userId=${this.$route.query.userId}`).then(data => {
        console.log(data, "?????");
        const getList = [
          {
            question: 1,
            questionText: "회원명",
            answerResult: data.response.userName
          },
          {
            question: 2,
            questionText: "훈련일자",
            answerResultOpt: data.response.list
          }
        ];
        getList[1].answerResult = String(this.$route.query.date);
        console.log(getList);
        this.member = getList;
      });
    },
    goDetail(string) {
      const params = this.$route.query.date;
      if (string === "voice") {
        let compareVoiceMorning = "";
        let compareVoiceAfternoon = "";
        let compareVoiceNight = "";
        let morningFlag = false;
        let afternoonFlag = false;
        let nightFlag = false;
        if (this.result.increasePitch.morning.training.length !== 0) {
          compareVoiceMorning = this.result.increasePitch.morning.training[
            this.result.increasePitch.morning.training.length - 1
          ];
          if (compareVoiceMorning.proceeding === compareVoiceMorning.total) {
            morningFlag = true;
          }
        }
        if (this.result.increasePitch.afternoon.training.length !== 0) {
          compareVoiceAfternoon = this.result.increasePitch.afternoon.training[
            this.result.increasePitch.afternoon.training.length - 1
          ];
          if (compareVoiceAfternoon.proceeding === compareVoiceAfternoon.total) {
            afternoonFlag = true;
          }
        }
        if (this.result.increasePitch.night.training.length !== 0) {
          compareVoiceNight = this.result.increasePitch.night.training[
            this.result.increasePitch.night.training.length - 1
          ];
          if (compareVoiceNight.proceeding === compareVoiceNight.total) {
            nightFlag = true;
          }
        }
        this.$router.push({
          name: "VoiceRecord",
          query: { userId: this.$route.query.userId, date: this.member[1].answerResult },
          params: {
            morningComplete: morningFlag === true,
            afternoonComplete: afternoonFlag === true,
            nightComplete: nightFlag === true
          }
        });
      } else if (string === "tongue") {
        this.$router.push({
          name: "Video",
          query: { userId: this.$route.query.userId }
        });
      }
    },
    async download() {
      const data = {
        id: this.$route.query.userId
      };
      console.log(`download-data : ${data.id}`);
      axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/training/trainingall/excel?userId=${data.id}`,
        responseType: "blob",
        headers: {
          Authorization: `Token ${localStorage.getItem("token")}`
        }
      }).then(response => {
        console.log(response, " : response");
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: response.headers["content-type"] })
        );
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.member[0].answerResult}.xlsx`);
        document.body.appendChild(link);
        link.click();
      });
    }
  },
  computed: {
    ...mapState("basic", ["checkPopup"]),
    txtEscape() {
      return value => {
        if (value === "") {
          return "-";
        }
        return value;
      };
    },
    timeFormat() {
      return time => {
        if (time === "") {
          return "-";
        }
        return this.$moment(time).format("YYYY-MM-DD HH:mm:ss");
      };
    },
    transTimeName() {
      return time => {
        let timeName = time;
        if (timeName !== null && timeName !== undefined && timeName !== "") {
          timeName = timeName
            .toString()
            .replace("morning", "아침")
            .replace("afternoon", "점심")
            .replace("night", "저녁");
        }
        return timeName;
      };
    }
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    vSelect
  }
};
</script>
<style lang="scss" scoped>
.container {
  .excel-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    .btn-wrap {
      cursor: pointer;
      background: #fd8b11;
      p {
        margin: 0 10px;
        @include initfontKor(
          $font-size: 15px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.training-result {
  margin: 0 0 105px 0;
  .member-info-set {
    width: 340px;
    margin: 0 0 85px 0;
    .header {
      display: grid;
      grid-auto-flow: dense;
      // grid-gap: 1px;
      grid-template-columns: 125px 1fr;
      // margin: 0 0 1px 0;
      .item {
        padding: 0;
        border: 1px solid #acb3c9;
        p {
          background: #f3f3f3;
          padding: 8px 12px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
    }
    .content {
      display: grid;
      grid-auto-flow: dense;
      // grid-gap: 1px;
      grid-template-columns: 125px 1fr;
      .item {
        padding: 0;
        border: 1px solid #acb3c9;
        display: flex;
        justify-content: center;
        align-items: center;
        .select-wrap {
          margin: 10px auto;
        }
        p {
          padding: 8px 12px;
          @include initfontKor(
            $font-size: 16px,
            $line-height: 26px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
        }
      }
    }
  }

  .training-list {
    position: relative;
    margin: 40px 0 0 0;
    .time-wrap {
      display: grid;
      grid-auto-flow: column;
      // grid-gap: 1px;
      grid-template-columns: 450px;
      max-width: 450px;
      // margin: 1px 0 0 0;
      .time-status {
        .start,
        .end {
          display: flex;
          .title {
            width: 150px;
            background: #f3f3f3;
            border: 1px solid #acb3c9;
            @include initfontKor(
              $font-size: 19px,
              $line-height: 29px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
          }
          .time {
            p {
              border: 1px solid #acb3c9;
              min-width: 300px;
              @include initfontKor(
                $font-size: 19px,
                $line-height: 29px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
            }
            &:last-of-type {
            }
          }
        }
      }
    }
    .plus-btn {
      position: absolute;
      left: 140px;
      button {
        border: 0;
        width: 120px;
        background: #292a2b;
        cursor: pointer;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 29px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
    &:first-of-type {
      margin: 0;
    }
    .list-wrap {
      display: flex;
      h5 {
        width: 100%;
        @include initfontKor(
          $font-size: 19px,
          $line-height: 48px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: left
        );
      }
      .list {
        display: grid;
        grid-auto-flow: dense;
        // grid-gap: 1px;
        grid-template-columns: 150px 150px 150px;
        // margin: 1px 0 0 0;
        &:first-of-type {
        }
        .time-notification {
          border: 1px solid #acb3c9;
          position: relative;
          background: #f3f3f3;
          &.actived {
            padding: 9.5px 0;
          }

          p {
            width: 100%;
            margin: 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 32px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:last-of-type {
              /**
              position: absolute;
              transform: translateY(-50%);
              top: calc(50% + 17.5px);
               */
            }
          }
        }
        .set {
          border: 1px solid #acb3c9;
          background: #fff9f3;
          &.alone {
            p {
              line-height: 64px;
            }
          }
          p {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 41px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              border-width: 0 0 0 0;
            }
          }
        }
        .count {
          border: 1px solid #acb3c9;
          &.alone {
            p {
              line-height: 64px;
            }
          }
          p {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            @include initfontKor(
              $font-size: 18px,
              $line-height: 41px,
              $font-family: "AppleSDGothicNeoM00",
              $color: #292a2b,
              $letter-spacing: -0.6px,
              $text-align: center
            );
            &:first-of-type {
              border-width: 0 0 0 0;
            }
          }
        }
        .on-time {
          border: 1px solid #acb3c9;
          &.alone {
            .item {
              p {
                line-height: 64px;
              }
            }
          }
          .item {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            display: flex;
            &:first-of-type {
              border-width: 0 0 0 0;
            }
            p {
              @include initfontKor(
                $font-size: 18px,
                $line-height: 41px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              width: 100%;
            }
          }
        }
        .noti-time {
          border: 1px solid #acb3c9;
          background: #fff9f3;
          &.alone {
            .item {
              p {
                line-height: 64px;
              }
            }
          }
          .item {
            border: 0px solid #acb3c9;
            border-width: 1px 0 0 0;
            display: flex;
            &:first-of-type {
              border-width: 0 0 0 0;
            }
            p {
              @include initfontKor(
                $font-size: 18px,
                $line-height: 41px,
                $font-family: "AppleSDGothicNeoM00",
                $color: #292a2b,
                $letter-spacing: -0.6px,
                $text-align: center
              );
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
