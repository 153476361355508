<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="resv-time-set">
          <div
            class="list"
            v-for="(parentItem, parentIndex) in Object.entries(resvList)"
            :key="parentIndex + 'A'"
          >
            <h5 class="title" v-text="parentIndex === 0 ? '오전' : '오후'" />
            <div class="item">
              <h5 class="header">시간</h5>
              <div class="content">
                <p
                  v-for="(childItem, childIndex) in parentItem[1].timeList"
                  :key="childIndex + 'A'"
                  v-text="childItem"
                />
              </div>
            </div>
            <div class="item">
              <h5 class="header">예약가능 회원 수</h5>
              <div class="content-normal">
                <div class="select-wrap">
                  <v-select
                    :searchable="false"
                    :options="parentItem[1].resvLimitOpt"
                    v-model="parentItem[1].limitCount"
                  ></v-select>
                </div>
              </div>
            </div>
            <div class="btn-wrap">
              <button type="button" @click="timeChange(parentIndex)">수정</button>
            </div>
          </div>
        </div>
        <!-- <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div> -->
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import vSelect from 'vue-select';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: "원격 상담",
        detail: '예약 시간별 제한 설정',
      },
      resvList: {},
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getList();
  },
  methods: {
    ...mapActions('reservation', ['FETCH_CONSULTATION_LIMIT', 'UPDATE_CONSULTATION_LIMIT']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true)
      this.popupSet.popType = "warn";
      this.popupSet.popImg = "success.svg";
      this.popupSet.content = "로그아웃 되었습니다.";
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = "/login";
      this.popupSet.confirmBtnText = "확인";
      this.popupSet.destination = true;
    },
    timeChange(index) {
      let targetTime = index === 0 ? this.resvList.morning : this.resvList.afternoon;
      let limitCountToApi = targetTime.limitCount;
      console.log('limitCountToApi :', limitCountToApi);
      /** limitCount 로 API를 조회 */
      /** API 통과시 팝업으로 알림 */
      let result = true;
      for (let i=0; i<targetTime.idList.length; i++) {
        let data = {
          id: targetTime.idList[i],
          limit: limitCountToApi,
        };
        this.UPDATE_CONSULTATION_LIMIT(data).then(data => {
          if (data.result === false)
            result = false;
        });

        if (result === false)
          break;
      }
      this.SET_POPUP(true);
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = result ? '예약가능 회원수 변경이 완료되었습니다.' : '예약가능 회원수 변경 중 오류가 발생하였습니다.';
      this.popupSet.cancelBtnText = '확인';
      this.popupSet.confirmBtnText = null;
    },
    async getList() {
      /** info api 조회 */
      await this.FETCH_CONSULTATION_LIMIT().then(data => {
        let list = {
          morning: {
            idList: [],
            timeList: [],
            resvLimitOpt: [],
            limitCount: 0,
          },
          afternoon: {
            idList: [],
            timeList: [],
            resvLimitOpt: [],
            limitCount: 0,
          }
        };

        for (let i=0; i<10; i++) {
          list.morning.resvLimitOpt.push(i + 1);
          list.afternoon.resvLimitOpt.push(i + 1);
        }

        for (let i=0; i<data.response.list.length; i++) {
          let item = data.response.list[i];
          let targetTime = item.time >= '13:00' ? list.afternoon : list.morning;

          targetTime.idList.push(item.id);
          targetTime.timeList.push(item.time);
          targetTime.limitCount = item.limit;
        }

        this.resvList = list;
      });
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
    vSelect,
  },
};
</script>
<style lang="scss" scoped>
.resv-time-set {
  margin: 0 0 165px 0;
  .list {
    margin: 60px 0 0 0;
    &:first-of-type {
      margin: 0;
    }
    h5.title {
      @include initfontKor(
        $font-size: 22px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoB00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: left
      );
    }
    .item {
      display: grid;
      grid-auto-flow: dense;
      grid-gap: 1px;
      grid-template-columns: 170px 1fr;
      margin: 1px 0 0 0;
      &:first-of-type {
        margin: 0;
      }
      h5.header {
        outline: 1px solid #acb3c9;
        background: #f3f3f3;
        @include initfontKor(
          $font-size: 18px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
      .content {
        display: grid;
        grid-auto-flow: dense;
        grid-gap: 1px;
        grid-template-columns: repeat(auto-fill, 130px);
        p {
          outline: 1px solid #acb3c9;
          @include initfontKor(
            $font-size: 18px,
            $line-height: 40px,
            $font-family: "AppleSDGothicNeoM00",
            $color: #292a2b,
            $letter-spacing: -0.6px,
            $text-align: center
          );
          &:first-of-type {
            margin: 0;
          }
        }
      }
      .content-normal {
        width: 130px;
        .select-wrap {
          width: inherit;
          outline: 1px solid #acb3c9;
          span {
            display: flex;
            justify-content: center;
          }
        }
      }
    }
    .btn-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 25px 0 0 0;
      width: 105px;
      button {
        border: 0;
        width: inherit;
        background: #292a2b;
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
        @include initfontKor(
          $font-size: 18px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
        cursor: pointer;
      }
    }
  }
}
</style>
<style lang="scss">
.content-normal .select-wrap .v-select {
  span,
  li {
    display: flex;
    justify-content: center;
  }
  .vs__dropdown-toggle {
    border: 0;
  }
}
</style>
