<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="info-detail">
          <div class="article">
            <h5 class="title" v-text="form.id.title" />
            <div class="content">
              <div class="input-wrap">
                <input
                  type="text"
                  v-model="form.id.value"
                  @input="checkId()"
                  :disabled="view.isModify"
                />
                <p class="warn-message" v-if="form.id.hasError" v-text="form.id.errorTxt" />
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.password.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="password" v-model="form.password.value" @input="checkPw()" />
                <p
                  class="warn-message"
                  v-if="form.password.hasError"
                  v-text="form.password.errorTxt"
                />
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.passwordConfirm.title" />
            <div class="content">
              <div class="input-wrap">
                <input
                  type="password"
                  v-model="form.passwordConfirm.value"
                  @input="checkPwConfirm()"
                />
                <p
                  class="warn-message"
                  v-if="form.passwordConfirm.hasError"
                  v-text="form.passwordConfirm.errorTxt"
                />
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.user_name.title" />
            <div class="content">
              <div class="input-wrap">
                <input
                  type="text"
                  v-model="form.user_name.value"
                  @input="checkName()"
                  :disabled="view.isModify"
                />
                <p class="warn-message" v-if="form.user_name.hasError" v-text="form.user_name.errorTxt" />
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.mail.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="text" v-model="form.mail.value" @input="checkEmail()" />
                <p class="warn-message" v-if="form.mail.hasError" v-text="form.mail.errorTxt" />
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.attachment.title" />
            <div class="content">
              <div class="attachment-wrap">
                <div class="attachment viewer">
                  <div class="a-input-wrap">
                    <span class="file-user_name" v-text="form.attachment.filename" />
                    <input
                      class="hidden file-upload"
                      type="file"
                      @click.stop
                      @change="onFileChange($event)"
                    />
                  </div>
                  <div class="a-btn-wrap">
                    <button type="button" @click="attachmentFile()">
                      파일첨부
                    </button>
                  </div>
                  <div class="preview-img" v-if="form.attachment.url !== ''">
                    <img :src="form.attachment.url" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="article">
            <h5 class="title" v-text="form.note.title" />
            <div class="content">
              <div class="input-wrap">
                <input type="text" v-model="form.note.value" @input="checkEtc()" />
                <p class="warn-message" v-if="form.note.hasError" v-text="form.note.errorTxt" />
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
          <button type="button" @click="modify()" class="active" v-if="view.isModify">
            수정하기
          </button>
          <button type="button" @click="register()" class="active" v-else>등록하기</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import {
  mapMutations, mapState, mapActions, mapGetters,
} from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      tree: {
        title: '회원 관리',
        detail: '상담자 정보 관리',
        detail2: '상담자 상세정보',
      },
      view: {
        isModify: false,
      },
      form: {
        id: {
          title: '아이디',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '아이디를 점검해주세요',
        },
        user_name: {
          title: '이름',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '이름을 점검해주세요',
        },
        password: {
          title: '비밀번호',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '비밀번호를 점검해주세요',
        },
        passwordConfirm: {
          title: '비밀번호 확인',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '비밀번호 확인을 점검해주세요',
        },
        mail: {
          title: '이메일',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '이메일 형식을 확인해주세요',
        },
        attachment: {
          title: '서명',
          flag: false,
          source: null,
          url: '',
          filename: '',
        },
        note: {
          title: '비고',
          flag: false,
          value: '',
          hasError: false,
          errorTxt: '비고를 확인해주세요',
        },
      },
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.typeCheck();
  },
  methods: {
    ...mapActions('member', ['FETCH_CONSULT_DETAIL', 'FETCH_CONSULT_SIGN', 'POST_CONSULT_DETAIL']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    checkFlag(save_type) {
      if (save_type == 'new' || this.form.password.value != '' || this.form.passwordConfirm.value != '') {
        if (!this.checkPw() || !this.checkPwConfirm()) {
          this.popupSet.content = '비밀번호 확인을 입력해주세요';
          this.form.passwordConfirm.flag = false;
          this.form.password.hasError = true;

          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
          return false;
        }
      }

      if (
        !this.form.id.flag
        || !this.form.user_name.flag
        || !this.form.mail.flag
      ) {
        if (!this.form.id.flag) {
          this.popupSet.content = '아이디를 입력해주세요';
          this.form.id.flag = false;
          this.form.id.hasError = true;
        } else if (!this.form.user_name.flag) {
          this.popupSet.content = '이름을 입력해주세요';
          this.form.user_name.flag = false;
          this.form.user_name.hasError = true;
        } else if (!this.form.password.flag) {
          this.popupSet.content = '비밀번호를 입력해주세요';
          this.form.password.flag = false;
          this.form.password.hasError = true;
        } else if (!this.form.passwordConfirm.flag) {
          this.popupSet.content = '비밀번호 확인을 입력해주세요';
          this.form.passwordConfirm.flag = false;
          this.form.password.hasError = true;
        } else if (!this.form.mail.flag) {
          this.popupSet.content = '이메일을 입력해주세요';
          this.form.mail.flag = false;
          this.form.mail.hasError = true;
        }
        this.SET_POPUP(true);
        this.popupSet.popType = 'warn';
        this.popupSet.popImg = 'success.svg';
        this.popupSet.cancelBtnText = '확인';
        this.popupSet.confirmBtnText = null;
        return false;
      }
      return true;
    },
    register() {
      console.log('form: ', this.form);
      /** form의 value 를 서버에 전송 */
      if (this.checkFlag('new')) {
        this.settingApi('new');
      }
    },
    modify() {
      console.log('form: ', this.form);
      /** form의 value 를 서버에 전송 */
      if (this.checkFlag('mod')) {
        this.settingApi('mod');
      }
    },
    async settingApi(save_type) {
      console.log(`id : ${this.$route.params.code}`);
      console.log(`this.form.attachment.url : ${this.form.attachment.url}`);

      const formData = new FormData();
      formData.append('save_type', save_type);
      formData.append('id', save_type == 'new' ? this.form.id.value : this.$route.params.code);
      formData.append('password', this.form.password.value);
      formData.append('user_name', this.form.user_name.value);
      formData.append('mail', this.form.mail.value);
      formData.append('note', this.form.note.value);
      formData.append('signature', this.form.attachment.source);

      console.log('request : ', formData);
      await this.POST_CONSULT_DETAIL(formData).then((result) => {
        console.log('result', result);
        if (result.result) {
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'success.svg';
          this.popupSet.content = save_type == 'new' ? '상담자 정보가 등록되었습니다.' : '상담자 정보가 변경되었습니다.';
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
          this.popupSet.returnList = true;
        } else {
          this.SET_POPUP(true);
          this.popupSet.popType = 'warn';
          this.popupSet.popImg = 'warning.svg';
          this.popupSet.content = result.response.message;
          this.popupSet.cancelBtnText = '확인';
          this.popupSet.confirmBtnText = null;
        }
      });
    },
    checkEtc() {
      this.form.note.flag = false;
      this.form.note.hasError = true;
      const Etc = this.form.note.value.replace(/\s/gi, '');
      // 비고 NULL 가능
      // if (Etc === "") {
      //   this.form.note.hasError = true;
      //   return false;
      // }
      this.form.note.flag = true;
      this.form.note.hasError = false;
      return true;
    },
    checkEmail() {
      this.form.mail.flag = false;
      this.form.mail.hasError = true;
      const mail = this.form.mail.value.replace(/\s/gi, '');
      if (mail === '') {
        this.form.mail.flag = false;
        this.form.mail.hasError = true;
        this.form.mail.errorTxt = '이메일을 입력해주세요.';
        return false;
      }
      const isEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!isEmail.test(mail)) {
        this.form.mail.flag = false;
        this.form.mail.hasError = true;
        this.form.mail.errorTxt = '이메일 형식에 맞지 않습니다.';
        return false;
      }
      this.form.mail.flag = true;
      this.form.mail.hasError = false;
      this.form.mail.errorTxt = '';
      return true;
    },
    checkPw() {
      this.form.password.flag = false;
      this.form.password.hasError = true;
      const pw = this.form.password.value.replace(/\s/gi, '');
      console.log('pw', pw);
      if (pw === '') {
        this.form.password.flag = false;
        this.form.password.hasError = true;
        this.form.password.errorTxt = '비밀번호를 입력해주세요.';
        return false;
      }
      const isPw = /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[`~!@@#$%^&*|₩₩₩'₩";:₩/?]).{8,20}$/;
      if (!isPw.test(pw)) {
        this.form.password.errorTxt = '영문+숫자+특수문자의 조합으로 8~20자리로 설정해주세요.';
        this.form.password.flag = false;
        this.form.password.hasError = true;
        return false;
      }
      this.form.password.flag = true;
      this.form.password.hasError = false;
      this.form.password.errorTxt = '';
      return true;
    },
    checkPwConfirm() {
      this.form.passwordConfirm.flag = false;
      this.form.passwordConfirm.hasError = true;
      const pwConfirm = this.form.passwordConfirm.value.replace(/\s/gi, '');
      if (pwConfirm === '') {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        this.form.passwordConfirm.errorTxt = '비밀번호 확인을 입력해주세요.';
        return false;
      }
      if (pwConfirm !== this.form.password.value) {
        this.form.passwordConfirm.flag = false;
        this.form.passwordConfirm.hasError = true;
        this.form.passwordConfirm.errorTxt = '비밀번호가 일치하지 않습니다.';
        return false;
      }
      this.form.passwordConfirm.flag = true;
      this.form.passwordConfirm.hasError = false;
      this.form.passwordConfirm.errorTxt = '';
      return true;
    },
    checkName() {
      this.form.user_name.flag = false;
      this.form.user_name.hasError = true;
      const user_name = this.form.user_name.value.replace(/\s/gi, '');
      if (user_name === '') {
        this.form.user_name.flag = false;
        this.form.user_name.errorTxt = '이름을 입력해주세요.';
        return false;
      }
      const isHan = /^[ㄱ-ㅎ가-힣|a-z|A-Z]{2,6}$/;
      if (!isHan.test(user_name)) {
        this.form.user_name.errorTxt = '한글 및 영문만 입력할 수 있습니다.';
        this.form.user_name.flag = false;
        return false;
      }
      this.form.user_name.flag = true;
      this.form.user_name.hasError = false;
      this.form.user_name.errorTxt = '';
      return true;
    },
    checkId() {
      this.form.id.flag = false;
      this.form.id.hasError = true;
      const id = this.form.id.value.replace(/\s/gi, '');
      if (id === '') {
        this.form.id.flag = false;
        this.form.id.errorTxt = '아이디를 입력해주세요.';
        return false;
      }
      const isId = /^[a-zA-Z][a-zA-Z0-9]{4,13}$/;
      if (!isId.test(id)) {
        this.form.id.errorTxt = '5글자 이상, 영문 및 숫자만 입력할 수 있습니다.';
        this.form.id.flag = false;
        return false;
      }
      this.form.id.flag = true;
      this.form.id.hasError = false;
      this.form.id.errorTxt = '';
      return true;
    },
    typeCheck() {
      if (this.$route.params.code !== undefined) {
        this.view.isModify = true;
        this.getResult(this.$route.params.code);
      } else {
        this.tree.detail2 = '상담자 추가';
      }
    },
    onFileChange(event) {
      const getFile = event.target.files;
      const getFileUrl = URL.createObjectURL(event.target.files[0]);
      this.form.attachment.flag = true;
      console.log('event.target.files[0] : ', event.target.files[0]);
      console.log('getFileUrl : ', getFileUrl);
      this.form.attachment.source = event.target.files[0];
      this.form.attachment.filename = getFile[0].name;
      this.form.attachment.url = getFileUrl;

      console.log('event : ', event);
      console.log('attachment : ', this.form.attachment);
    },
    attachmentFile() {
      document.querySelector('input.file-upload').click();
    },
    async getResult(id) {
      this.requestParam = `?id=${id}`;

      try {
        await this.FETCH_CONSULT_DETAIL(this.requestParam).then((data) => {
          /** id */
          console.log('id :', data.response.list[0].tel);
          this.form.id.value = data.response.list[0].id;
          this.form.id.flag = true;
          /** user_name */
          this.form.user_name.value = data.response.list[0].user_name;
          this.form.user_name.flag = true;
          /** mail */
          this.form.mail.value = data.response.list[0].mail;
          this.form.mail.flag = true;
          /** 비고 */
          console.log('note :', data.response.list[0].note);
          this.form.note.value = data.response.list[0].note;
          this.form.note.flag = true;

          this.form.attachment.filename = data.response.list[0].signature;
        });

        try {
          const data = { id };
          await this.FETCH_CONSULT_SIGN(data).then((data) => {
            const url = window.URL.createObjectURL(data);

            console.log('sign url : ', url);
            this.form.attachment.url = url;
          });
        } catch (error) {
          console.log('error:', error);
        }
      } catch (error) {
        console.log('error:', error);
      }
    },

  },
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
@import "@assets/css/infoDetail";
</style>
