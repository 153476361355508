<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">예약 관리 및 상담 내역</h5>
        <list-filter @request-list="injectionResult" ref="filter"></list-filter>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          @list-reversal="reversalResult"
          @list-amount-change="changedListAmount"
          @call-consultant-record="goRecord"
          v-if="isListVisible"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          v-if="isPaginationVisible"
          ref="pagination"
        ></pagination>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import ListFilter from '@/components/ListFilter.vue';
import List from '@/components/List.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        startPage: null,
        lastPage: null,
        currentPage: null,
        length: null,
      },
      listInfo: {
        type: 10,
        data: [],
      },
      tree: {
        title: '원격 상담',
        detail: '예약 관리 및 상담 내역',
      },
      columnInfo: [
        {
          word: '예약일',
          filter: 'consultationDate',
        },
        {
          word: '예약시간',
          filter: 'consultationTime',
        },
        {
          word: '이름',
          filter: 'userName',
        },
        {
          word: '생년월일',
          filter: 'birthDate',
        },
        {
          word: '상태',
          filter: 'state',
        },
        /**
         * 0: 예약완료
         * 1: 회원 취소
         * 2: 관리자 취소
         * 3: 관리자 예약 변경
         * 4: 상담완료
         */
        {
          word: '상담자',
          filter: 'consultant',
        },
        /**
         * 상담자, 작성자 상태값
         * 0: 배정전
         * 1: 배정됨
         * 2: 회원 취소
         * 3: 관리자 취소
         */
        {
          word: '작성자',
          filter: 'author',
        },
        {
          word: '작성일시',
          filter: 'authorAt',
        },
      ],
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      requestParam: '',
      requestParamOrder: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.getResult();
  },
  methods: {
    ...mapActions('reservation', ['FETCH_CONSULTATION_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    goRecord(data) {
      if (!data.ready) { return; }

      console.log('상담 data :', data);
      /** date, time 값으로 상세페이동 */
      this.$router.push({
        name: 'ConsultantRecord',
        query: { id: data.id, ready: data.ready },
      });
    },
    async fetchData(isInit, page, type) {
      try {
        await this.FETCH_CONSULTATION_LIST(this.requestParam + this.requestParamOrder).then((data) => {
          console.log(data.response.list);

          for (let i = 0; i < data.response.list.length; i++) {
            const item = data.response.list[i];

            item.consultantStatus = item.status == '1' || item.status == '2' ? 2 : 1;
            item.authorStatus = item.status == '1' || item.status == '2' ? 2 : 1;
          }

          if (isInit) {
            this.listInfo.data = data.response.list;
            this.listInfo.totalDataCnt = data.response.totalCount;
            this.isListVisible = true;
            this.getPaginationInfo(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              1,
            );
          } else {
            this.$refs.list.initList(data.response.list, data.response.totalCount, ((type != undefined ? 1 : page) - 1) * this.$refs.filter.filterOption.listAmount);
            this.$refs.pagination.injectPagination(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              type != undefined ? 1 : page,
            );
          }
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    changedListAmount(listAmountVal) {
      this.$refs.filter.changeListAmount(listAmountVal);
    },
    reversalResult(info) {
      this.requestParamOrder = info;
      this.fetchData(false, this.$refs.pagination.paginationStatus.currentPage, undefined);
    },
    getResult() {
      this.requestParam = '?page=1&maxPage=10&default=1';
      this.fetchData(true, null, undefined);
    },
    getPaginationInfo(totalDataCnt, startPage, lastPage, currentPage) {
      /**
       * page, maxPage, default 인자로 페이지네이션 API 실행함
       * */
      this.paginationInfo.totalDataCnt = totalDataCnt;
      this.paginationInfo.startPage = startPage;
      this.paginationInfo.lastPage = lastPage;
      if (currentPage !== null) {
        this.paginationInfo.currentPage = currentPage;
      } else {
        this.paginationInfo.currentPage = 1;
      }
      this.isPaginationVisible = true;
    },
    paginationClick(pageOrder) {
      /** 페이지네이션 번호 클릭 */
      console.log('전달할 친구들', pageOrder);
      this.$refs.filter.changeClickedPage(pageOrder);
    },
    injectionResult(info, option, type) {
      this.requestParam = info;
      this.fetchData(false, option.clickedPage, type);
    },
  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    ListFilter,
    List,
    Pagination,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.app-context {
}
</style>
