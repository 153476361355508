<template>
  <div class="app-context">
    <side-menu></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="member-info-set">
          <h5 class="title">상담자정보</h5>
          <div class="header">
            <div class="item" v-for="(header, index) in member" :key="index + 'A'">
              <p v-text="header.questionText" />
            </div>
          </div>
          <div class="content">
            <div class="item" v-for="(value, index) in member" :key="index + 'B'">
              <p v-text="value.answerResult" />
            </div>
          </div>
        </div>
        <div class="reservation-info">
          <h5 class="title">예약정보</h5>
          <list :columnInfo="columnInfo"
           :listInfo="listInfo"
           v-if=isListVisible
           ref="list"></list>
        </div>
        <div class="bottom-btn-wrap">
          <button type="button" @click="$router.go(-1)">이전으로</button>
        </div>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
    <calendar-popup
      @selected-resv-data="selectedResv"
      v-if="checkCalendarPopup"
      :popupSetCalendar="popupSetCalendar"
    ></calendar-popup>
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import CalendarPopup from '@/components/modal/CalendarPopup';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  props: {},
  data() {
    return {
      popupSet: {},
      popupSetCalendar: {},
      resvDate: {
        flag: false,
        value: '',
        exposeData: '',
        date: '',
        dayName: '',
        time: '',
      },
      tree: {
        title: '원격 상담',
        detail: '상담자 스케줄 조회',
        detail2: '스케줄 상세 조회',
      },
      member: [],
      consultant: [],
      listInfo: {
        type: 12,
        data: [],
      },
      columnInfo: [
        {
          word: '예약일',
          filter: 'consultationDate',
        },
        {
          word: '예약시간',
          filter: 'consultationTime',
        },
        {
          word: '회원명',
          filter: 'userName',
        },
        {
          word: '상태',
          filter: 'state',
        },
        /**
         * 0: 예약완료
         * 1: 회원 취소
         * 2: 관리자 취소
         * 3: 관리자 예약 변경
         * 4: 상담완료
         */
      ],
      /** api Param */
      requestParam: '',
      isListVisible: false,
      filterOpt: [],
      result: [],
      allChecked: false,
      listType: 1,
      totalDataCnt: '',
      totalCnt: 0,
      startindex: 0,
      view: {},
      order: 'desc',
      typeData: null,
    };
  },
  created() {
    console.log('param code :', this.$route.params.code);
    window.scrollTo(0, 0);
    this.fetchData();
    this.makeFilter();
    this.initList(this.listInfo.data, this.listInfo.totalDataCnt);
    this.typeData = this.listInfo.data.type;
  },
  methods: {
    ...mapActions('consultant', ['FETCH_SCHEDULE']),
    ...mapMutations('basic', ['SET_POPUP', 'SET_CALENDAR_POPUP']),
    initList(list, totalDataCnt, startIndex) {
      console.log('리스트 테스트', list);
      console.log('startIndex :', startIndex);
      this.startindex = startIndex === undefined ? 0 : startIndex;
      /** 더미데이터 리스트 */
      this.result = list;
      this.listType = this.listInfo.type;
      this.totalCnt = totalDataCnt;
      this.totalDataCnt = totalDataCnt === undefined ? '총 0명' : `총 ${totalDataCnt}명`;
    },
    makeFilter() {
      /** 필터옵션 생성해주기 */
      const arraySet = this.columnInfo;
      for (let i = 0; i < arraySet.length; i++) {
        arraySet[i].flag = false;
      }
      if (
        this.listInfo.type !== 11
        && this.listInfo.type !== 12
        && this.listInfo.type !== 13
        && this.listInfo.type !== 14
        && this.listInfo.type !== 15
        && this.listInfo.type !== 18
      ) {
        const numArea = {
          word: '번호',
        };
        arraySet.unshift(numArea);
      }
      this.filterOpt = arraySet;
    },
    filterReversal(index) {
      /**
      if (
        document
          .querySelector(".header")
          .childNodes[index + 1].childNodes[0].classList.contains("isReversal")
      ) {
        document
          .querySelector(".header")
          .childNodes[index + 1].childNodes[0].classList.remove("isReversal");
      } else {
        document
          .querySelector(".header")
          .childNodes[index + 1].childNodes[0].classList.add("isReversal");
      }
       */
      /** 선택된 필터항목과 순서(order) 넘기기 */
      if (index !== 0) {
        this.filterOpt[index].flag = !this.filterOpt[index].flag;
        const option = this.filterOpt[index];
        let stringVal = '';
        stringVal += `&orderField=${option.filter}`;
        stringVal += `&order=${option.flag === true ? 'asc' : 'desc'}`;
        this.$emit('list-reversal', stringVal);
      }
    },
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async fetchData() {
      await this.FETCH_SCHEDULE(`?id=${this.$route.params.code}`).then((data) => {
        console.log('response :', data.response);

        // 상담자 정보
        const getList = [
          {
            question: 1,
            questionText: '이름',
            answerResult: data.response.consultant,
          },
        ];
        this.member = getList;

        // 예약정보
        this.listInfo.data = data.response.list;
        this.isListVisible = true;
      });
    },
  },
  computed: {
    ...mapState('basic', ['checkPopup', 'checkCalendarPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    DefaultPopup,
    CalendarPopup,
  },
};
</script>
<style lang="scss" scoped>
.member-info-set {
  margin: 0 auto 85px 0;
  width: 205px;
  .header {
    margin: 0 0 1px 0;
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        background: #f3f3f3;
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
  .content {
    .item {
      padding: 0;
      outline: 1px solid #acb3c9;
      p {
        padding: 8px 12px;
        @include initfontKor(
          $font-size: 16px,
          $line-height: 26px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #292a2b,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}

.reservation-info {
  width: 890px;
  margin: 0 auto 140px 0;
}
</style>
