<template>
  <div class="app-wrap">
    <av-waveform
      :audio-src="URL"
      :canv-width="1024"
      :canv-height="480"
      noplayed-line-color="black"
      :noplayed-line-width="1.5"
      class="wave-form"
    >
      <!-- played-line-color="#FFE400" -->
      <!-- playtime-text-bottom -->
    </av-waveform>
  <!-- <vue-wave-surfer :src="URL2" :options="options"></vue-wave-surfer> -->
  </div>
</template>

<script>
export default {
  components: {
  },
  created() {
    console.log(this.$route.query, '준비물!!');
    this.URL = `${this.$route.query.audio.replace('mp4', 'mp3')}`;
  },
  data() {
    return {
      URL: '',
      options: {},
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>
<style scoped>
div.wave-form :nth-child(1) {
  display:flex;
  margin: 0 auto;
}
div.wave-form :nth-child(2) {
  display:flex;
  margin: 0 auto;
}
</style>
<style lang="scss" scoped>
.app-wrap {
  width: 100%;
  height: 100%;
  // background: black;
  .wave-form {
    width: 100%;
    display: flex;
    flex-direction: column-reverse;
    margin: 0 auto;
  }
}
</style>
