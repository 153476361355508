<template>
  <div class="pagination">
    <div
      class="nav-btn prev"
      @click="navigatorAct('prev')"
      :class="{ hide: paginationStatus.currentPage === 1 }"
      v-if="paginationStatus.first > 5"
    >
      <img src="@assets/images/util/icon_before.svg" />
    </div>

    <div class="count-wrap">
      <span
        class="item"
        v-for="(paginationItem, index) in paginationStatus.list"
        :key="index + 'A'"
        v-text="paginationItem"
        @click="changePage(paginationItem)"
      ></span>
    </div>
    <div
      class="nav-btn next"
      @click="navigatorAct('next')"
      :class="{ hide: paginationStatus.currentPage === paginationStatus.lastPage }"
      v-if="paginationStatus.last < paginationStatus.lastPage && 10 < paginationStatus.lastPage"
    >
      <img src="@assets/images/util/icon_next.svg" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    paginationInfo: {
      type: Object
    }
  },
  data() {
    return {
      paginationStatus: {
        totalDataCnt: this.paginationInfo.totalDataCnt,
        startPage: this.paginationInfo.startPage,
        lastPage: this.paginationInfo.lastPage,
        currentPage: this.paginationInfo.currentPage,
        prev: null,
        next: null,
        first: null,
        last: null,
        list: []
      }
    };
  },
  created() {
    this.preparePagination();
  },
  methods: {
    injectPagination(totalDataCnt, startPage, lastPage, currentPage) {
      this.initPagination(totalDataCnt, startPage, lastPage, currentPage);
      this.preparePagination();
      this.initPaginationEffect();
    },
    initPaginationEffect() {
      for (let i = 0; i < document.querySelector(".count-wrap").childElementCount; i++) {
        if (document.querySelector(".count-wrap").childNodes[i].classList.contains("active")) {
          document.querySelector(".count-wrap").childNodes[i].classList.remove("active");
        }
      }
    },
    changePage(order) {
      this.paginationStatus.currentPage = order;
      this.initPaginationEffect();
      this.$emit("btn-click", order);
    },
    calculatedPaginationList(first, last) {
      const paginationListArray = [];
      for (let j = first; j <= last; j++) {
        if (j > 0) {
          paginationListArray.push(j);
        }
      }
      this.paginationStatus.list = paginationListArray;
      console.log(this.paginationStatus.currentPage);
      
      const activePage = Number(
        this.paginationStatus.currentPage.toString()[
          this.paginationStatus.currentPage.toString().length - 1
        ]
      );
      console.log("전달목표", activePage);
      const transActivePage = activePage === 0 ? 9 : activePage - 1;
      console.log("변환값", transActivePage);
      this.$nextTick(_ => {
        document.querySelector(".count-wrap").childNodes[transActivePage].classList.add("active");
      });
      /**
      if (type === "prev") {
        this.$nextTick(_ => {
          for (let k = 0; k < document.querySelector(".count-wrap").childElementCount; k++) {
            if (
              Number(
                document.querySelector(".count-wrap").childNodes[k].childNodes[0].textContent
              ) === Number(this.paginationStatus.last)
            ) {
              document.querySelector(".count-wrap").childNodes[k].classList.add("active");
            }
          }
        });
      } else if (type === "next") {
        this.$nextTick(_ => {
          document.querySelector(".count-wrap").childNodes[0].classList.add("active");
        });
      } else {
        this.$nextTick(_ => {
          document
            .querySelector(".count-wrap")
            .childNodes[this.paginationStatus.currentPage - 1].classList.add("active");
        });
      }
       */
    },
    preparePagination() {
      const { currentPage } = this.paginationStatus;
      const { lastPage } = this.paginationStatus;
      const pageCount = 10;
      const pageGroup = Math.ceil(currentPage / pageCount);
      console.log("pageGroup", pageGroup);
      let last = pageGroup * pageCount;
      let first;
      if (last >= lastPage) {
        last = lastPage;
        if (currentPage > 100) {
          first = Number(currentPage.toString()[0] + currentPage.toString()[1] + "1");
        } else if (currentPage > 10) {
          first = Number(currentPage.toString()[0] + "1");
        } else {
          first = last - (pageCount - 1);
        }
      } else {
        first = last - (pageCount - 1);
      }
      const next = last + 1;
      const prev = first - 1;
      if (lastPage <= 1) {
        first = last;
      }
      console.log("last : " + last);
      console.log("first : " + first);
      console.log("next :" + next);
      console.log("prev :" + prev);
      this.paginationStatus.prev = prev;
      this.paginationStatus.next = next;
      this.paginationStatus.first = first;
      this.paginationStatus.last = last;
      this.calculatedPaginationList(first, last);
    },
    initPagination(totalDataCnt, startPage, lastPage, currentPage) {
      this.paginationStatus.totalDataCnt = totalDataCnt;
      this.paginationStatus.startPage = startPage;
      this.paginationStatus.lastPage = lastPage;
      this.paginationStatus.currentPage = currentPage;
    },
    navigatorAct(type) {
      this.initPaginationEffect();
      let first = this.paginationStatus.first;
      let last = this.paginationStatus.last;
      const { lastPage } = this.paginationStatus;
      let next = this.paginationStatus.next;
      let prev = this.paginationStatus.prev;
      if (type === "prev") {
        last = first - 1;
        next = last + 1;
        first = first - 10;
        prev = first - 1;
        this.paginationStatus.currentPage = last;
      } else if (type === "next") {
        first = first + 10;
        prev = first - 1;
        if (lastPage > last + 10) {
          last = last + 10;
          next = last + 1;
        } else {
          last = lastPage;
          next = last;
        }
        this.paginationStatus.currentPage = first;
      }
      this.paginationStatus.first = first;
      this.paginationStatus.last = last;
      this.paginationStatus.prev = prev;
      this.paginationStatus.next = next;
      this.calculatedPaginationList(first, last);
    }
  },
  computed: {},
  components: {}
};
</script>
<style lang="scss" scoped>
.pagination {
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
  width: 580px;
  margin: 0 auto;
  .nav-btn {
    cursor: pointer;
    display: flex;
    align-items: center;
    &.hide {
      opacity: 0;
    }
  }
  .count-wrap {
    display: flex;
    span.item {
      margin: 0 0 0 12px;
      display: block;
      width: 30px;
      cursor: pointer;
      @include initfontKor(
        $font-size: 18px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.48px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: #fd8b11;
        color: #fff;
      }
    }
  }
}
</style>
