<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <h5 class="title">관리자 관리</h5>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          @list-reversal="reversalResult"
          v-if="isListVisible"
          ref="list"
        ></list>
      </div>
    </div>
    <default-popup v-if="checkPopup" :popupSet="popupSet" />
  </div>
</template>

<script>
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import List from '@/components/List.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      tree: {
        title: '회원 관리',
        detail: '관리자 관리',
      },
      columnInfo: [
        {
          word: '아이디',
          filter: 'id',
        },
        {
          word: '이름',
          filter: 'name',
        },
        {
          word: '이메일',
          filter: 'mail',
        },
        {
          word: '연락처',
          filter: 'tel',
        },
        {
          word: '작성자',
          filter: 'author',
        },
        {
          word: '작성일자',
          filter: 'update_at',
        },
      ],
      listInfo: {
        type: 4,
        data: [],
        isHideAmount: true,
      },
      isListVisible: false,
      /** api Param */
      requestParam: '',
      requestParamOrder: '',
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.requestParam = '?page=1';
    this.getResult();
  },
  methods: {
    ...mapActions('member', ['FETCH_MANAGE_LIST']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    reversalResult(info) {
      this.requestParamOrder = info;
      this.fetchData(false);
    },
    async fetchData(isInit) {
      try {
        await this.FETCH_MANAGE_LIST(this.requestParam + this.requestParamOrder).then((data) => {
          if (isInit) {
            this.listInfo.data = data.response.list;
            this.listInfo.totalDataCnt = data.response.list.length;
            this.isListVisible = true;
          } else {
            this.$refs.list.initList(data.response.list, data.response.list.length);
          }
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    getResult() {
      this.fetchData(true);
    },
  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    List,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped></style>
