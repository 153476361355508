<template>
  <div class="app-context">
    <side-menu @is-unauthorized="isUnAuthorized"></side-menu>
    <div class="wrap-context">
      <notification :tree="tree" @call-logout-popup="callLogoutPopup"></notification>
      <div class="container">
        <div class="excel-btn">
          <div class="btn-wrap" @click="download()">
            <p>Excel 다운로드</p>
          </div>
        </div>
        <h5 class="title">회원별 설정</h5>
        <list-filter @request-list="injectionResult" ref="filter"></list-filter>
        <list
          :columnInfo="columnInfo"
          :listInfo="listInfo"
          @list-reversal="reversalResult"
          @list-amount-change="changedListAmount"
          @set-training-amount="setAmountPop"
          @set-training-time="setTimePop"
          @set-training-hertz="setHertz"
          @list-find-to-checked="getCheckedList"
          v-if="isListVisible"
          ref="list"
        ></list>
        <pagination
          @btn-click="paginationClick"
          :paginationInfo="paginationInfo"
          ref="pagination"
          v-if="isPaginationVisible"
        ></pagination>
      </div>
    </div>
    <default-popup
      v-if="checkPopup"
      :popupSet="popupSet"
      @training-amount-proceed="apiToTrainingAmount"
      @training-time-proceed="apiToTrainingTime"
      @hertz-time-proceed="apiToHertzTime"
    />
  </div>
</template>

<script>
import axios from 'axios';
import DefaultPopup from '@/components/modal/DefaultPopup.vue';
import SideMenu from '@/components/SideMenu.vue';
import Notification from '@/components/Notification.vue';
import ListFilter from '@/components/ListFilter.vue';
import List from '@/components/List.vue';
import Pagination from '@/components/Pagination.vue';
import { mapMutations, mapState, mapActions } from 'vuex';

export default {
  data() {
    return {
      popupSet: {},
      paginationInfo: {
        totalDataCnt: null,
        dataOnPage: 10,
        startPage: null,
        lastPage: null,
        currentPage: null,
        length: null,
      },
      tree: {
        title: '회원 관리',
        detail: '회원별 설정',
      },
      listInfo: {
        type: 2,
        data: [],
        isHideAmount: false,
      },
      columnInfo: [
        // {
        //   word: '가입일',
        //   filter: 'createAt',
        // },
        // {
        //   word: "아이디",
        //   filter: "id"
        // },
        {
          word: '이름',
          filter: 'userName',
        },
        {
          word: '성별',
          filter: 'gender',
        },
        {
          word: '생년월일',
          filter: 'birthDate',
        },
        // {
        //   word: "연락처",
        //   filter: "tel"
        // },
        {
          word: '삼킴훈련 시간',
          filter: 'training',
        },
        {
          word: '삼킴훈련 횟수',
          filter: 'amount',
        },
        {
          word: '음도 측정 시간',
          filter: 'hertz',
        },
        {
          word: '자세법',
          filter: 'positionCount',
        },
        {
          word: '식이법',
          filter: 'feedCount',
        },
        // {
        //   word: '작업자',
        //   filter: 'author',
        // },
        {
          word: '최종 작업일',
          filter: 'authorAt',
        },
      ],
      isPaginationVisible: false,
      isListVisible: false,
      /** api Param */
      requestParam: '',
      requestParamOrder: '',
      trainingAmount: {
        amount: '',
        set: '',
      },
      trainingTime: {
        morning: '',
        afternoon: '',
        night: '',
      },
      hertzTime: '',
    };
  },
  /** API 데이터 로딩주기 */
  created() {
    // localStorage.setItem(
    //   "token",
    //   "c778815b739866cc2c4d884f1f73e4639847babca9e928635e0f6a8a9d946e7b"
    // );
    window.scrollTo(0, 0);
    this.getResult();
  },
  methods: {
    ...mapActions('member', ['FETCH_ACCOUNT_SET_LIST', 'UPDATE_ACCOUNT_SET_MEMBER']),
    ...mapActions('training', ['CHECK_TRAINING']),
    ...mapMutations('basic', ['SET_POPUP']),
    callLogoutPopup() {
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.popType = 'warn';
      this.popupSet.popImg = 'success.svg';
      this.popupSet.content = '로그아웃 되었습니다.';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    isUnAuthorized() {
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.content = '로그인 권한이 필요합니다.';
      this.popupSet.popImg = 'warning.svg';
      this.popupSet.cancelBtnText = null;
      this.popupSet.nextLink = '/login';
      this.popupSet.confirmBtnText = '확인';
      this.popupSet.destination = true;
    },
    async trainingSet(type, range, order, tType) {
      /** 인자로 받은 순번으로 삼킴훈련횟수 설정 API를 통신한다. */
      console.log('order :', order);
      console.log('request 보낼 인자 체크: ', this.trainingAmount.amount, this.trainingAmount.set);

      console.log(`id : ${this.$refs.list.result[order].id}`);
      console.log(this.training);
      const setMember = {
        t_type: tType,
        type,
        range: range === true ? 'all' : undefined,
        filter: range === true ? this.requestParam : undefined,
        id: range === true ? '' : this.$refs.list.result[order].id,
        dic: type === 'time' ? {
          morning: this.trainingTime.morning,
          afternoon: this.trainingTime.afternoon,
          night: this.trainingTime.night,
        } : type === 'hertz' ? {
          time: this.hertzTime,
        } : {
          number: this.trainingAmount.amount,
          set: this.trainingAmount.set,
        },
      };

      // ** 설정 관련 정책 변경 **
      // 1. 훈련 중일 경우 삼킴훈련 시간이 [없음] <-> [있음] 변경 되면 안됨.
      // 2. 훈련 중일 경우 삼킴훈련 횟수 변경 불가
      if (range !== true) {
        let requireCheckTrainig = false;
        const exerciseCate = `training_${tType}`;
        // 시간 변경
        console.log(this.$refs.list.result[order][exerciseCate], exerciseCate, this.$refs.list.result[order][exerciseCate]);
        if (type == 'time') {
          if ((this.trainingTime.morning == '없음' && this.trainingTime.morning != this.$refs.list.result[order][exerciseCate][0].morning)
            || (this.$refs.list.result[order][exerciseCate][0].morning == '없음' && this.trainingTime.morning != this.$refs.list.result[order][exerciseCate][0].morning)
            || (this.trainingTime.afternoon == '없음' && this.trainingTime.afternoon != this.$refs.list.result[order][exerciseCate][0].afternoon)
            || (this.$refs.list.result[order][exerciseCate][0].afternoon == '없음' && this.trainingTime.afternoon != this.$refs.list.result[order][exerciseCate][0].afternoon)
            || (this.trainingTime.night == '없음' && this.trainingTime.night != this.$refs.list.result[order][exerciseCate][0].night)
            || (this.$refs.list.result[order][exerciseCate][0].night == '없음' && this.trainingTime.night != this.$refs.list.result[order][exerciseCate][0].night)) { requireCheckTrainig = true; }
        }
        // 횟수 변경
        else {
          requireCheckTrainig = true;
        }

        if (requireCheckTrainig) {
          let returnFlag = false;
          await this.CHECK_TRAINING(`?userId=${this.$refs.list.result[order].id}`).then((data) => {
            if (data.response.isProgress) {
              returnFlag = true;
            }
          });

          if (returnFlag) {
            this.SET_POPUP(true);
            this.popupSet = {};
            this.popupSet.content = '회원이 현재 훈련 진행 중이므로\n변경할 수 없습니다.';
            this.popupSet.popImg = 'warning.svg';
            this.popupSet.cancelBtnText = null;
            this.popupSet.confirmBtnText = '확인';
            return;
          }
        }
      }

      console.log('##### call API - UPDATE_ACCOUNT_SET_MEMBER #####');
      try {
        let result = false;

        await this.UPDATE_ACCOUNT_SET_MEMBER(setMember).then(async (data) => {
          console.log('result :', data);
          if (data.result) {
            await this.fetchData(false, this.$refs.pagination.paginationStatus.currentPage, undefined);
          }
          result = data.result;
        });
        return result;
      } catch (error) {
        console.log('error:', error);
      }

      return false;
    },
    getCheckedList(list, type) {
      if (list.length === 0) { return; }

      this.trainingSet(type == 'amount' ? 'count' : type, true, 0);
    },
    apiToTrainingAmount(isMultiple, array, order, tType) {
      console.log('목표빔', array);
      this.trainingAmount.amount = array.amount.value;
      this.trainingAmount.set = array.set.value;
      if (isMultiple) {
        this.$refs.list.extract('amount');
      } else {
        this.trainingSet('count', '', order, tType);
        const curPage = this.$refs.pagination.paginationStatus.currentPage;
        this.fetchData(false, curPage, undefined);
      }
    },
    setAmountPop(order, cate) {
      const exerciseCate = `training_${cate}`;
      /** api 조회하여 횟수, 세트의 옵션값을 불러옴 */
      console.log('testtest ', this.$refs.list.result[order][exerciseCate][1]);

      const optionValue = {
        amount: {
          option: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
          value: this.$refs.list.result[order][exerciseCate][1].amount,
        },
        set: {
          option: [1, 2],
          value: this.$refs.list.result[order][exerciseCate][1].set,
        },
      };
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.overlayRestrict = true;
      this.popupSet.popType = 'trainingAmount';
      this.popupSet.content = `전체 적용시 현재 조회된<br/>${this.$refs.list.totalCnt}명의 회원에 대해 일괄 적용됩니다.`;
      this.popupSet.selectOpt = optionValue;
      this.popupSet.selectedOrder = order;
      this.popupSet.exerciseCate = cate;
    },
    apiToTrainingTime(isMultiple, array, order, tType) {
      console.log('목표빔', array);
      console.log('morning', array.morning.value);
      console.log('afternoon', array.afternoon.value);
      console.log('night', array.night.value);
      this.trainingTime.morning = array.morning.value;
      this.trainingTime.afternoon = array.afternoon.value;
      this.trainingTime.night = array.night.value;
      if (isMultiple) {
        this.$refs.list.extract('time');
      } else {
        this.trainingSet('time', '', order, tType);
      }
    },
    apiToHertzTime(isMultiple, array, order, tType) {
      console.log('isMultiple : ', isMultiple);
      console.log('array : ', array);
      console.log('order : ', order);
      this.hertzTime = array.hertzValue;
      if (isMultiple) {
        this.$refs.list.extract('hertz');
      } else {
        this.trainingSet('hertz', '', order, tType);
      }
    },
    setTimePop(order, cate) {
      console.log(cate);
      /** api 조회하여 횟수, 세트의 옵션값을 불러옴 */
      // const optionTime = ['01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
      //   '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
      //   '21:00', '22:00', '23:00', '24:00'];

      const optionTime = ['없음', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00',
        '11:00', '12:00', '13:00', '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00',
        '21:00', '22:00', '23:00', '24:00'];

      const exerciseCate = `training_${cate}`;
      console.log(this.$refs.list.result, exerciseCate, this.$refs.list.result[order][exerciseCate][0]);
      const optionValue = {
        morning: {
          option: optionTime,
          value: this.$refs.list.result[order][exerciseCate][0].morning,
        },
        afternoon: {
          option: optionTime,
          value: this.$refs.list.result[order][exerciseCate][0].afternoon,
        },
        night: {
          option: optionTime,
          value: this.$refs.list.result[order][exerciseCate][0].night,
        },
      };
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.overlayRestrict = true;
      this.popupSet.popType = 'trainingTime';
      this.popupSet.content = `전체 적용시 현재 조회된<br/>${this.$refs.list.totalCnt}명의 회원에 대해 일괄 적용됩니다.`;
      this.popupSet.selectOpt = optionValue;
      this.popupSet.selectedOrder = order;
      this.popupSet.exerciseCate = cate;
    },
    setHertz(order, cate) {
      // this.hertzTime = this.$refs.list.result[order].hertzTime;
      const optionValue = {
        hertzTime: [5, 10, 15, 20],
        hertzValue: this.$refs.list.result[order].hertzTime,
      };
      this.SET_POPUP(true);
      this.popupSet = {};
      this.popupSet.overlayRestrict = true;
      this.popupSet.popType = 'setHertzSec';
      // this.popupSet.content = `전체 적용시 현재 조회된<br/>${this.$refs.list.totalCnt}명의 회원에 대해 일괄 적용됩니다.`;
      this.popupSet.selectOpt = optionValue;
      this.popupSet.selectedOrder = order;
      this.popupSet.exerciseCate = cate;
    },
    async fetchData(isInit, page, type) {
      try {
        console.log(this.requestParam + this.requestParamOrder);
        await this.FETCH_ACCOUNT_SET_LIST(this.requestParam + this.requestParamOrder).then((data) => {
          console.log('회원설정리스트 : ', data.response);
          if (isInit) {
            this.listInfo.data = data.response.list;
            this.listInfo.totalDataCnt = data.response.totalCount;
            this.isListVisible = true;
            this.getPaginationInfo(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              1,
            );
          } else {
            this.$refs.list.initList(data.response.list, data.response.totalCount, ((type != undefined ? 1 : page) - 1) * this.$refs.filter.filterOption.listAmount);
            this.$refs.pagination.injectPagination(
              data.response.totalCount,
              data.response.firstPage,
              data.response.endPage,
              type != undefined ? 1 : page,
            );
          }
        });
      } catch (error) {
        console.log('error:', error);
      }
    },
    changedListAmount(listAmountVal) {
      this.$refs.filter.changeListAmount(listAmountVal);
    },
    paginationClick(pageOrder) {
      this.$refs.filter.changeClickedPage(pageOrder);
    },
    getPaginationInfo(totalDataCnt, startPage, lastPage, currentPage) {
      /**
       * page, maxPage, default 인자로 페이지네이션 API 실행함
       * */
      this.paginationInfo.totalDataCnt = totalDataCnt;
      this.paginationInfo.startPage = startPage;
      this.paginationInfo.lastPage = lastPage;
      if (currentPage !== null) {
        this.paginationInfo.currentPage = currentPage;
      } else {
        this.paginationInfo.currentPage = 1;
      }
      this.isPaginationVisible = true;
    },
    getResult() {
      this.requestParam = '?page=1&maxPage=10&default=1';
      this.fetchData(true, null, undefined);
    },
    reversalResult(info) {
      this.requestParamOrder = info;
      this.fetchData(false, this.$refs.pagination.paginationStatus.currentPage, undefined);
    },
    injectionResult(info, option, type) {
      this.requestParam = info;
      this.fetchData(false, option.clickedPage, type);
    },
    async download() {
      axios({
        method: 'GET',
        url: `${process.env.VUE_APP_API_URL}/account/set/excel`,
        responseType: 'blob',
        headers: {
          Authorization: `Token ${localStorage.getItem('token')}`,
        },
      })
        .then((response) => {
          console.log(response, ' : response');
          const url = window.URL.createObjectURL(new Blob([response.data], { type: response.headers['content-type'] }));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', '회원별 설정.xlsx');
          document.body.appendChild(link);
          link.click();
        });
    },
  },
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
  },
  components: {
    SideMenu,
    Notification,
    ListFilter,
    List,
    Pagination,
    DefaultPopup,
  },
};
</script>
<style lang="scss" scoped>
.container {
  .excel-btn {
    display: flex;
    justify-content: flex-end;
    margin: 0;
    .btn-wrap {
      cursor: pointer;
      background: #fd8b11;
      p {
        margin: 0 10px;
         @include initfontKor(
          $font-size: 15px,
          $line-height: 40px,
          $font-family: "AppleSDGothicNeoM00",
          $color: #fff,
          $letter-spacing: -0.6px,
          $text-align: center
        );
      }
    }
  }
}
.app-context {
}
</style>
