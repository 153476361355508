<template>
  <modal :wide="isTimeTable">
    <template v-slot:body>
      <div class="calendar-pop frame" v-if="popType === 'warn'">
        <div class="close" @click="close()">
          <img src="@assets/images/util/x.svg" />
        </div>
        <!-- 달력 존 -->
        <calendar
          @selected-time-data="transSelectedTimeData"
          @selected-date-val="transSelectedDate"
          @call-repeat-resv="repeatResv"
          :notificationType="notificationType"
          :isTimeTable="isTimeTable"
          :rangeOrder="rangeOrder"
          :repeatItems="repeatItems"
        ></calendar>
        <div class="btn-wrap">
          <button
            type="button"
            @click="searchDate()"
            :class="{
              active:
                (!isTimeTable && selectedDate !== null) ||
                (selectedTime !== null && selectedDate !== null)
            }"
            v-text="isTimeTable ? '선택완료' : '조회'"
          ></button>
        </div>
        <div class="test-wrap">askdfjasdlfkkasndflsanfl</div>
      </div>
    </template>
  </modal>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Calendar from '@/components/Calendar.vue';
import Modal from '@/components/modal/CalendarModal.vue';

export default {
  props: {
    popupSetCalendar: {
      content: {
        type: String,
      },
      nextLink: {
        type: String,
      },
      confirmBtnText: {
        type: String,
      },
      cancelBtnText: {
        type: String,
      },
      popType: {
        type: String,
      },
      notificationType: {
        type: Number,
        default: 0,
      },
      isTimeTable: {
        type: Boolean,
        default: false,
      },
      rangeOrder: {
        type: Number,
        default: null,
      },
      typeIndex: {
        type: Number,
      },
    },
  },
  components: {
    Modal,
    Calendar,
  },
  data() {
    return {
      popType: this.popupSetCalendar.popType || 'warn',
      content: this.popupSetCalendar.content,
      nextLink: this.popupSetCalendar.nextLink,
      cancelBtnText: this.popupSetCalendar.cancelBtnText,
      confirmBtnText: this.popupSetCalendar.confirmBtnText,
      notificationType: this.popupSetCalendar.notificationType,
      isTimeTable: this.popupSetCalendar.isTimeTable,
      rangeOrder: this.popupSetCalendar.rangeOrder,
      typeIndex: this.popupSetCalendar.typeIndex,
      selectedDate: null,
      selectedTime: null,
      selectedOrder: null,
      repeatItems: {},
    };
  },
  watch: {},
  mounted() {},
  computed: {
    ...mapState('basic', ['checkPopup']),
    contentMutated() {
      if (this.content !== undefined && this.content.indexOf('\n') !== 1) {
        return this.content.split('\n').join('<br />');
      }
      return this.content;
    },
  },
  methods: {
    ...mapMutations('basic', ['SET_CALENDAR_POPUP']),
    transSelectedDate(date, order) {
      this.selectedDate = date;
      if (order !== null) {
        this.selectedOrder = order;
      }
    },
    transSelectedTimeData(data) {
      this.selectedTime = data;
    },
    repeatResv(flag) {
      console.log('repeatResv메소드 실행');
      this.$emit('call-repeat-resv', flag, this.selectedDate);
    },
    searchDate() {
      if (this.isTimeTable) {
        if (this.selectedTime !== null) {
          this.$emit('selected-date', this.selectedDate);
          this.$emit('selected-resv-data', this.selectedTime, this.typeIndex);
          this.SET_CALENDAR_POPUP(false);
        } else {
          alert('체크해주세요');
        }
      } else if (this.selectedDate !== null) {
        if (this.selectedOrder !== null) {
          this.$emit('selected-date', this.selectedDate, this.selectedOrder);
        } else {
          this.$emit('selected-date', this.selectedDate);
        }
        this.SET_CALENDAR_POPUP(false);
      } else {
        alert('체크해주세요');
      }
    },
    action() {
      this.SET_CALENDAR_POPUP(false);
      if (this.nextLink !== undefined) {
        this.$router.push(this.nextLink);
      }
    },
    close() {
      this.SET_CALENDAR_POPUP(false);
    },
  },
  created() {
    console.log('가져온 팝업아이템', this.popupSetCalendar);
    this.repeatItems = this.$attrs.repeatItem;
  },
};
</script>

<style lang="scss" scoped>
.frame {
  padding: 40px 0;
  min-height: 300px;
}
.calendar-pop {
  width: inherit;
  .close {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
    cursor: pointer;
    &.hide {
      opacity: 0;
    }
  }
  .pop-img {
    max-width: 105px;
    margin: 0 auto 15px auto;
    img {
    }
  }
  .content {
    p {
      @include initfontKor(
        $font-size: 24px,
        $line-height: 30px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.6px,
        $text-align: right
      );
      text-align: center;
      margin: 0 0 20px 0;
    }
  }
  .btn-wrap {
    display: flex;
    justify-content: center;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    button {
      width: 100%;
      height: 60px;
      margin: 0;
      background: #eaebf1;
      cursor: pointer;
      border: 0;
      @include initfontKor(
        $font-size: 26px,
        $line-height: 36px,
        $font-family: "AppleSDGothicNeoM00",
        $color: #292a2b,
        $letter-spacing: -0.52px,
        $text-align: center
      );
      &:first-of-type {
        margin: 0;
      }
      &.active {
        background: $pointcolor__orange;
        color: #fff;
      }
    }
  }
}
</style>
