<template>
  <div class="posture-wrap">
    <div class="member-info">
      <div class="item" v-for="(memberItem, index) in member" :key="index + 'A'">
        <h6 v-text="memberItem.columnName" />
        <p v-text="memberItem.value" />
      </div>
    </div>
    <div class="list-wrap" v-if="settingList.listType === 1">
      <div class="action-btn">
        <button type="button" v-text="btnName" @click="allCheck()" />
      </div>
      <div class="list" v-for="(listItem, index) in list" :key="index + 'B'">
        <h5 class="title" v-text="listItem.questionText" />
        <div class="content">
          <div class="item" v-for="(answerItem, index) in listItem.answerList" :key="index + 'C'">
            <p v-html="computedLine(answerItem.question)" />
            <p v-html="computedLine(answerItem.answer)" />
            <div class="check-wrap">
              <input type="checkbox" :id="answerItem.keyName" v-model="answerItem.checkFlag" />
              <label :for="answerItem.keyName" @click="checked()"></label>
            </div>
            <div class="btn-wrap-download">
              <button type="button" @click="download('pos', answerItem.id, answerItem.question)">
                다운로드
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="list-wrap" v-if="settingList.listType === 2">
      <div class="action-btn">
        <button type="button" v-text="btnName" @click="allCheck()" />
      </div>
      <div class="list" v-for="(listItem, index) in list" :key="index + 'B'">
        <h5 class="title" v-text="listItem.questionText" />
        <div class="content">
          <div class="item" v-for="(answerItem, index) in listItem.answerList" :key="index + 'C'">
            <p class="sub-title" v-html="computedLine(answerItem.title)" />
            <p v-html="computedLine(answerItem.question)" />
            <p v-html="computedLine(answerItem.answer)" />
            <div class="check-wrap">
              <input type="checkbox" :id="answerItem.keyName" v-model="answerItem.checkFlag" />
              <label :for="answerItem.keyName" @click="checked()"></label>
            </div>
            <div class="btn-wrap-download">
              <button type="button" @click="download('diet', answerItem.id, answerItem.question)">
                다운로드
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bottom-btn-wrap">
      <button type="button" @click="$router.go(-1)">이전으로</button>
      <button type="button" class = "active" @click="settingPosture()">적용하기</button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    settingList: {
      list: {
        type: Array
      },
      member: {
        type: Array
      }
    }
  },
  data() {
    return {
      list: this.settingList.list,
      member: this.settingList.member,
      btnName: "전체선택"
    };
  },
  created() {},
  methods: {
    extract() {
      this.$emit("return-checked-list", this.list);
    },
    settingPosture() {
      this.$emit("call-setting-pop");
    },
    allCheck() {
      if (this.checkFlagInspection()) {
        console.log("모두 체크된 상태에 돌입");
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < this.list[i].answerList.length; j++) {
            this.list[i].answerList[j].checkFlag = false;
          }
        }
        this.btnName = "전체선택";
      } else {
        console.log("모두 체크되지 않은 상태에 돌입");
        for (let i = 0; i < this.list.length; i++) {
          for (let j = 0; j < this.list[i].answerList.length; j++) {
            this.list[i].answerList[j].checkFlag = true;
            console.log("i :", i, "j :", j, "결과 :", this.list[i].answerList[j].checkFlag);
          }
        }
        this.btnName = "전체해제";
      }
    },
    checked() {
      setTimeout(() => {
        if (this.checkFlagInspection()) {
          this.btnName = "전체해제";
        } else {
          this.btnName = "전체선택";
        }
      }, 1);
    },
    checkFlagInspection() {
      let allCheckFlag = true;
      for (let i = 0; i < this.list.length; i++) {
        for (let j = 0; j < this.list[i].answerList.length; j++) {
          if (!this.list[i].answerList[j].checkFlag) {
            allCheckFlag = false;
          }
        }
      }
      console.log("체크결과", allCheckFlag);
      return allCheckFlag;
    },
    download(type, id, question) {
      let url = `${process.env.VUE_APP_MAIN_URL}/media/public/base_image/${type}/${id}.png`
      let filename = `${question}.png`
      
      fetch(url).then(async f => {
        let blob = await f.blob()

        let a = document.createElement('a')
        a.setAttribute('download', filename)
        a.style.display = 'none'
        a.href = URL.createObjectURL(blob)
        document.body.appendChild(a)
        a.click()
        a.remove()
      })
    }
  },
  computed: {
    computedLine() {
      return text => text.split("\n").join("<br />");
    }
  },
  components: {}
};
</script>
<style lang="scss" scoped>
@import "@assets/css/posture";
</style>
