import { consultant } from "@/api";

const state = {
  day: "",
  checkPopup: false,
  checkCalendarPopup: false
};

const getters = {
  GET_DAY: state => state.day
};

const mutations = {
  SET_DAY(state, day) {
    state.day = day;
  }
};

const actions = {
  FETCH_SCHEDULE_LIST({}, payload) {
    return consultant.fetchScheduleList(payload);
  },
  FETCH_SCHEDULE({}, payload) {
      return consultant.fetchSchedule(payload);
  },
  FETCH_CONSULTATION_CONTENT({}, payload) {
    return consultant.fetchConsultationContent(payload);
  },
  UPDATE_CONSULTATION_CONTENT({}, payload) {
    return consultant.updateConsultationContent(payload);
  },
  FETCH_CONSULTATION_CONTENT_LIST({}, payload) {
    return consultant.fetchConsultationContentList(payload);
  },
};

export default {
  strict: process.env.NODE_ENV !== "production",
  namespaced: true,
  state,
  mutations,
  getters,
  actions
};
